import React from "react";
import { ButtonFormControl, InputFormControl, SelectedFormControl } from "../../molecules";

export default function FormCreate({
  handleOnCloseProductNew
  , presentations = []
  , categorys = []
  , onHandleChangeInsert
  , onHandleChangeSelectInsert
  , handleOnSaveProductNew
}) {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <InputFormControl
            type={'text'}
            label={'Nombre del producto'}
            onHandleChange={onHandleChangeInsert}
            nameInput={'nameProduct'}
          />
        </div>
        <div className="col-md-6">
          <InputFormControl
            type={'text'}
            label={'Código de barras'}
            onHandleChange={onHandleChangeInsert}
            nameInput={'code'}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          <InputFormControl
            type={'number'}
            label={'Precio de compra'}
            onHandleChange={onHandleChangeInsert}
            nameInput={'purchasePrice'}
          />
        </div>
        <div className="col-md-3">
          <InputFormControl
            type={'number'}
            label={'Precio de venta'}
            onHandleChange={onHandleChangeInsert}
            nameInput={'purchaseSale'}
          />
        </div>
        <div className="col-md-3">
          <InputFormControl
            type={'number'}
            label={'Cantidad'}
            onHandleChange={onHandleChangeInsert}
            nameInput={'quantity'}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <SelectedFormControl
            label={'Categoría'}
            placeholder={'Seleccione una categoría...'}
            options={categorys.filter(cate => cate.state_value === true)}
            onHandleChange={(selectedOption) => onHandleChangeSelectInsert(selectedOption, 'selectValueCategory')}
            nameSelect='categoryId'
          />
        </div>
        <div className="col-md-6">
          <SelectedFormControl
            label={'Presentación'}
            placeholder={'Seleccione una presentación...'}
            options={presentations.filter(pre => pre.state_value === true)}
            onHandleChange={(selectedOption) => onHandleChangeSelectInsert(selectedOption, 'selectValuePresentation')}
            nameSelect={'presentationId'}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-6"></div>
        <div className="col-md-6 d-flex justify-content-end">
          <div className="btn-group">
            <ButtonFormControl
              text={'Cancelar'}
              className={'btn btn-secondary btn-lg'}
              OnHandleClick={handleOnCloseProductNew}
            />&nbsp;&nbsp;
            <ButtonFormControl
              text={'Guardar'}
              className={'btn btn-success btn-lg'}
              OnHandleClick={handleOnSaveProductNew}
            />
          </div>
        </div>
      </div>
    </div>
  )
}