import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { ButtonFormControl, InputFormControl, SelectedFormControl } from '../../molecules';
import SaveIcon from '@mui/icons-material/Save';
import LogoutIcon from '@mui/icons-material/Logout';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import { formatearFecha } from '../../../helpers/general_functions';

export default function FormCreate({
  handleOnChangeInsert,
  handleFinallyRegister,
  activeStep,
  handleNext,
  isStepOptional,
  isStepSkipped,
  handleBack,
  steps = [],
  areas = [],
  roles = [],
  areaIdSelect,
  handleOnChangeSelectArea,
  handleOnChangeSelectRole,
  handleOnKeyPressDni,
  formValues = {},
  handleOnCloseCustomerEdit,
  documentsType = [],
  handleOnChangeSelectDocumentType,
  objEditCustomer = {}
}) {

  return (
    <Box sx={{ width: '100%', marginTop: '0.5em' }} >
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption">Opcional</Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <RenderComponentRegister 
        step={activeStep + 1}
        handleOnChangeInsert={handleOnChangeInsert}
        areas={areas}
        roles={roles}
        areaIdSelect={areaIdSelect}
        handleOnChangeSelectArea={handleOnChangeSelectArea}
        handleOnChangeSelectRole={handleOnChangeSelectRole}
        handleOnKeyPressDni={handleOnKeyPressDni}
        formValues={formValues}
        handleOnChangeSelectDocumentType={handleOnChangeSelectDocumentType}
        documentsType={documentsType}
        objEditCustomer={objEditCustomer}
      />
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            Todos los pasos completados.
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <ButtonFormControl
              text={<><LogoutIcon />&nbsp;Finalizar</>}
              OnHandleClick={handleFinallyRegister}
              className="btn btn-primary btn-lg"
            />
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Volver
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Siguiente
              </Button>
            )}
            <ButtonFormControl
              text={
                activeStep === steps.length - 1 ? <>
                  <ArrowCircleLeftIcon />&nbsp;Cancelar
                </> : ''
              }
              className="btn btn-secondary btn-lg"
              OnHandleClick={handleOnCloseCustomerEdit}
            />&nbsp;&nbsp;
            <ButtonFormControl
              text={
                activeStep === steps.length - 1 ? <>
                  <SaveIcon />&nbsp;Guardar
                </> : 'Siguiente'
              }
              className="btn btn-success btn-lg"
              OnHandleClick={(e) => handleNext(e, 'U')}
            />&nbsp;&nbsp;
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
}

const RenderComponentRegister = ({
  step,
  handleOnChangeInsert,
  areas = [],
  roles = [],
  areaIdSelect = 0,
  handleOnChangeSelectArea,
  handleOnChangeSelectRole,
  handleOnKeyPressDni,
  formValues = {},
  documentsType = [],
  handleOnChangeSelectDocumentType,
  objEditCustomer = {}
}) => {
  switch (step) {
    case 1:
      return(
        <ComponentePersonalDataRegister 
          handleOnChangeInsert={handleOnChangeInsert}
          areas={areas}
          roles={roles}
          areaIdSelect={areaIdSelect}
          handleOnChangeSelectArea={handleOnChangeSelectArea}
          handleOnChangeSelectRole={handleOnChangeSelectRole}
          handleOnKeyPressDni={handleOnKeyPressDni}
          formValues={formValues}
          handleOnChangeSelectDocumentType={handleOnChangeSelectDocumentType}
          documentsType={documentsType}
          objEditCustomer={objEditCustomer}
        />
      )

    default:
      break;
  }
}

const ComponentePersonalDataRegister = ({
  handleOnChangeInsert,
  handleOnChangeSelectDocumentType,
  handleOnKeyPressDni,
  formValues = {},
  documentsType = [],
  objEditCustomer = {}
}) => {
  return(
    <div className='container mt-2'>
      <div className='row'>
        <div className='col-md-6'>
          <InputFormControl
            type={'text'}
            label={`${objEditCustomer.tipo_documento_id === 1 ? 'Nombres': 'Razón Social'}`}
            nameInput={'names'}
            defaultValue={objEditCustomer.names}
            onHandleChange={handleOnChangeInsert}
          />
        </div>
        {
          objEditCustomer.tipo_documento_id === 1 ?
          <div className='col-md-6'>
            <InputFormControl
              type={'text'}
              label={'Apellidos'}
              nameInput={'lastNames'}
              onHandleChange={handleOnChangeInsert}
              defaultValue={objEditCustomer.last_names}
            />
          </div>: <div className='col-md-6'></div>
        }
        <div className='col-md-4'>
          <SelectedFormControl
            label={'Tipo de documento'}
            placeholder={'Seleccione un tipo de documento...'}
            options={documentsType}
            defaultValue={documentsType.filter(doc => parseInt(doc.value) === objEditCustomer.tipo_documento_id)}
            onHandleChange={handleOnChangeSelectDocumentType}
          />
        </div>
        <div className='col-md-2'>
          <InputFormControl
            type={'text'}
            label={'Número de documento'}
            nameInput={'documentNumber'}
            autoFocus
            onHandleChange={handleOnChangeInsert}
            onKeyPress={handleOnKeyPressDni}
            defaultValue={objEditCustomer.number_document}
            maxlength={20}
          />
        </div>
        <div className='col-md-6'>
          <InputFormControl
            type={'text'}
            label={'Dirección (Calle|Mz|Psje|Av) (*Opcional)'}
            nameInput={'address'}
            onHandleChange={handleOnChangeInsert}
            defaultValue={objEditCustomer.address}
          />
        </div>
        <div className='col-md-4'>
          <InputFormControl
            type={'email'}
            label={'Email'}
            nameInput={'email'}
            defaultValue={objEditCustomer.emails[0]?.email}
            onHandleChange={handleOnChangeInsert}
          />
        </div>
        <div className='col-md-2'>
          <InputFormControl
            type={'text'}
            label={'Celular'}
            nameInput={'cellphone'}
            defaultValue={objEditCustomer.cellphone}
            onHandleChange={handleOnChangeInsert}
            maxlength={9}
          />
        </div>
        {
          objEditCustomer.tipo_documento_id === 1 ?
          <div className='col-md-3'>
            <InputFormControl
              type={'date'}
              label={'Fecha Nacimiento'}
              nameInput={'birthDate'}
              defaultValue={formatearFecha(new Date(objEditCustomer.fecha_nacimiento))}
              onHandleChange={handleOnChangeInsert}
            />
          </div>:
          <div className='col-md-3'>
            
          </div>
        }
      </div>
    </div>
  )
}