import React, { useState } from "react";
import { Container } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import List from './List';
import { getTitle } from "../../../helpers/general_functions";
import { useGetAllCategory } from "../../../hooks";
import { Modal } from "../../organism";
import FormCreate from "./FormCreate";
import Swal from 'sweetalert2';
import { ServiceGetAllCategory, ServicePostCreateCategory, ServicePutUpdateCategory } from "../../../service/category-service";
import FormUpdate from "./FormUpdate";

export default function Manager() {
  getTitle('Categorías');
  const {categorys,setCategory} = useGetAllCategory();
  const [openModalNew, setOpenModalNew] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [objCategory, setObjCategory] = useState({});
  const [formValues, setFormValues] = useState({
    names: '',
    abbreviature: ''
  });

  const handleOnChangeInsert = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  }

  const handleCategoryNew = (e) => {
    setOpenModalNew(true);
  }
  const handleOnCloseCategoryNew = (e) => {
    setOpenModalNew(false);
  }
  const handleOnClickSaveCategory = (e) => {
    if(!formValues.names) {
      return;
    }

    const dataInsert = {
      name: formValues.names,
      abbreviature: formValues.abbreviature
    }

    Swal.fire({
      title: "Seguro de guardar la categoría?",
      text: "Si, estoy seguro!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, guardar",
      cancelButtonText: 'Cancelar'
    }).then(async(result) => {
      if (result.isConfirmed) {
        let insert = await ServicePostCreateCategory(dataInsert);
        if(insert.status === 200) {
          Swal.fire({
            title: `${insert.data.message}`,
            text: `Se ha registrado la categoría.`,
            icon: "success"
          });
          await handleGetAllCategories();
          handleClearControls();
        } else {
          Swal.fire({
            icon: "error",
            title: "Advertencia",
            text: `${insert.error}`,
          });
        }
      }
    });
  }
  const handleOnClickSaveEditCategory = (e) => {
    const dateUpdate = {
      name: formValues.names === '' || formValues.names === undefined || formValues.names === null ? objCategory.names: formValues.names,
      abbreviature: formValues.abbreviature === '' || formValues.abbreviature === undefined || formValues.abbreviature === null ? objCategory.abbreviature: formValues.abbreviature,
      category_id: parseInt(objCategory.id)
    }
    Swal.fire({
      title: "Seguro de editar la categoría?",
      text: "Si, estoy seguro!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, editar",
      cancelButtonText: 'Cancelar'
    }).then(async(result) => {
      if (result.isConfirmed) {
        let insert = await ServicePutUpdateCategory(dateUpdate);
        if(insert.status === 200) {
          Swal.fire({
            title: `${insert.data.message}`,
            text: `Se ha actualizado la categoría.`,
            icon: "success"
          });
          await handleGetAllCategories();
          handleClearControls();
        } else {
          Swal.fire({
            icon: "error",
            title: "Advertencia",
            text: `${insert.error}`,
          });
        }
      }
    });
  }
  const handleClearControls = (_) => {
    setFormValues({});
    setOpenModalNew(false);
    if(openModalEdit) {
      setOpenModalEdit(false);
    }
  }
  const handleGetAllCategories = async() => {
    let data = await ServiceGetAllCategory();
    setCategory(data.data);
  }
  const handleOnEdit = (e, item) => {
    setOpenModalEdit(true);
    setObjCategory(item);
  }
  const handleOnCloseCategoryEdit = (e) => {
    setOpenModalEdit(false);
  }
  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Mantenimiento" breadcrumbItem="Categorías" />
      </Container>
      <List 
        rows={categorys}
        handleCategoryNew={handleCategoryNew}
        handleOnEdit={handleOnEdit}
      />
      {
        openModalNew && (
          <Modal
            title={`Nueva categoría`}
            size='modal-lg'
            onClose={handleOnCloseCategoryNew}
          >
          <FormCreate 
            handleOnChangeInsert={handleOnChangeInsert}
            handleOnClickSaveCategory={handleOnClickSaveCategory}
            handleOnCloseCategoryNew={handleOnCloseCategoryNew}
          />
          </Modal>
      )}
      {
        openModalEdit && (
          <Modal
            title={`Editar categoría`}
            size='modal-lg'
            onClose={handleOnCloseCategoryEdit}
          >
          <FormUpdate 
            handleOnChangeInsert={handleOnChangeInsert}
            handleOnClickSaveEditCategory={handleOnClickSaveEditCategory}
            handleOnCloseCategoryEdit={handleOnCloseCategoryEdit}
            item={objCategory}
          />
          </Modal>
      )}
    </div>
  )
}