import React, { Fragment } from "react";
import { ButtonFormControl, InputFormControl, TextAreaFormControl } from "../../molecules";
import List from "./List";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SaveIcon from '@mui/icons-material/Save';
import { COLOR_BUTTON_MAB } from "../../../helpers/color";
import { ComponentDetailPerson, VehicleAssignmentDetail, ConditionalRender, FormCreateModalNew } from "../../organism";
import AutoCompleteInput from "../../organism/Auto-Complete-Input";
import { CART, VEHICLE } from "../../../config/config";
import { StrongText } from "../../atoms";

export default function FormCreate({
  handleOnClickNewLine,
  lstLine = [],
  presentations = [],
  handleDeleteItem,
  handleOnChangeAddQuantity,
  handleOnSaveExitNote,
  onHandleChangeInsert,
  handleOnChangeNaturalezaProduc,
  handleEnterPressCustomer,
  handleEnterPressDriver,
  fullNameCustomer = '',
  fullNameDriver = '',
  handleOnChangeWeightUnit,
  handleOnChangeKgBrutos,
  handleOnChangeKgNetos,
  handleOnChangePresentation,
  loadButtonCustomer = false,
  loadButtonDriver = false,
  handleOnViewData,
  flagsViewData = {},
  objReniecDriver = {},
  objReniecCustomer = {},
  flagsSelectTypeDocument = {},
  handleOnClickPersonAddCustomer,
  handleOnClickPersonAddDriver,
  handleOnChangeEmail,
  stateButtonSave = false,
  handleOnClickAddVehicle,
  objVehicleSelect = {},
  formEmail = {},
  showDetailVehicleAssignment = false,
  handleOnDeleteAssignment,
  showDropdown = false,
  showDropdownDriver = false,
  foundPersonDriver = false,
  handleSelectItemDriver,
  foundPerson = false,
  handleOnDeleteCustomer,
  handleSelectItem,
  handleOnDeleteDriver,
  handleOnChangeSearchVehicleByLicense,
  // Clientes
  handleOnCloseAutoCompleteCustomer,
  // Chofer
  handleOnCloseAutoCompleteDriver,
  // Placas
  vehicleLicenseList,
  showDropDownOfVehicleLicense = false,
  vehicleLicense = {},
  vehicleLicenseFound = false,
  handleOnDeleteVehicleLicense,
  handleOnSelectItemVehicleLicense,
  handleOnDeleteVehicleLicenseCart,
  vehicleLicenseCartFound = false,
  handleOnChangeSearchVehicleCartByLicense,
  showDropDownOfVehicleLicenseCart,
  vehicleLicenseCartList = [],
  handleOnSelectItemVehicleLicenseCart,
  vehicleLicenseCart = {},
  handleOnCloseAutoCompleteVehicleLicense,
  handleOnCloseAutoCompleteVehicleLicenseCart,
  // Carretas
  handleOnClickCreateCartNew,
  isNewVehicleCart = false,
  handleOnChangeLicenseCart,
  handleOnClickSaveCartNew,
  // Vehiculo
  isNewVehicle = false,
  handleOnClickCreateLicenseNew,
  handleOnClickSaveLicenseNew,
  handleOnChangeLicense,
  onHandleChangeSelectBrandInsert,
  brands = [],
  // Customer
  handleOnClickCustomer,
  handleOnClickDriver,
  handleOnClickVehicle,
  handleOnClickLicenseCart,
  // Chofer
  isNewDriverModal = false,
  handleOnClickCreateDriverNew,
  handleOnChangeDriver,
  handleOnClickSaveDriverNew,
  isNewCustomer = false,
  onHandleChangeDriverLicense,
  onHandleChangeBrand,
  handleOnChangeHumedad,
  handleOnChangeProductoDescripcion,
  handleOnChangeTara
}) {
  return (
    <Fragment>
      <div className="row">
        <div className="col-md-9">
          <div className="row mt-1">
            <div className="col-md-8 mb-1">
              <div className="btn-group">
                <ButtonFormControl
                  text={
                    <>
                      <AddCircleIcon />
                      &nbsp;Agregar item
                    </>
                  }
                  className={'btn btn-dark btn-sm'}
                  OnHandleClick={handleOnClickNewLine}
                />&nbsp;&nbsp;
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <List
                rows={lstLine}
                presentations={presentations}
                handleDeleteItem={handleDeleteItem}
                handleOnChangeAddQuantity={handleOnChangeAddQuantity}
                handleOnChangeKgBrutos={handleOnChangeKgBrutos}
                handleOnChangeKgNetos={handleOnChangeKgNetos}

                handleOnChangeHumedad={handleOnChangeHumedad}
                handleOnChangeProductoDescripcion={handleOnChangeProductoDescripcion}
                handleOnChangeTara={handleOnChangeTara}
              />
            </div>
          </div>
          <hr />
          <div className="row mb-1">
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-9">
                  <InputFormControl
                    type={'text'}
                    label={'Transportista'}
                    nameInput={'transportista'}
                    onHandleChange={onHandleChangeInsert}
                  />
                  {/* <ConditionalRender
                    condition={foundPersonDriver}
                    elseContent={
                      <AutoCompleteInput 
                        data={objReniecDriver}
                        placeholder={'Buscar tranportista por dni o ruc'}
                        titleCreate={'Nuevo Transporista'}  
                        onCloseAutoComplete={handleOnCloseAutoCompleteDriver}
                        showDropdown={showDropdownDriver}
                        onKeyPress={handleEnterPressDriver}
                        handleSelectItem={handleSelectItemDriver}
                        type={'DRIVER'}
                        showProgress={loadButtonDriver}
                        typeDocument={flagsSelectTypeDocument.isDniRucDriver}
                        handleOnClick={handleOnClickDriver}
                        onClickCreate={handleOnClickCreateDriverNew}
                      />
                    }
                  >
                  <ComponentDetailPerson 
                    item={objReniecDriver}
                    handleOnDelete={handleOnDeleteDriver}
                    type={'DRIVER'}
                    typeDocument={flagsSelectTypeDocument.isDniRucDriver} />
                  </ConditionalRender>
                  <FormCreateModalNew
                    isOpen={isNewDriverModal}
                    toggle={handleOnClickCreateDriverNew}
                    onClickSave={handleOnClickSaveDriverNew}
                    onChange={handleOnChangeDriver}
                    titleHeader='Nuevo Chofer'
                    type={'DRIVER'}
                  /> */}
                </div>
                <div className="col-md-3">
                <InputFormControl
                    type={'text'}
                    label={'RUC'}
                    nameInput={'ruc_transportista'}
                    onHandleChange={onHandleChangeInsert}
                  />
                </div>
                <div className="col-md-9 mt-3">
                  <StrongText value="Chofer:" />
                  <ConditionalRender
                    condition={foundPersonDriver}
                    elseContent={
                      <AutoCompleteInput 
                        data={objReniecDriver}
                        placeholder={'Buscar chofer por dni '}
                        titleCreate={'Nuevo chofer'}  
                        onCloseAutoComplete={handleOnCloseAutoCompleteDriver}
                        showDropdown={showDropdownDriver}
                        onKeyPress={handleEnterPressDriver}
                        handleSelectItem={handleSelectItemDriver}
                        type={'DRIVER'}
                        showProgress={loadButtonDriver}
                        typeDocument={flagsSelectTypeDocument.isDniRucDriver}
                        handleOnClick={handleOnClickDriver}
                        onClickCreate={handleOnClickCreateDriverNew}
                      />
                    }
                  >
                  <ComponentDetailPerson 
                    item={objReniecDriver}
                    handleOnDelete={handleOnDeleteDriver}
                    type={'DRIVER'}
                    typeDocument={flagsSelectTypeDocument.isDniRucDriver} />
                  </ConditionalRender>
                  <FormCreateModalNew
                    isOpen={isNewDriverModal}
                    toggle={handleOnClickCreateDriverNew}
                    onClickSave={handleOnClickSaveDriverNew}
                    onChange={handleOnChangeDriver}
                    titleHeader='Nuevo Chofer'
                    type={'DRIVER'}
                  />
                </div>
                <div className="col-md-3 mb-1">
                  <InputFormControl
                    type={'text'}
                    label={'Licencia'}
                    nameInput={'license'}
                    onHandleChange={onHandleChangeDriverLicense}
                  />
                </div>
                <div className="col-md-6 mt-2 mb-4">
                  <StrongText value="Vehículo:" />
                  <ConditionalRender 
                    condition={vehicleLicenseFound} 
                    elseContent={
                      <AutoCompleteInput
                        placeholder={'Buscar por placa frontal: '}
                        onCloseAutoComplete={handleOnCloseAutoCompleteVehicleLicense}
                        onChange={handleOnChangeSearchVehicleByLicense}
                        type={VEHICLE}
                        items={vehicleLicenseList}
                        showDropdown={showDropDownOfVehicleLicense}
                        handleSelectItem={handleOnSelectItemVehicleLicense}
                        titleCreate={'Nuevo vehículo'}
                        onClickCreate={handleOnClickCreateLicenseNew}
                        handleOnClick={handleOnClickVehicle}
                      />
                    }
                  >
                  <VehicleAssignmentDetail
                    handleOnDelete={handleOnDeleteVehicleLicense}
                    item={vehicleLicense}
                    vehicleLicense={vehicleLicense.vehicle_license_plate}
                    icon={VEHICLE}
                  />
                  </ConditionalRender>
                  <FormCreateModalNew
                    isOpen={isNewVehicle}
                    toggle={handleOnClickCreateLicenseNew}
                    onClickSave={handleOnClickSaveLicenseNew}
                    onChange={handleOnChangeLicense}
                    titleHeader='Nueva placa del vehículo'
                    onHandleChangeSelectBrandInsert={onHandleChangeSelectBrandInsert}
                    onChangeBrand={onHandleChangeBrand}
                    type={VEHICLE}
                    brands={brands}
                  />
                </div>
                <div className="col-md-6 mt-2 mb-4">
                  <StrongText value="Carreta:" />
                  <ConditionalRender
                    condition={vehicleLicenseCartFound}
                    elseContent={
                      <AutoCompleteInput
                        onCloseAutoComplete={handleOnCloseAutoCompleteVehicleLicenseCart}
                        placeholder={'Buscar por placa carreta: '}
                        onChange={handleOnChangeSearchVehicleCartByLicense}
                        type={VEHICLE}
                        items={vehicleLicenseCartList}
                        showDropdown={showDropDownOfVehicleLicenseCart}
                        handleSelectItem={handleOnSelectItemVehicleLicenseCart}
                        titleCreate={'Nueva placa de carreta'}
                        onClickCreate={handleOnClickCreateCartNew}
                        handleOnClick={handleOnClickLicenseCart}
                      />
                    }
                  >
                  <VehicleAssignmentDetail
                    handleOnDelete={handleOnDeleteVehicleLicenseCart}
                    item={vehicleLicenseCart}
                    vehicleLicense={vehicleLicenseCart.license_cart}
                    icon={CART}
                  />
                  </ConditionalRender>
                  <FormCreateModalNew
                    isOpen={isNewVehicleCart}
                    toggle={handleOnClickCreateCartNew}
                    onClickSave={handleOnClickSaveCartNew}
                    onChange={handleOnChangeLicenseCart}
                    titleHeader='Nueva carreta'
                    type={CART}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-6">
                  <InputFormControl
                    type={'text'}
                    label={'Peso de origen (KG).'}
                    nameInput={'peso_origen'}
                    onHandleChange={onHandleChangeInsert}
                  />
                </div>
                <div className="col-md-6">
                  <InputFormControl
                    type={'text'}
                    label={'Peso recibido (KG).'}
                    nameInput={'peso_recibido'}
                    onHandleChange={onHandleChangeInsert}
                  />
                </div>
                <div className="col-md-6">
                  <InputFormControl
                    type={'text'}
                    label={'Kg. de más y/o menos'}
                    nameInput={'kg_mas_menos'}
                    onHandleChange={onHandleChangeInsert}
                  />
                </div>
                <div className="col-md-6">
                  <InputFormControl
                    type={'text'}
                    label={'Sacos de origen yute'}
                    nameInput={'saco_origen_yute'}
                    onHandleChange={onHandleChangeInsert}
                  />
                </div>
                <div className="col-md-6">
                  <InputFormControl
                    type={'text'}
                    label={'Sacos de origen plástico'}
                    nameInput={'saco_origen_plastico'}
                    onHandleChange={onHandleChangeInsert}
                  />
                </div>
                <div className="col-md-6">
                  <InputFormControl
                    type={'text'}
                    label={'Nro Lote'}
                    nameInput={'nro_lote'}
                    onHandleChange={onHandleChangeInsert}
                  />
                </div>
              </div>
            </div>
            <hr />
            <br />
          </div>
        </div>
        <div className="col-md-3 mt-4">
          <div className="row">
            <div className="col-md-12 mb-2">
              <strong>Cliente:</strong>
              {
                !foundPerson ?
                <AutoCompleteInput 
                  data={objReniecCustomer}
                  placeholder={'Buscar cliente por ruc o dni: '}
                  titleCreate={'Nuevo cliente'}  
                  showDropdown={showDropdown}
                  onKeyPress={handleEnterPressCustomer}
                  handleSelectItem={handleSelectItem}
                  typeDocument={flagsSelectTypeDocument.isDniRucCustomer}
                  type={'CUSTOMER'}
                  onCloseAutoComplete={handleOnCloseAutoCompleteCustomer}
                  showProgress={loadButtonDriver}
                  handleOnClick={handleOnClickCustomer}
                />: 
                <ComponentDetailPerson 
                  item={objReniecCustomer}
                  handleOnDelete={handleOnDeleteCustomer}
                  type={'CUSTOMER'}
                  typeDocument={flagsSelectTypeDocument.isDniRucCustomer}
                  isNew={isNewCustomer}
                />
              }
            </div>
           
            <div className="col-md-12 mt-1">
              <label>Dirección: </label>
              <TextAreaFormControl
                className={'form-control'}
                rows={1}
                onChange={onHandleChangeInsert}
                name={'direccion'}
              />
            </div>
            <div className="col-md-12 mt-1">
              <label>Procedencia: </label>
              <TextAreaFormControl
                className={'form-control'}
                rows={1}
                onChange={onHandleChangeInsert}
                name={'reason'}
              />
            </div>
            <div className="col-md-6">
              <InputFormControl
                type={'text'}
                label={'Guía de remisión'}
                nameInput={'plant'}
                onHandleChange={onHandleChangeInsert}
              />
            </div>
            <div className="col-md-12 mt-2">
              <label style={{ color: COLOR_BUTTON_MAB }}>Observaciones: </label>
              <TextAreaFormControl
                className={'form-control'}
                rows={2}
                onChange={onHandleChangeInsert}
                name={'observations'}
              />
            </div>
            <div className="col-md-4 mt-3">
              <ButtonFormControl
                text={
                    !stateButtonSave?
                    <><SaveIcon />&nbsp;Guardar</>
                    :
                    <><SaveIcon />&nbsp;Guardando...</>
                }
                className="btn btn-success w-100"
                disabled={stateButtonSave}
                OnHandleClick={handleOnSaveExitNote}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
