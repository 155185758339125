import React from "react";
import { Container } from "reactstrap";
import List from "./List";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { getTitle } from "../../../helpers/general_functions";
import useGetAllPurchaseSettlement from "../../../hooks/purchase-settlement-hooks";
import { Uri_GetDownloadPdf } from "../../../api/purchase-settlement/api-purchase-settlement";

export default function Manager() {
  getTitle('Liquidaciones de Compra')
  
  const { purchaseSettlements } = useGetAllPurchaseSettlement()

  function handleDownloadPdfPurchase(_, item) {
    const {
      url_pdf,
      pdf_name
    } = item
    handleDownloadPdf(url_pdf, pdf_name)
  }
  function handleDownloadPdf(url, name) {
    fetch(Uri_GetDownloadPdf(url, name))
    .then(response => {
      if (!response.ok) {
        throw new Error('Ocurrió un error al descargar el PDF');
      }
      return response.blob();
    })
    .then(blob => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${name}`);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
    })
    .catch(error => {
      console.error('Error:', error);
    });
  }
  return(
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Liquidación de Compra" breadcrumbItem="Liquidación de Compra" />
        <List 
          rows={purchaseSettlements}
          handleDownloadPdfPurchase={handleDownloadPdfPurchase}
        />
      </Container>
    </div>
  )
}