import { useEffect, useState } from "react";
import { ServiceGetAllVehicleLicense, ServiceGetAllVehicles, ServiceGetAllVehiclesAvailable } from "../service/vehicle-service";

export function useGetAllVehicle() {
  const [vehicles, setVehicle] = useState([]);
  useEffect(() => {
    async function getAllVehicles() {
      let data = await ServiceGetAllVehicles();
      setVehicle(data.data);
    }
    getAllVehicles();
  }, []);
  return {vehicles, setVehicle}
}
export function useGetAllVehicleAvailable() {
  const [vehiclesAvailable, setVehicleAvailable] = useState([]);
  useEffect(() => {
    async function getAllVehiclesAvailable() {
      let data = await ServiceGetAllVehiclesAvailable();
      setVehicleAvailable(data.data);
    }
    getAllVehiclesAvailable();
  }, []);
  return {vehiclesAvailable, setVehicleAvailable}
}
 