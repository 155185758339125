import { useEffect, useState } from "react";
import { ServiceGetAllRole } from "../service/ServiceRole";

export function useGetAllRole() {
  const [roles, setRoles] = useState([]);
  useEffect(() => {
    async function getAllRole() {
      let data = await ServiceGetAllRole();
      setRoles(data.data);
    }
    getAllRole();
  }, []);
  return {roles}
}

export default { useGetAllRole }