import { useEffect, useState } from "react";
import { ServiceGetAllProducts } from "../service/product-service";

export default function useGetAllProducts() {
  const [products, setProducts] = useState([]);
  useEffect(() => {
    async function getAllProducts() {
      let data = await ServiceGetAllProducts();
      setProducts(data.data);
    }
    getAllProducts();
  }, []);
  return {products, setProducts}
}