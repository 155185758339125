import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table";
import { Table, Row, Col, Button } from "reactstrap";
import { Filter, DefaultColumnFilter } from "./filters";
import { Link } from "react-router-dom";
import { InputFormControl, SelectedFormControl } from "../../molecules";
import { CustomOptions } from "../../organism";
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import Cleave from "cleave.js/react";
import { COLOR_GREEN } from "../../../helpers/color";

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <Col sm={4}>
      <div className="search-box mb-0 d-inline-block">
        <div className="position-relative">
          <label htmlFor="search-bar-0" className="search-label">
            <span id="search-bar-0-label" className="sr-only">
              Search this table
            </span>
            <input
              onChange={e => {
                setValue(e.target.value);
                onChange(e.target.value);
              }}
              id="search-bar-0"
              type="text"
              className="form-control"
              // placeholder={`${count} records...`}
              value={value || ""}
            />
          </label>
          {/* <i className="bx bx-search-alt search-icon"></i> */}
        </div>
      </div>
    </Col>
  );
}

const TableContainer = ({
  data,
  className,
  isBordered,
  handleDeleteItem,
  handleOnChangeNetKilo,
  totalsResult = 0.00,
  productName,
  handleOnChangeDescriptionResult,
  handleOnChangeNumberBagsResult,
  handleOnChangePercentageHumedityResult
}) => {
  return (
    <Fragment>
      <h4 className="text-center" style={{color: COLOR_GREEN}}>Resultado de {productName}</h4>
      <div className="table-responsive" style={{ height: '25vh' }}>
        <Table  className={className} bordered={isBordered} >
          <thead className="thead-light">
            <tr >
              <th rowSpan="2" className="align-middle" width={'30%'} style={{backgroundColor: '#7ee7f1'}}>DESCRIPCIÓN</th>
              <th rowSpan="2" className="align-middle" width={'10%'} style={{backgroundColor: '#7ee7f1'}}>SACOS</th>
              <th rowSpan="2" className="align-middle" width={'10%'} style={{backgroundColor: '#7ee7f1'}} >KILOS NETOS</th>
              <th colSpan="2" className="align-middle text-center" style={{backgroundColor: '#7ee7f1'}}>QQS</th>
              <th rowSpan="2" className="align-middle" width={'10%'} style={{backgroundColor: '#7ee7f1'}}>%</th>
              <th rowSpan="2" className="align-middle" width={'10%'} style={{backgroundColor: '#7ee7f1'}}>HUMEDAD %</th>
            </tr>
            <tr>
              <th className="align-middle text-center"  width={'10%'} style={{backgroundColor: '#7ee7f1'}}>55.2 KGS.</th>
              <th className="align-middle text-center"  width={'10%'} style={{backgroundColor: '#7ee7f1'}}>46 KGS.</th>
            </tr>
          </thead>
          <tbody>
            
            {
              data.length > 0 ? data.map((d, index) => {
                return (
                  <tr key={index}>
                    <td width={'30%'} style={{backgroundColor: '#7ee7f1'}}>
                      <InputFormControl
                        type={'text'}
                        showIsLabel={false}
                        defaultValue={d.description}
                        onHandleChange={(e) => handleOnChangeDescriptionResult(e, d)}
                      />
                    </td>
                    <td width={'10%'} style={{backgroundColor: '#f1e591'}}>
                      <InputFormControl
                        id='txtNumberBagsResult'
                        type={'text'}
                        showIsLabel={false}
                        defaultValue={d.numberBags}
                        onHandleChange={(e) => handleOnChangeNumberBagsResult(e, d)}
                      />
                    </td>
                    <td width={'10%'} style={{backgroundColor: d.id === 1 ? '#a3e1a9': '#f1e591', textAlign: 'right'}}>
                      {
                        d.id === 2 ?
                        <InputFormControl
                          id='txtNetKiloResult'
                          type={'text'}
                          showIsLabel={false}
                          defaultValue={d.netKilo}
                          style={{textAlign: 'right'}}
                          onHandleChange={(e) => handleOnChangeNetKilo(e, d)}
                        />:
                        <strong>{d.netKilo}</strong>
                      }
                    </td>
                    <td width={'10%'} style={{backgroundColor: '#a3e1a9', textAlign: 'right'}}>
                      <strong>{d.qqs55_2}</strong>
                    </td>
                    <td width={'10%'} style={{backgroundColor: '#a3e1a9', textAlign: 'right'}}>
                      <strong>{d.qqs46}</strong>
                    </td>
                    <td width={'10%'} style={{backgroundColor: '#a3e1a9', textAlign: 'right'}}>
                      <strong>{d.percentage}</strong>
                    </td>
                    <td width={'10%'} style={{backgroundColor: '#f1e591'}}>
                      <InputFormControl
                        id='txtPercentageHumedityResult'
                        type={'text'}
                        showIsLabel={false}
                        defaultValue={d.percentageHumedity}
                        onHandleChange={(e) => handleOnChangePercentageHumedityResult(e, d)}
                      />
                    </td>
                  </tr>
                )
              }): ''
            }
          </tbody>
          {
            data.length > 0 ?
            <tfoot className="custom-footer" >
              <tr >
                <th colSpan="2" className="align-middle" style={{backgroundColor: '#a3e1a9'}} >TOTAL</th>
                <th className="align-middle" style={{textAlign: 'right', backgroundColor: '#a3e1a9'}}>{totalsResult.kilosNetos.toFixed(2)}</th>
                <th className="align-middle" style={{textAlign: 'right', backgroundColor: '#a3e1a9'}}>{totalsResult.qqs552.toFixed(2)}</th>
                <th className="align-middle" style={{textAlign: 'right', backgroundColor: '#a3e1a9'}}>{totalsResult.qqs46.toFixed(2)}</th>
                <th className="align-middle" style={{textAlign: 'right', backgroundColor: '#a3e1a9'}}>{totalsResult.percentage.toFixed(2)}</th>
                <th className="align-middle" style={{textAlign: 'right', backgroundColor: '#a3e1a9'}}></th>
              </tr>
            </tfoot>: ''
          }
        </Table>
        {
          data.length === 0 ?
          <CenteredContent />: ''
        }
      </div>
    </Fragment>

  );
};
const CenteredContent = () => {
  return (
    <div className="container h-100">
      <div className="row h-100 justify-content-center align-items-center">
        <div className="col-md-12 text-center">
          <Inventory2OutlinedIcon style={{height: '100%', width: '40px'}} /> 
          <div>Lista de productos vacía.</div>
          Agrega al menos un producto, busca y selecciona un producto para comenzar
        </div>
      </div>
    </div>
  );
};

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default TableContainer;
