import React, { useState } from "react";
import { Container } from "reactstrap";
import Breadcrumbs from "../../Common/Breadcrumb";
import List from './List';
import { getNameAndLastName, getTitle, separarNombreCompleto } from "../../../helpers/general_functions";
import { useGetAllEmployeed } from "../../../hooks/user-hook";
import { Modal, SendEmailVouchers } from "../../organism";
import FormCreate from "./FormCreate";
import Swal from 'sweetalert2';
import { useGetAllAreas, useGetAllConfigs } from "../../../hooks/common-hook";
import { useGetAllRole } from "../../../hooks";
import { GetAllEmployeed, ServiceDisabledEmployeed, ServiceGetBasedDocumentNumber, ServicePostCreateEmployed, ServicePutEnabledEmployeed } from "../../../service/user-service";
import { getReniec } from "../../../helpers/api_helper";
import FormUpdate from "./FormUpdate";
import { ServiceSendEmailCredentials } from "../../../service/common-service";

const steps = ['Ingreso de datos personales'];

export default function Manager() {
  getTitle('Trabajadores');
  const { employeed, setEmployeed } = useGetAllEmployeed();
  const { areas } = useGetAllAreas();
  const { roles } = useGetAllRole();
  const { config } = useGetAllConfigs();
  const [openModalEmployeedNew, setOpenModalEmployeedNew] = useState(false);
  const [stateButtonSave, setStateButtonSave] = useState(false);
  const [areaIdSelect, setAreaIdSelect] = useState(0);
  const [roleIdSelect, setRoleIdSelect] = useState(0);
  const [objEmployeed, setObjEmployeed] = useState({});
  const [openModalEmployeedEdit, setOpenModalEmployeedEdit] = useState(false);
  const [openModalSendCredentials, setOpenModalSendCredentials] = useState(false);
  const [objSendCred, setObjSendCred] = useState({});

  const [formValues, setFormValues] = useState({
    names: '',
    lastNames: '',
    dni: '',
    birthDate: '',
    address: '',
    email: '',
    cellphone: ''
  })
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = (e, type = 'I') => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    if (activeStep + 1 === steps.length) {
      const stateTypeTransaction = type === 'U';
      let data = {};
      if(type === 'I') {
        if(!areaIdSelect) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `Debe de seleccionar un área`,
          });
          return;
        }
        if(!roleIdSelect) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `Debe de seleccionar un rol`,
          });
          return;
        }
        if(!formValues.names) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `Debe de ingresar los nombres del empleado`,
          });
          return;
        }
        if(!formValues.lastNames) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `Debe de ingresar los apellidos del empleado`,
          });
          return;
        }
        if(!formValues.dni) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `Debe de ingresar el numero de documento`,
          });
          return;
        }
        data = {
          formValues,
          roleIdSelect,
          areaIdSelect,
          typeTransaction: type
        }
      } else {
        const {
          user_id
        } = objEmployeed
        data = {
          formValues: {
            names: formValues.names === undefined || formValues.names === null || formValues.names === '' ? objEmployeed.names: formValues.names,
            lastNames: formValues.lastNames === undefined || formValues.lastNames === null || formValues.lastNames === '' ? objEmployeed.last_names: formValues.lastNames,
            birthDate: formValues.birthDate === undefined || formValues.birthDate === null || formValues.birthDate === '' ? objEmployeed.birth_date: formValues.birthDate,
            address: formValues.address === undefined || formValues.address === null || formValues.address === '' ? objEmployeed.address: formValues.address,
            email: formValues.email === undefined || formValues.email === null || formValues.email === '' ? objEmployeed.emails[0].email: formValues.email,
            cellphone: formValues.cellphone === undefined || formValues.cellphone === null || formValues.cellphone === '' ? objEmployeed.cellphone: formValues.cellphone,
          },
          roleIdSelect: roleIdSelect === 0 || roleIdSelect === '' ? objEmployeed.rol_id: roleIdSelect,
          areaIdSelect: areaIdSelect === 0 || areaIdSelect === '' ? objEmployeed.area_id: areaIdSelect,
          user_id,
          typeTransaction: type
        }
        
      }
      Swal.fire({
        title: `Seguro de ${stateTypeTransaction ? 'actualizar': 'guardar'} el registro del nuevo empleado?`,
        text: "Si, estoy seguro!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: `Si ${stateTypeTransaction ? 'actualizar': 'guardar'}`,
        cancelButtonText: 'Cancelar'
      }).then(async (result) => {
        if (result.isConfirmed) {
          data = JSON.stringify(data);
          setStateButtonSave(!stateButtonSave)
          let response = await ServicePostCreateEmployed(data);
          if (response.status === 200) {
            Swal.fire({
              title: `${stateTypeTransaction ? 'Actualización': 'Registro'} exitoso`,
              text: `Se ha ${stateTypeTransaction ? 'actualizado': 'registrado'} el empleado`,
              icon: "success"
            });
            await handleGetAllEmployeedRefresh();
            handleOnClearControls();
            setStateButtonSave(false);
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: `${response.error}`,
            });
          }
        }
      });
    }
  };
  async function handleGetAllEmployeedRefresh() {
    let data = await GetAllEmployeed();
    setEmployeed(data.data);
  }
  const handleOnClearControls = (_) => {
    setFormValues({
      names: '',
      lastNames: '',
      dni: '',
      birthDate: '',
      address: '',
      email: '',
      cellphone: ''
    });
    setActiveStep(0);
    setAreaIdSelect(0);
    setRoleIdSelect(0);
    setOpenModalEmployeedNew(false);
    if(openModalEmployeedEdit) {
      setOpenModalEmployeedEdit(false);
    }
  }
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleEmployeedNew = () => {
    setOpenModalEmployeedNew(!openModalEmployeedNew)
  }
  const handleOnCloseModalEmployeedNew = (e) => {
    handleOnClearControls();
  }
  const handleOnChangeInsert = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  }
  const handleFinallyRegister = (e) => {
    handleOnCloseModalEmployeedNew();
  }
  const handleOnChangeSelectArea = (e) => {
    setAreaIdSelect(e?.value);
  }
  const handleOnChangeSelectRole = (e) => {
    setRoleIdSelect(e?.value);
  }
  const handleOnKeyPressDni = async (e) => {
    const documentNumber = e.target.value;

    if (e.key === 'Enter') {
      if (parseInt(config.activar_uso_api_reniec) === 1) {
        const objDataUser = await ServiceGetBasedDocumentNumber(documentNumber, -1);
        if (objDataUser.status === 200) {
          const {
            user_id: id
          } = objDataUser.data;
          if (id) {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: `El empleado a registrar  con el numero de documento ${documentNumber}, ya existe.`,
            });
            return;
          }
        }
        const result = await getReniec(documentNumber, config.token_api_reniec, 'dni', config.url_web_api_reniec);
        if (result.success) {
          let objNamesLastNames = getNameAndLastName(result.nombre);
          setFormValues({
            names: objNamesLastNames.nombres,
            lastNames: objNamesLastNames.apellidos,
            dni: result.numero
          })
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `${result.message}.`,
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `El bit de control de buscar mediante la RENIEC, no está activado. Comunicate con el administrador del sistema.`,
        });
      }
    }
  }
  //Editar un empleado
  const handleOnEdit = (e, item) => {
    setOpenModalEmployeedEdit(!openModalEmployeedEdit);
    setObjEmployeed(item);
  }
  //Desactivar o activar un empleado
  const handleEnabledDisabled = (e, item) => {
    const {
      user_id,
      state_value,
      names,
      last_names
    } = item;
    Swal.fire({
      title: `Seguro de ${state_value ? 'inactivar' : 'activar'} el empleado?`,
      text: "Si, estoy seguro!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Si, ${state_value ? 'inactivar' : 'activar'}`,
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = false;
        if (!state_value) {
          response = await ServicePutEnabledEmployeed(user_id);
        } else {
          response = await ServiceDisabledEmployeed(user_id);
        }
        if (response.status === 200) {
          Swal.fire({
            title: `${response.data.message}`,
            text: `Se ha ${state_value ? 'inactivado' : 'activado'} el empleado - ${names}, ${last_names}.`,
            icon: "success"
          });
          await handleGetAllEmployeedRefresh();
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `${insert.error}`,
          });
        }
      }
    });
  }
  const handleOnCloseModalEmployeedEdit = (e) => {
    setOpenModalEmployeedEdit(false);
  }
  const handleSendEmailEmployeedCredentials = (e, item) => {
    setOpenModalSendCredentials(!openModalSendCredentials);
    setObjSendCred(item);
  }
  const handleOnCloseModalSendCredentials = () => {
    setOpenModalSendCredentials(false);
  }
  const handleOnClickSendEmail = (e) => {
    const {
      user_id
    } = objSendCred;
    let sendData = {
      user_id,
    }
    Swal.fire({
      title: `Seguro de enviar el correo a los destinatarios?`,
      text: "Si, estoy seguro!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Si enviar`,
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        sendData = JSON.stringify(sendData);
        const send = await ServiceSendEmailCredentials(sendData);
        if (send.status === 200) {
          Swal.fire({
            title: `Envío exitoso`,
            text: `Se ha enviado las credenciales de acceso.`,
            icon: "success"
          });
          handleOnCloseModalSendCredentials();
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `${send.error}`,
          });
        }
      }
    });
  }
  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Mantenimiento" breadcrumbItem="Trabajadores" />
      </Container>
      <List
        rows={employeed}
        handleEmployeedNew={handleEmployeedNew}
        handleOnEdit={handleOnEdit}
        handleSendEmailEmployeedCredentials={handleSendEmailEmployeedCredentials}
        handleEnabledDisabled={handleEnabledDisabled}
      />
      {
        openModalEmployeedNew && (
          <Modal
            title={`Nuevo Empleado`}
            size='modal-xl'
            onClose={handleOnCloseModalEmployeedNew}
          >
            <FormCreate
              handleOnChangeInsert={handleOnChangeInsert}
              handleFinallyRegister={handleFinallyRegister}
              activeStep={activeStep}
              handleNext={handleNext}
              isStepOptional={isStepOptional}
              isStepSkipped={isStepSkipped}
              handleBack={handleBack}
              steps={steps}
              areas={areas}
              roles={roles}
              areaIdSelect={areaIdSelect}
              handleOnChangeSelectArea={handleOnChangeSelectArea}
              handleOnChangeSelectRole={handleOnChangeSelectRole}
              handleOnKeyPressDni={handleOnKeyPressDni}
              formValues={formValues}
              handleOnCloseModalEmployeedNew={handleOnCloseModalEmployeedNew}
            />
          </Modal>
        )}
      {
        openModalEmployeedEdit && (
          <Modal
            title={`Editar Empleado`}
            size='modal-xl'
            onClose={handleOnCloseModalEmployeedEdit}
          >
            <FormUpdate
              handleOnChangeInsert={handleOnChangeInsert}
              handleFinallyRegister={handleFinallyRegister}
              activeStep={activeStep}
              handleNext={handleNext}
              isStepOptional={isStepOptional}
              isStepSkipped={isStepSkipped}
              handleBack={handleBack}
              steps={steps}
              areas={areas}
              roles={roles}
              areaIdSelect={areaIdSelect}
              handleOnChangeSelectArea={handleOnChangeSelectArea}
              handleOnChangeSelectRole={handleOnChangeSelectRole}
              handleOnKeyPressDni={handleOnKeyPressDni}
              formValues={formValues}
              handleOnCloseModalEmployeedEdit={handleOnCloseModalEmployeedEdit}
              objEmployeed={objEmployeed}
            />
          </Modal>
        )}
      {
        openModalSendCredentials && (
          <Modal
            title={`Enviar credenciales de acceso`}
            size='modal-xs'
            onClose={handleOnCloseModalSendCredentials}
          >
            <SendEmailVouchers
              item={objSendCred}
              type="EMPLOYEED"
              onClose={handleOnCloseModalSendCredentials}
              onSendEmail={handleOnClickSendEmail}
              // handleOnChangeSend={handleOnChangeSend}
            />
          </Modal>
        )}
    </div>
  )
}