import { Uri_AnulaMovementExitNote, Uri_GetAllEntryNote, Uri_GetAllExitNote, Uri_PostInsertEntryNote, Uri_PostInsertExitNote } from "../api/movements/apiMovements";
import { get, post} from "../helpers/api_helper";

export async function ServicePostInsertExitNote(data) {
  return await post(Uri_PostInsertExitNote, data);
}
export async function ServicePostInsertEntryNote(data) {
  return await post(Uri_PostInsertEntryNote, data);
}
export async function ServiceAnulaMovementExitNote(data) {
  return await post(Uri_AnulaMovementExitNote, data);
}
export async function ServiceGetAllExitNote() {
  return await get(Uri_GetAllExitNote);
}
export async function ServiceGetAllEntryNote() {
  return await get(Uri_GetAllEntryNote);
}
