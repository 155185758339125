import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import TableContainer from "../../../components/Common/TableContainer";

import {
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Label,
  Input,
  FormFeedback,
  UncontrolledTooltip,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavLink,
} from "reactstrap";

import * as Yup from "yup";
import { useFormik } from "formik";

import { Email, Tags, Projects, Img } from "./Column";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import DeleteModal from "../../../components/Common/DeleteModal";

import {
  getUsers as onGetUsers,
  addNewUser as onAddNewUser,
  updateUser as onUpdateUser,
  deleteUser as onDeleteUser,
} from "../../../store/actions";

import { isEmpty } from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { Badge } from "reactstrap";
import { AddNewItem, CustomOptions, ExportImportExcel } from "../../organism";
import { COLOR_BUTTON_MAB } from "../../../helpers/color";

const List = ({
  rows = [],
  handleNewExitNote,
  handleDownloadPDF,
  handleSendEmail,
  handleAnular
}) => {
  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const contactlistData = createSelector(

    (state) => state.contacts,
    (state) => ({
      users: state.users,
    })
  );
  const { users } = useSelector(contactlistData);
  
  const columns = useMemo(
    () => [
      // {
      //   Header: "#",
      //   Cell: () => {
      //     return <input type="checkbox" className="form-check-input" />;
      //   },
      // },
      {
        Header: "Serie",
        filterable: false,
        disableFilters: true,
        accessor: "serie",
        Cell: (product) => (
          <>
            <span className="font-size-14 mb-1">
              <p style={{color: product?.cell.row?.original.state_value === 2 ? COLOR_BUTTON_MAB: 'black', textDecoration: product?.cell?.row?.original.state_value === 2 ? 'line-through': ''}}>
                {product?.cell.row.original.serie}
              </p>
            </span>
          </>
        ),
      },
      {
        Header: () => ( // Utilizamos una función para personalizar el encabezado
          <div className="text-center"> {/* Aplicamos la clase text-center aquí */}
            Número
          </div>
        ),
        filterable: false,
        disableFilters: true,
        accessor: "number",
        Cell: (product) => (
          <div className="text-center">
            <span className="font-size-14">
              <p style={{color: product?.cell.row?.original.state_value === 2 ? COLOR_BUTTON_MAB: 'black', textDecoration: product?.cell?.row?.original.state_value === 2 ? 'line-through': ''}}>
                {product?.cell.value}
              </p>
            </span>
          </div>
        ),
      },
      {
        Header: () => ( // Utilizamos una función para personalizar el encabezado
          <div className="text-center"> {/* Aplicamos la clase text-center aquí */}
            Cliente
          </div>
        ),
        filterable: false,
        disableFilters: true,
        accessor: "customer",
        Cell: (product) => (
          <div className="text-center">
            <span className="font-size-14 mb-1">
              <p style={{color: product?.cell.row?.original.state_value === 2 ? COLOR_BUTTON_MAB: 'black', textDecoration: product?.cell?.row?.original.state_value === 2 ? 'line-through': ''}}>
                {product?.cell.value}
              </p>
            </span>
          </div>
        ),
      },
      {
        Header: () => ( // Utilizamos una función para personalizar el encabezado
          <div className="text-center"> {/* Aplicamos la clase text-center aquí */}
            Chofer
          </div>
        ),
        filterable: false,
        disableFilters: true,
        accessor: "driver",
        Cell: (product) => (
          <div className="text-center">
            <span className="font-size-14 mb-1">
              <p style={{color: product?.cell.row?.original.state_value === 2 ? COLOR_BUTTON_MAB: 'black', 
                textDecoration: product?.cell?.row?.original.state_value === 2 ? 'line-through': ''}}>
                {product?.cell.value}
              </p>
            </span>
          </div>
        ),
      },
      {
        Header: () => ( // Utilizamos una función para personalizar el encabezado
          <div className="text-center"> {/* Aplicamos la clase text-center aquí */}
            Fecha Registro
          </div>
        ),
        filterable: false,
        disableFilters: true,
        accessor: "created_at",
        Cell: (product) => (
          <div className="text-center">
            <span className="font-size-14 mb-1">
              <p style={{color: product?.cell.row?.original.state_value === 2 ? COLOR_BUTTON_MAB: 'black', 
                textDecoration: product?.cell?.row?.original.state_value === 2 ? 'line-through': ''}}>
                {product?.cell.value}
              </p>
            </span>
          </div>
        ),
      },
      {
        Header: () => ( // Utilizamos una función para personalizar el encabezado
          <div className="text-center"> {/* Aplicamos la clase text-center aquí */}
            Estado
          </div>
        ),
        filterable: false,
        disableFilters: true,
        accessor: "state",
        Cell: (exitNote) => (
          <div className="text-center">
            <span className="font-size-14 mb-1">
              <Badge color={showColorState(exitNote?.cell.row?.original.state_value)}>
                {exitNote?.cell.row.original.state}
              </Badge>
            </span>
          </div>
        )
      },
      {
        Header: () => ( // Utilizamos una función para personalizar el encabezado
          <div className="text-center"> {/* Aplicamos la clase text-center aquí */}
            Acción
          </div>
        ),
        filterable: false,
        disableFilters: true,
        accessor: "accion",
        Cell: (exitNote) => (
          <div className="text-center">
            <CustomOptions 
              componenteType='ListExitsNote'
              item={exitNote.cell.row.original}
              handleDownloadPDF={handleDownloadPDF}
              handleSendEmail={handleSendEmail}
              handleAnular={handleAnular}
            />
          </div>
        )
      },
    ],
    []
  );
  const showColorState = (value) => {
    return value === 1 ? `success` : value === 2 ? 'danger': value === 3 ? 'warning': 'primary';
  }

  useEffect(() => {
    if (users && !users.length) {
      dispatch(onGetUsers());
      setIsEdit(false);
    }
  }, [dispatch, users]);

  useEffect(() => {
    setContact(users);
    setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };

  const handleUserClick = (arg) => {
    const user = arg;

    setContact({
      id: user.id,
      name: user.name,
      designation: user.designation,
      email: user.email,
      tags: user.tags,
      projects: user.projects,
    });
    setIsEdit(true);

    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = (page) => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };
  const [deleteModal, setDeleteModal] = useState(false);
  const onClickDelete = (users) => {
    setContact(users);
    setDeleteModal(true);
  };
  const handleDeleteUser = () => {
    dispatch(onDeleteUser(contact.id));
    onPaginationPageChange(1);
    setDeleteModal(false);
  };
  const handleUserClicks = () => {
    setIsEdit(false);
    toggle();
  };
  return (
    <React.Fragment>
      <Row>
        <Col lg="12">
          <Row className="align-items-center">
            <Col md={6}>
            </Col>
            <Col md={6}>
              <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                <AddNewItem 
                  title="Nueva Nota de Salida"
                  handleOnClick={handleNewExitNote}
                  navigateUrl={'/tesoreria/nota-salida/registro'}
                />
                <ExportImportExcel 
                  showImport={false}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl="12" className="mb-4">
              <TableContainer
                columns={columns}
                data={rows}
                isGlobalFilter={true}
                isAddUserList={true}
                customPageSize={10}
                className="table align-middle datatable dt-responsive table-check nowrap"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default List;
