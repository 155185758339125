import { Uri_GetAllVehicleLicenseByLicense, Uri_GetAllVehicleLicenseCartByLicense, Uri_GetAllVehicles, Uri_GetAllVehiclesAvailable, Uri_GetValidateAssignment, Uri_PostCreateVehicle, Uri_PostVehicleCartNew, Uri_PutDisabledVehicle, Uri_PutEnabledVehicle, Uri_PutUpdateVehicle } from "../api/vehicle/api-vehicle";
import { get, post, put } from "../helpers/api_helper";

export async function ServiceGetAllVehicles() {
  return await get(Uri_GetAllVehicles);
}
export async function ServiceGetAllVehiclesAvailable() {
  return await get(Uri_GetAllVehiclesAvailable);
}
export async function ServiceGetValidateAssignment(vehicleId) {
  return await get(Uri_GetValidateAssignment(vehicleId));
}
export async function ServicePostCreateVehicle(data) {
  return await post(Uri_PostCreateVehicle, data);
}
export async function ServicePutUpdateVehicle(data) {
  return await put(Uri_PutUpdateVehicle, data);
}
export async function ServicePutDisabledVehicle(vehicleId) {
  return await put(Uri_PutDisabledVehicle(vehicleId));
}
export async function ServicePutEnabledVehicle(vehicleId) {
  return await put(Uri_PutEnabledVehicle(vehicleId));
}
export async function ServiceGetAllVehicleLicense(data) {
  return await post(Uri_GetAllVehicleLicenseByLicense, data)
}
export async function ServiceGetAllVehicleLicenseCart(data) {
  return await post(Uri_GetAllVehicleLicenseCartByLicense, data)
}
export async function ServicePostVehicleCartNew(data) {
  return await post(Uri_PostVehicleCartNew, data)
}