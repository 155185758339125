import React, { useEffect, useState, useRef, useMemo, Fragment } from "react";
import { Link } from "react-router-dom";
import TableContainer from "../../Common/TableContainer";
import {
  Col,
  Row,

} from "reactstrap";
import { Badge } from "reactstrap";
import { CustomOptions } from "../../organism";
import { COLOR_BUTTON_MAB } from "../../../helpers/color";

const ListVehicleAvailable = ({
  rows = []
  , handleOnAssignmentVehicle
  , objDriver = {}
  , isEdit = false
  , handleOnDeleteAssignmentVehicle
  , entryShow = 5
  , showIconsVehicle = false
  , showOptimizableColumns = false
}) => {
  let columns = []
  if(!showOptimizableColumns) {
    columns = useMemo(
      () => [
        {
          Header: "Placa",
          filterable: false,
          disableFilters: true,
          accessor: "vehicle_license_plate",
          Cell: (driver) => (
            <>
              <span className="font-size-14 mb-1">
                <Link to="#" className="text-dark">
                  {driver?.cell.row.original.vehicle_license_plate} 
                </Link>
              </span>
            </>
          ),
        },
        {
          Header: "Marca",
          filterable: false,
          disableFilters: true,
          accessor: "marca",
          Cell: (product) => (
            <>
              <span className="font-size-14 mb-1">
                <Link to="#" className="text-dark">
                  {product?.cell.row.original.brand}
                </Link>
              </span>
            </>
          ),
        },
        {
          Header: "Chofer asignado",
          filterable: false,
          disableFilters: true,
          accessor: "driver",
          Cell: (product) => (
            <>
              <span className="font-size-14 mb-1">
                <Link to="#" className="text-dark">
                  {product?.cell.row.original.driver}
                  {
                    objDriver.driver_id === product?.cell.row.original.driver_id ? <strong style={{color: COLOR_BUTTON_MAB}}> (Asignado a tí) </strong>: ''
                  }
                </Link>
              </span>
            </>
          ),
        },
        {
          Header: () => ( // Utilizamos una función para personalizar el encabezado
            <div className="text-center"> {/* Aplicamos la clase text-center aquí */}
              Estado
            </div>
          ),
          filterable: false,
          disableFilters: true,
          accessor: "state",
          Cell: (product) => (
            <div className="text-center">
              <span className="font-size-14 mb-1">
                <Badge color={showColorState(product?.cell?.row.original.state_value)}>
                  {product?.cell.row.original.state}
                </Badge>
              </span>
            </div>
          )
        },
        {
          Header: () => ( // Utilizamos una función para personalizar el encabezado
            <div className="text-center"> {/* Aplicamos la clase text-center aquí */}
              Acción / Asignar
            </div>
          ),
          filterable: false,
          disableFilters: true,
          accessor: "accion",
          Cell: (user) => (
            <div className="text-center">
              <CustomOptions 
                componenteType = 'ListVehicleAvailable'
                item={user.cell.row.original}
                handleOnAssignmentVehicle={handleOnAssignmentVehicle}
                isEdit={isEdit}
                handleOnDeleteAssignmentVehicle={handleOnDeleteAssignmentVehicle}
                isDelete={objDriver.driver_id === user?.cell.row.original.driver_id}
                showIconsVehicle={showIconsVehicle}
              />
            </div>
          )
        },
      ],
      []
    );
  } else {
    columns = useMemo(
      () => [
        {
          Header: "Placa",
          filterable: false,
          disableFilters: true,
          accessor: "vehicle_license_plate",
          Cell: (driver) => (
            <>
              <span className="font-size-14 mb-1">
                <Link to="#" className="text-dark">
                  {driver?.cell.row.original.vehicle_license_plate} 
                </Link>
              </span>
            </>
          ),
        },
        {
          Header: "Marca",
          filterable: false,
          disableFilters: true,
          accessor: "marca",
          Cell: (product) => (
            <>
              <span className="font-size-14 mb-1">
                <Link to="#" className="text-dark">
                  {product?.cell.row.original.brand}
                </Link>
              </span>
            </>
          ),
        },
        {
          Header: "Chofer asignado",
          filterable: false,
          disableFilters: true,
          accessor: "driver",
          Cell: (product) => (
            <>
              <span className="font-size-14 mb-1">
                <Link to="#" className="text-dark">
                  {product?.cell.row.original.driver}
                  {
                    objDriver.driver_id === product?.cell.row.original.driver_id ? <strong style={{color: COLOR_BUTTON_MAB}}> (Asignado a tí) </strong>: ''
                  }
                </Link>
              </span>
            </>
          ),
        },
        {
          Header: () => ( // Utilizamos una función para personalizar el encabezado
            <div className="text-center"> {/* Aplicamos la clase text-center aquí */}
              Acción / Asignar
            </div>
          ),
          filterable: false,
          disableFilters: true,
          accessor: "accion",
          Cell: (user) => (
            <div className="text-center">
              <CustomOptions 
                componenteType = 'ListVehicleAvailable'
                item={user.cell.row.original}
                handleOnAssignmentVehicle={handleOnAssignmentVehicle}
                isEdit={isEdit}
                handleOnDeleteAssignmentVehicle={handleOnDeleteAssignmentVehicle}
                isDelete={objDriver.driver_id === user?.cell.row.original.driver_id}
                showIconsVehicle={showIconsVehicle}
              />
            </div>
          )
        },
      ],
      []
    );
  }
  const showColorState = (value) => {
    return value === 1 ? `success` : value === 2? 'warning': value === 3 ? 'danger': 'primary';
  }
  return (
    <Fragment>
      <Row>
        <Col lg="12" className="mt-4">
          <Row>
            <Col xl="12">
              <TableContainer
                columns={columns}
                data={rows}
                isGlobalFilter={true}
                isAddUserList={true}
                customPageSize={entryShow}
                entryShow={entryShow}
                className="table align-middle datatable dt-responsive table-check nowrap"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Fragment>
  );
};

export default ListVehicleAvailable;
