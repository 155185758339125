import React from "react";
import { Container } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import List from './List';
import { getTitle } from "../../../helpers/general_functions";
import { useGetAllDocumentType } from "../../../hooks";

export default function Manager() {
  const {documentsType} = useGetAllDocumentType();
  getTitle('Tipos de documento');
  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Mantenimiento" breadcrumbItem="Tipos de documento" />
      </Container>
      <List 
        rows={documentsType}
      />
    </div>
  )
}