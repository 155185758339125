import { Uri_GetAllCategory, Uri_PostCreateCategory, Uri_PutUpdateCategory } from "../api/category/api-category";
import { get, post, put } from "../helpers/api_helper";

export async function ServiceGetAllCategory() {
  return await get(Uri_GetAllCategory);
}
export async function ServicePostCreateCategory(data) {
  return await post(Uri_PostCreateCategory, data);
}
export async function ServicePutUpdateCategory(data) {
  return await put(Uri_PutUpdateCategory, data);
}
