import React, { useState } from "react";
import { Container } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import useGetAllProducts from "../../../hooks/product-hook";
import List from './List';
import { getTitle } from "../../../helpers/general_functions";
import { Modal } from "../../organism";
import FormCreate from './FormCreate';
import { useGetAllCategory, useGetAllPresentation } from "../../../hooks/";
import Swal from 'sweetalert2';
import { ServiceGetAllProducts, ServicePostNewProduct, ServicePutDisabledProduct, ServicePutEditProduct, ServicePutEnabledProduct } from "../../../service/product-service";
import FormUpdate from "./FormUpdate";

export default function Manager() {
  const { products, setProducts } = useGetAllProducts();
  const { presentations } = useGetAllPresentation();
  const { categorys } = useGetAllCategory();
  const [objEditProduct, setObjEditProduct] = useState({});

  const [formValues, setFormValues] = useState({
    nameProduct: '',
    code: '',
    purchasePrice: 0.00,
    purchaseSale: 0.00,
    quantity: 0.00,
    selectValueCategory: null,
    selectValuePresentation: null,
  });

  const [openModalProductNew, setOpenModalProductNew] = useState(false);
  const [openModalProductEdit, setOpenModalProductEdit] = useState(false);
  getTitle('Productos');

  const handleOnClickProductNew = (_) => {
    setOpenModalProductNew(!openModalProductNew);
  }
  const handleOnCloseProductNew = (_) => {
    setOpenModalProductNew(!!!openModalProductNew)
  }
  const onHandleChangeInsert = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  }
  const handleGetAllProducts = async() => {
    let data = await ServiceGetAllProducts();
    setProducts(data.data);
  }
  const onHandleChangeSelectInsert = (selectedOption, name) => {
    setFormValues({
      ...formValues,
      [name]: selectedOption,
    });
  }
  const handleOnSaveProductNew = (e) => {
    if(formValues.selectValueCategory === null || formValues.selectValueCategory === undefined) {
      Swal.fire({
        icon: "error",
        title: "Advertencia",
        text: `Debe de seleccionar una categoría`,
      });
      return;
    }
    if(formValues.selectValuePresentation === null || formValues.selectValuePresentation === undefined) {
      Swal.fire({
        icon: "error",
        title: "Advertencia",
        text: `Debe de seleccionar una presentación`,
      });
      return;
    } 
    if(!name_product) {
      Swal.fire({
        icon: "error",
        title: "Advertencia",
        text: `Debe de ingresar el nombre del producto`,
      });
      return;
    }
    if(!bar_code) {
      Swal.fire({
        icon: "error",
        title: "Advertencia",
        text: `Debe de ingresar el código del producto`,
      });
      return;
    }
    if(!sale_price) {
      Swal.fire({
        icon: "error",
        title: "Advertencia",
        text: `Debe de ingresar el precio de venta`,
      });
      return;
    }
    if(!sale_price) {
      Swal.fire({
        icon: "error",
        title: "Advertencia",
        text: `Debe de ingresar el stock`,
      });
      return;
    }
    let dataInsert = {
      name_product: formValues.nameProduct,
      image: '',
      purchase_price: parseFloat(formValues.purchasePrice),
      sale_price: parseFloat(formValues.purchaseSale),
      show_stock_sale: 1,
      show_stock_warehouse: 0,
      stock_sale: parseInt(formValues.quantity),
      bar_code: formValues.code,
      category: {
        category_id: parseInt(formValues.selectValueCategory.id)
      },
      presentation: {
        presentation_id: parseInt(formValues.selectValuePresentation.id)
      }
    };
    Swal.fire({
      title: "Seguro de guardar el producto?",
      text: "Si, estoy seguro!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, guardar",
      cancelButtonText: 'Cancelar'
    }).then(async(result) => {
      if (result.isConfirmed) {
        let insert = await ServicePostNewProduct(dataInsert);
        if(insert.status === 200) {
          Swal.fire({
            title: `${insert.data.message}`,
            text: `Se ha registrado el producto ${dataInsert.name_product}.`,
            icon: "success"
          });
          setOpenModalProductNew(false);
          await handleGetAllProducts();
          handleClearControls();
        } else {
          Swal.fire({
            icon: "error",
            title: "Advertencia",
            text: `${insert.error}`,
          });
        }
      }
    });
  }
  const handleClearControls = () => {
    setFormValues({});
  }
  // Editar producto
  const handleOnEdit = (e, item) => {
    setObjEditProduct(item);
    setOpenModalProductEdit(true);
  }
  const handleOnCloseProductEdit = (e) => {
    setOpenModalProductEdit(false);
  }
  const handleOnSaveProductoEdit = (_) => {
    const {
      id
    } = objEditProduct
    let dataEdit = {
      product_id: parseInt(id),
      name_product: formValues.nameProduct === undefined || formValues.nameProduct === null || formValues.nameProduct === '' ? objEditProduct?.producto_descripcion: formValues.nameProduct,
      image: '',
      purchase_price: formValues.purchasePrice === undefined || formValues.purchasePrice === null || formValues.purchasePrice === '' ? objEditProduct?.precio_compra: formValues.purchasePrice,
      sale_price: formValues.purchaseSale === undefined || formValues.purchaseSale === null || formValues.purchaseSale === '' ? objEditProduct?.precio_venta: formValues.purchaseSale,
      show_stock_sale: 1,
      show_stock_warehouse: 0,
      stock_sale: formValues.quantity === undefined || formValues.quantity === null || formValues.quantity === '' ? objEditProduct.stock_venta: formValues.quantity,
      bar_code: formValues.code === undefined || formValues.code === null || formValues.code === '' ? objEditProduct.codigo: formValues.code,
      category: {
        category_id: formValues.selectValueCategory === null || formValues.selectValueCategory === undefined ? objEditProduct?.category_id: parseInt(formValues.selectValueCategory.id)
      },
      presentation: {
        presentation_id: formValues.selectValuePresentation === null || formValues.selectValuePresentation === undefined ? objEditProduct.presentation_id: parseInt(formValues.selectValuePresentation.id)
      }
    }
    Swal.fire({
      title: "Seguro de editar el producto?",
      text: "Si, estoy seguro!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, editar",
      cancelButtonText: 'Cancelar'
    }).then(async(result) => {
      if (result.isConfirmed) {
        let update = await ServicePutEditProduct(dataEdit);
        if(update.status === 200) {
          Swal.fire({
            title: `${update.data.message}`,
            text: `Se ha actualizado el producto ${dataEdit.name_product}.`,
            icon: "success"
          });
          setOpenModalProductEdit(false);
          await handleGetAllProducts();
          handleClearControls();
        } else {
          Swal.fire({
            icon: "error",
            title: "Advertencia",
            text: `${update.error}`,
          });
        }
      }
    });
  }
  const handleEnabledDisabled = async(e, item) => {
    const {
      id,
      valor_estado,
      producto_descripcion
    } = item;
    Swal.fire({
      title: `Seguro de ${valor_estado ? 'inactivar': 'activar'} el producto?`,
      text: "Si, estoy seguro!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Si, ${valor_estado ? 'inactivar': 'activar'}`,
      cancelButtonText: 'Cancelar'
    }).then(async(result) => {
      if (result.isConfirmed) {
        let response = false;
        if(!valor_estado) {
          response = await ServicePutEnabledProduct(id);
        } else {
          response = await ServicePutDisabledProduct(id);
        }
        if(response.status === 200) {
          Swal.fire({
            title: `${response.data.message}`,
            text: `Se ha ${valor_estado ? 'inactivado': 'activado'} el producto ${producto_descripcion}.`,
            icon: "success"
          });
          await handleGetAllProducts();
        } else {
          Swal.fire({
            icon: "error",
            title: "Advertencia",
            text: `${insert.error}`,
          });
        }
      }
    });

  }
  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Mantenimiento" breadcrumbItem="Productos" />
      </Container>
      <List
        rows={products}
        handleOnClickProductNew={handleOnClickProductNew}
        handleOnEdit={handleOnEdit}
        handleEnabledDisabled={handleEnabledDisabled}
      />
      {
        openModalProductNew && (
          <Modal
            title={`Nuevo producto`}
            size='modal-lg'
            onClose={handleOnCloseProductNew}
          >
          <FormCreate 
            handleOnCloseProductNew={handleOnCloseProductNew}
            presentations={presentations}
            categorys={categorys}
            onHandleChangeInsert={onHandleChangeInsert}
            onHandleChangeSelectInsert={onHandleChangeSelectInsert}
            handleOnSaveProductNew={handleOnSaveProductNew}
          />
          </Modal>
      )}
      {
        openModalProductEdit && (
          <Modal
            title={`Editar producto ${objEditProduct.producto_descripcion}`}
            size='modal-lg'
            onClose={handleOnCloseProductEdit}
          >
          <FormUpdate
            handleOnCloseProductEdit={handleOnCloseProductEdit}
            objEditProduct={objEditProduct}
            presentations={presentations}
            categorys={categorys}
            onHandleChangeInsert={onHandleChangeInsert}
            onHandleChangeSelectInsert={onHandleChangeSelectInsert}
            handleOnSaveProductoEdit={handleOnSaveProductoEdit}
          />
          </Modal>
      )}
    </div>
  )
}