import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { IconButton, Tooltip } from '@mui/material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import DownloadIcon from '@mui/icons-material/Download';
export default function CustomOptions({
  componenteType = '',
  handleOnEdit,
  item = {},
  handleEnabledDisabled,
  handleOnAssignmentVehicle,
  isEdit = false,
  handleOnDeleteAssignmentVehicle,
  isDelete = false,
  handleDeleteItem,
  handleDownloadPDF,
  handleSendEmail,
  showIconsVehicle,
  handleDownloadPdfPurchase,
  handleAnular
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  switch (componenteType) {
    case 'ListProduct':
    case 'ListDriver':
      return (
        <div >
          <Button
            id="basic-button"
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            style={{ fontFamily: 'Arial', fontSize: '16px' }}
          >
            <MoreVertIcon />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            onClick={handleClose}
          >
            <MenuItem onClick={(e) => handleOnEdit(e, item)}>Editar</MenuItem>
            <MenuItem onClick={(e) => handleEnabledDisabled(e, item)}>Inactivar / Activar</MenuItem>
          </Menu>
        </div>
      );
    case 'ListEmployeed':
      return (
        <div>
          <Button
            id="basic-button"
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            style={{ fontFamily: 'Arial', fontSize: '16px' }}
          >
            <MoreVertIcon />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClick={handleClose}
          >
            <MenuItem onClick={(e) => handleOnEdit(e, item)}>Editar</MenuItem>
            <MenuItem onClick={(e) => handleEnabledDisabled(e, item)}>Inactivar / Activar</MenuItem>
            <MenuItem onClick={(e) => handleSendEmail(e, item)}>Enviar Credenciales</MenuItem>
            {/* <MenuItem onClick={handleClose}>Historial de acceso</MenuItem> */}
            {/* <MenuItem onClick={handleClose}>Ver Asistencia</MenuItem> */}
          </Menu>
        </div>
      );
    case 'ListCategory':
      return (
        <div>
          <Button
            id="basic-button"
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            style={{ fontFamily: 'Arial', fontSize: '16px' }}
          >
            <MoreVertIcon />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClick={handleClose}
          >
            <MenuItem onClick={(e) => handleOnEdit(e, item)}>Editar</MenuItem>
            <MenuItem onClick={(e) => handleEnabledDisabled(e, item)}>Inactivar / Activar</MenuItem>
          </Menu>
        </div>
      );
    case 'ListPresentation':
      return (
        <div>
          <Button
            id="basic-button"
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            style={{ fontFamily: 'Arial', fontSize: '16px' }}
          >
            <MoreVertIcon />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClick={handleClose}
          >
            <MenuItem onClick={(e) => handleOnEdit(e, item)}>Editar</MenuItem>
            <MenuItem onClick={(e) => handleEnabledDisabled(e, item)}>Inactivar / Activar</MenuItem>
          </Menu>
        </div>
      );
    case 'ListDocumentType':
    case 'ListVehicleAvailable':
      return (
        <div>
          <Button
            id="basic-button"
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            style={{ fontFamily: 'Arial', fontSize: '16px' }}
          >
            <Tooltip title={`${item.state_value ? 'Vehículo ya asignado': 'Asigna el vehículo'}`}>
              <ArrowRightAltIcon /> <LocalShippingIcon /> <MoreVertIcon />
            </Tooltip>
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClick={handleClose}
          >
            <MenuItem onClick={(e) => handleOnAssignmentVehicle(e, item, (isEdit) ? 'update': 'new')} disabled={item.state_value}>Asignar vehículo</MenuItem>
            {
              isEdit ?
              <MenuItem onClick={(e) => handleOnDeleteAssignmentVehicle(e, item)} disabled={!isDelete}>Quitar vehículo</MenuItem>
              : ''
            }
          </Menu>
        </div>
      );
    case 'PurchaseSettlement':
      return (
        <div className='row'>
          <div className='col-md-3'></div>
          <div className='col-md-3'>
            <Tooltip title='Descargar'>
              <IconButton
                aria-label="Descargar"
                onClick={(e) => handleDownloadPdfPurchase(e, item)}
                style={{ fontFamily: 'Arial', fontSize: '16px' }}
              >
                <DownloadIcon />
              </IconButton>
            </Tooltip>
          </div>
          {/* <div className='col-md-3'>
            <Tooltip title='Editar'>
              <IconButton
                aria-label="Editar"
                onClick={(e) => handleDeleteItem(e, item)}
                style={{ fontFamily: 'Arial', fontSize: '16px' }}
                disabled
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </div> */}
        </div>
      );
    case 'ListAddRemoveItem':
      return (
        <Tooltip title='Eliminar'>
          <IconButton
            aria-label="Eliminar"
            onClick={(e) => handleDeleteItem(e, item)}
            style={{ fontFamily: 'Arial', fontSize: '16px' }}
          >
            <DeleteOutlineIcon />
          </IconButton>
        </Tooltip>
      );
    case 'ListExitsNote':
      return (
        <div>
          <Button
            id="basic-button"
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            style={{ fontFamily: 'Arial', fontSize: '16px' }}
          >
            <MoreVertIcon />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClick={handleClose}
          >
            <MenuItem onClick={(e) => handleDownloadPDF(e, item)}>
            <div className="text-center" id="DetalleButon" title="Ver detalle">
              <a
                href={item.pdf}
                target="_blank"
                rel="noopener noreferrer"
                title={`Ver documentos`}
              >
                Ver comprobante
              </a>
            </div>
            </MenuItem>
            <MenuItem onClick={(e) => handleAnular(e, item)} disabled={item.state_value === 2}>Anular</MenuItem>
            {/* <MenuItem onClick={(e) => handleDeleteItem(e, item.id)}>Anular comprobante</MenuItem> */}
          </Menu>
        </div>
      );
    default:
      break;
  }
}
