import React from "react";
import { ButtonFormControl, InputFormControl, SelectedFormControl } from "../../molecules";

export default function FormUpdate({
  handleOnCloseProductEdit
  , objEditProduct
  , categorys
  , presentations
  , handleOnSaveProductoEdit
  , onHandleChangeSelectInsert
  , onHandleChangeInsert
}) {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <InputFormControl
            type={'text'}
            label={'Nombre del producto'}
            nameInput={'nameProduct'}
            defaultValue={objEditProduct.producto_descripcion}
            onHandleChange={onHandleChangeInsert}
          />
        </div>
        <div className="col-md-6">
          <InputFormControl
            type={'text'}
            label={'Código de barras'}
            nameInput={'code'}
            defaultValue={objEditProduct?.codigo}
            onHandleChange={onHandleChangeInsert}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          <InputFormControl
            type={'number'}
            label={'Precio de compra'}
            nameInput={'purchasePrice'}
            defaultValue={objEditProduct?.precio_compra}
            onHandleChange={onHandleChangeInsert}
          />
        </div>
        <div className="col-md-3">
          <InputFormControl
            type={'number'}
            label={'Precio de venta'}
            nameInput={'purchaseSale'}
            defaultValue={objEditProduct?.precio_venta}
            onHandleChange={onHandleChangeInsert}
          />
        </div>
        <div className="col-md-3">
          {/* <InputFormControl
            type={'number'}
            label={'Cantidad'}
            nameInput={'quantity'}
            defaultValue={objEditProduct?.stock_venta}
            onHandleChange={onHandleChangeInsert}
          /> */}
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <SelectedFormControl
            label={'Categoría'}
            placeholder={'Seleccione una categoría...'}
            options={categorys}
            nameSelect='categoryId'
            onHandleChange={(selectedOption) => onHandleChangeSelectInsert(selectedOption, 'selectValueCategory')}
            defaultValue={categorys.filter(category => parseInt(category.value) === parseInt(objEditProduct?.category_id))}
          />
        </div>
        <div className="col-md-6">
          <SelectedFormControl
            label={'Presentación'}
            placeholder={'Seleccione una presentación...'}
            options={presentations}
            nameSelect={'presentationId'}
            onHandleChange={(selectedOption) => onHandleChangeSelectInsert(selectedOption, 'selectValuePresentation')}
            defaultValue={presentations.filter(pre => parseInt(pre.value) === parseInt(objEditProduct?.presentation_id))}
          /> 
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-6"></div>
        <div className="col-md-6 d-flex justify-content-end">
          <div className="btn-group">
            <ButtonFormControl
              text={'Cancelar'}
              className={'btn btn-secondary btn-lg'}
              OnHandleClick={handleOnCloseProductEdit}
            />&nbsp;&nbsp;
            <ButtonFormControl
              text={'Guardar'}
              className={'btn btn-success btn-lg'}
              OnHandleClick={handleOnSaveProductoEdit}
            />
          </div>
        </div>
      </div >
    </div>
  )
}