
import { useEffect, useState } from "react";
import { ServiceGetAllPurchaseSettlement } from "../service/purchase-settlement";

export default function useGetAllPurchaseSettlement() {
  const [purchaseSettlements, setPurchaseSettlement] = useState([]);
  useEffect(() => {
    async function getAllPurchaseSettlement() {
      let data = await ServiceGetAllPurchaseSettlement();
      setPurchaseSettlement(data.data);
    }
    getAllPurchaseSettlement();
  }, []);
  return {purchaseSettlements, setPurchaseSettlement}
}