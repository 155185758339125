import React, {  useMemo, Fragment } from "react";
import { Link } from "react-router-dom";
import TableContainer from "./table-container-result";
import {
  Col,
  Row,

} from "reactstrap";
import { CustomOptions} from "../../organism";

const weightUnits = [
  {
    value: 1,
    label: 'Kilogramo'
  },
  {
    value: 2,
    label: 'Tonelada'
  }
]

const List = ({
  rows = []
  , handleOnEdit
  , handleEnabledDisabled
  , handleDeleteItem
  , handleOnChangeNetKilo
  , totalsResult = 0.00
  , productName
  , handleOnChangeDescriptionResult
  , handleOnChangeNumberBagsResult
  , handleOnChangePercentageHumedityResult
}) => {
  const columns = useMemo(
    () => [
      {
        Header: "Fecha",
        filterable: false,
        disableFilters: true,
        tipo: "text",
        Cell: (item) => (
          <>
            <span className="font-size-14 mb-1">
              <Link to="#" className="text-dark">
                {item?.cell.row.original.cantidad}
              </Link>
            </span>
          </>
        ),
      },
      {
        Header: "Unidad",
        filterable: false,
        disableFilters: true,
        accessor: "role",
        tipo: 'select',
        Cell: () => (
          <>
            <span className="font-size-14 mb-1">
              <Link to="#" className="text-dark">
                {/* {user?.cell.row.original.role.role} */}
              </Link>
            </span>
          </>
        ),
      },
      {
        Header: () => ( // Utilizamos una función para personalizar el encabezado
          <div className="text-center"> {/* Aplicamos la clase text-center aquí */}
            Desc. Mercadería
          </div>
        ),
        filterable: false,
        disableFilters: true,
        accessor: "naturaleza_producto",
        tipo: 'text',
        Cell: (item) => (
          <div className="text-center">
            <span className="font-size-14 mb-1">
              <Link to="#" className="text-dark">
                {item?.cell.row.original.naturaleza_producto}
              </Link>
            </span>
          </div>
        ),
      },
      {
        Header: () => ( // Utilizamos una función para personalizar el encabezado
          <div className="text-center"> {/* Aplicamos la clase text-center aquí */}
            Unidad de peso
          </div>
        ),
        filterable: false,
        disableFilters: true,
        accessor: "unidad_de_peso",
        Cell: (user) => (
          <div className="text-center">
            <span className="font-size-14 mb-1">
              <Link to="#" className="text-dark">
                {/* {user?.cell.row.original.emails[0].email} */}
              </Link>
            </span>
          </div>
        ),
      },
      {
        Header: () => ( // Utilizamos una función para personalizar el encabezado
          <div className="text-center"> {/* Aplicamos la clase text-center aquí */}
            Kgs / Tn Brutos
          </div>
        ),
        filterable: false,
        disableFilters: true,
        accessor: "NETOS",
        Cell: (user) => (
          <div className="text-center">
            <span className="font-size-14 mb-1">
              <Link to="#" className="text-dark">
                {/* {user?.cell.row.original.emails[0].email} */}
              </Link>
            </span>
          </div>
        ),
      },
      {
        Header: () => ( // Utilizamos una función para personalizar el encabezado
          <div className="text-center"> {/* Aplicamos la clase text-center aquí */}
            Kgs / Tn Netos
          </div>
        ),
        filterable: false,
        disableFilters: true,
        accessor: "email",
        Cell: (user) => (
          <div className="text-center">
            <span className="font-size-14 mb-1">
              <Link to="#" className="text-dark">
                {/* {user?.cell.row.original.emails[0].email} */}
              </Link>
            </span>
          </div>
        ),
      },
      {
        Header: () => ( // Utilizamos una función para personalizar el encabezado
          <div className="text-center"> {/* Aplicamos la clase text-center aquí */}
            Acción
          </div>
        ),
        filterable: false,
        disableFilters: true,
        accessor: "accion",
        Cell: (product) => (
          <div className="text-center">
            <CustomOptions 
              componenteType = 'ListAddRemoveItem'
              item={product.cell.row.original}
              handleOnEdit={handleOnEdit}
              handleEnabledDisabled={handleEnabledDisabled}
            />
          </div>
        )
      },
    ],
    []
  );
  return (
    <Fragment>
      <Row className="">
        <Col lg="12">
          <Row>
            <Col xl="12">
              <TableContainer
                columns={columns}
                data={rows}
                isGlobalFilter={true}
                isAddUserList={true}
                customPageSize={5}
                showEntries={false}
                className="table align-middle datatable dt-responsive table-check nowrap table table-bordered table-sm"
                handleDeleteItem={handleDeleteItem}
                handleOnChangeNetKilo={handleOnChangeNetKilo}
                totalsResult={totalsResult}
                productName={productName}
                handleOnChangeDescriptionResult={handleOnChangeDescriptionResult}
                handleOnChangeNumberBagsResult={handleOnChangeNumberBagsResult}
                handleOnChangePercentageHumedityResult={handleOnChangePercentageHumedityResult}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Fragment>
  );
};

export default List;
