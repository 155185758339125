
import React from 'react'
import { COLOR_BUTTON_MAB } from '../../helpers/color'

function InputFormControl({ 
  id = '',
  type, 
  placeholder, 
  onHandleChange, 
  onClick,
  className,
  label,
  bottom = false,
  isBold = false,
  isValid = true,
  labelIsValid,
  defaultValue = '',
  onKeyPress,
  autoFocus = false,
  disabled = false,
  style,
  maxlength = '',
  isRequerid = false,
  isOptional = false,
  selectRef,
  hidden = false,
  nameInput,
  value,
  showIsLabel = true
}) {
  return(
    <div className={`${className} ${!bottom ? 'mt-2': 'mt-3'}`}>
      {
        !hidden && showIsLabel ?
          isOptional ?
          <label className={isBold ? 'font-weight-bold': ''}>{label} <label className="text-warning"> * (Opc.)</label></label>
          : 
          label !== undefined && isRequerid ? 
          <label className={isBold ? 'font-weight-bold': ''}>{label} <label style={{color: isRequerid ? COLOR_BUTTON_MAB: ''}}> *</label></label>
          : 
          <label className={isBold ? 'font-weight-bold': ''}>{label} </label>
        : ''
      }
      <input 
        id={id}
        type={type}
        name={nameInput}
        className={`form-control`} 
        placeholder={placeholder} 
        onChange={onHandleChange} 
        onClick={onClick}
        onKeyPress={onKeyPress}
        autoComplete='nope'
        autoFocus={autoFocus}
        defaultValue={defaultValue}
        disabled={disabled}
        style={style}
        maxLength={maxlength}
        ref={selectRef}
        hidden={hidden}
        value={value}
      />
      {
        !isValid ? 
        <span style={{color: COLOR_BUTTON_MAB}}>{labelIsValid}</span>
        : ''
      }
    </div>
  )
}
export default InputFormControl