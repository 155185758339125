
import React from "react";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Tooltip } from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AirlineSeatReclineNormalIcon from '@mui/icons-material/AirlineSeatReclineNormal';

export default function VehicleAssignmentDetail({
	className = '',
	handleOnDelete,
	item = {},
  type = '',
  typeDocument = '',
  isNew = false
}) {
  const {
    is_vehicle_assignment
  } = item
  if(type === 'CUSTOMER') {
    return(
      <div className={className}>
        <div className="row m-auto" style={{border: '1px solid #ccc',  borderRadius: '8px'}}>
          <div className="col-md-2 text-center mt-2">
            <PersonIcon />
          </div>
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-12">
                {
                  typeDocument === 'dni' ?
                  <div>
                    {
                      is_vehicle_assignment === 1 || is_vehicle_assignment === 0 ?
                      <span>{item.data_user}</span>
                      : is_vehicle_assignment === undefined ? <span>{item.nombre_completo}</span>: ''
                    }
                  </div>:
                  <div>
                    {
                      is_vehicle_assignment === 1 || is_vehicle_assignment === 0 ?
                      <span>{item.data_user}</span>
                      : is_vehicle_assignment === undefined ? <span>{item.nombre_o_razon_social}</span>: ''
                    }
                  </div>
                }
              </div>
              <div className="col-md-12">
                <span>
                  {
                    typeDocument === 'ruc' && isNew ?
                    item.ruc
                    : item.numero
                  }
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-2 mt-2">
            <Tooltip title='Quitar'>
              <div onClick={handleOnDelete}>
                <DeleteOutlineOutlinedIcon style={{cursor: 'pointer'}} />
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
    )
  } 
  else if(type === 'VEHI') {
    return(
      <div className={className}>
        <div className="row m-auto" style={{border: '1px solid #ccc',  borderRadius: '8px'}}>
          <div className="col-md-2 text-center mt-2">
            <LocalShippingIcon />
           
          </div>
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-12">
                {
                  typeDocument === 'dni' ?
                  <div>
                    {
                      is_vehicle_assignment === 1 || is_vehicle_assignment === 0 ?
                      <span>{item.data_user}</span>
                      : is_vehicle_assignment === undefined ? <span>{item.nombre_completo}</span>: ''
                    }
                  </div>:
                  <div>
                    {
                      is_vehicle_assignment === 1 || is_vehicle_assignment === 0 ?
                      <span>{item.data_user}</span>
                      : is_vehicle_assignment === undefined ? <span>{item.nombre_o_razon_social}</span>: ''
                    }
                  </div>
                }
              </div>
              <div className="col-md-12">
                <span>
                  {
                    item.numero.length === 4 ?
                    item.ruc: item.numero
                  }
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-2 mt-2">
            <Tooltip title='Quitar'>
              <div onClick={handleOnDelete}>
                <DeleteOutlineOutlinedIcon style={{cursor: 'pointer'}} />
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
    )
  }
  else {
    return(
      <div className={className}>
        <div className="row m-auto" style={{border: '1px solid #ccc',  borderRadius: '8px'}}>
          <div className="col-md-2 text-center mt-2">
            <AirlineSeatReclineNormalIcon />
          </div>
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-12">
                {
                  is_vehicle_assignment === 1 || is_vehicle_assignment === 0 ?
                  <span>{item.data_user}</span>
                  : is_vehicle_assignment === undefined ? <span>{item.nombre_completo}</span>: ''
                }
              </div>
              <div className="col-md-12">
                <span>{item?.numero}</span>
              </div>
            </div>
          </div>
          <div className="col-md-2 mt-2">
            <Tooltip title='Quitar'>
              <div onClick={handleOnDelete}>
                <DeleteOutlineOutlinedIcon style={{cursor: 'pointer'}} />
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
    )

  }
}