import React, { useEffect, useState } from 'react';
import ReactApexChart from "react-apexcharts"
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row
} from "reactstrap";
import { getTitle, getValueNamePerMonth } from '../../helpers/general_functions';
import { useGetReportEntryNoteEmit, useGetReportExitNoteEmit } from '../../hooks';
import { COLOR_BLUE_MAB, COLOR_BUTTON_MAB } from '../../helpers/color';
import { Greeting } from '../../components/organism';

const options = {
  chart: {
    height: 250,
    type: 'area',
    stacked: true,
    toolbar: {
      show: false
    }

  },
  dataLabels: {
    enabled: false,
    enabledOnSeries: [1]
  },
  stroke: {
    curve: "smooth",
  },

  colors: ["#5156be", "#2ab57d"],
  xaxis: {
    type: "day",
    tickAmount: 8,
    title: {
      text: 'Días'
    }
  },
  yaxis: {
    tickAmount: 4,
    floating: false,
    yaxis: {
      title: {
        text: 'Soles'
      }
    },
    labels: {
      style: {
        colors: '#8e8da4',
      },
      offsetY: -7,
      offsetX: 0,
    },
    axisBorder: {
      show: true,
    },
    axisTicks: {
      show: true
    }
  },
  grid: {
    borderColor: "#f1f1f1",
  },
  tooltip: {
    shared: true,
  },
};

const Dashboard = () => {
  getTitle('Menu principal');

  const { exitNotesEmits } = useGetReportExitNoteEmit();
  const { entryNotesEmits } = useGetReportEntryNoteEmit();
  const exitNotes = exitNotesEmits.length > 0 && typeof exitNotesEmits[0] === 'object'
    ? Object.values(exitNotesEmits[0])
    : [];
  const entryNotes = entryNotesEmits.length > 0 && typeof entryNotesEmits[0] === 'object'
    ? Object.values(entryNotesEmits[0])
    : [];
  // const total = exitNotes.reduce((acumulador, valorActual) => acumulador + valorActual, 0);

  const series = [
    {
      name: "Notas de Salida",
      data: exitNotes,
    },
    {
      name: "Notas de Ingreso",
      data: entryNotes,
    },
  ]
  const [objUser, setObjUser] = useState({});

  useEffect(() => {
    const authUser = localStorage.getItem("authUser");
    if (authUser) {
      const obj = JSON.parse(authUser);
      setObjUser(obj[0]);
    }
  }, []);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className='row'>
            <div className='col-md-12'>
              <Greeting  
                title={`${objUser.username}`}
              />
            </div>
          </div>
          <div className='row'>
            <span style={{color: COLOR_BLUE_MAB}}>NOTAS DE SALIDA E INGRESO - ({getValueNamePerMonth(new Date().getMonth() + 1)})</span>
            <div className='col-md-6'>
              <ReactApexChart
                options={options}
                series={series}
                type="area"
                className="apex-charts"
              />
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default Dashboard;