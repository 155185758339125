import React, { useState } from "react";
import { actualizarPropiedad, formatDecimal, getNameAndLastName, getNowDate, getTitle } from "../../../helpers/general_functions";
import FormCreate from "./FormCreate";
import { Button, Tooltip } from "@mui/material";
import { Container } from "reactstrap";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import { useGetAllCustomer, useGetAllDrivers } from "../../../hooks/user-hook";
import { useGetAllPresentation, useGetAllVehicleAvailable } from "../../../hooks";
import { ServiceGetBasedDocumentNumber, ServicePostCreateDriver } from "../../../service/user-service";
import { ServicePostInsertExitNote } from "../../../service/ServiceMovements";
import { useGetAllBrands, useGetAllConfigs } from "../../../hooks/common-hook";
import { getReniec } from "../../../helpers/api_helper";
import { Modal, SendEmailVouchers } from "../../organism";
import { AREA_CUSTOMER_ID, AREA_DRIVER_ID } from "../../../config/config";
import { ServiceGetAllVehicleLicense, ServiceGetAllVehicleLicenseCart, ServiceGetAllVehiclesAvailable, ServicePostCreateVehicle, ServicePostVehicleCartNew } from "../../../service/vehicle-service";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Uri_GetDownloadPdf } from "../../../api/movements/apiMovements";
import Breadcrumbs from "../../../components/Common/Breadcrumb";

export default function Manager() {
  getTitle('Registro de Nota de Salida');
  let navigate = useNavigate();
  const { drivers } = useGetAllDrivers();
  const { config } = useGetAllConfigs();
  const { customers } = useGetAllCustomer();
  const [lstLine, setLstLine] = useState([]);
  const { presentations } = useGetAllPresentation();
  const [lastId, setLastId] = useState(1);
  const [fullNameCustomer, setFullNameCustomer] = useState('');
  const [customerId, setCustomerId] = useState(0);
  const [driverId, setDriverId] = useState(0);
  const [fullNameDriver, setFullNameDriver] = useState('');
  const { vehiclesAvailable, setVehicleAvailable } = useGetAllVehicleAvailable();
  const { brands } = useGetAllBrands();

  const [isNewDriver, setIsNewDriver] = useState(false);
  const [isNewCustomer, setIsNewCustomer] = useState(false);
  const [loadButtonCustomer, setLoadButtonCustomer] = useState(false);
  const [loadButtonDriver, setLoadButtonDriver] = useState(false);

  const [expandViewDataCustomer, setExpandViewDataCustomer] = useState(false);
  const [expandViewDataDriver, setExpandViewDataDriver] = useState(false);

  const [objReniecCustomer, setObjReniecCustomer] = useState({});
  const [objReniecDriver, setObjReniecDriver] = useState({});
  const [isDniRucCustomer, setIsDniRucCustomer] = useState('');
  const [isDniRucDriver, setIsDniRucDriver] = useState('');

  const [showDetailVehicleAssignment, setShowDetailVehicleAssignment] = useState(false);
  const [documentNumberCustomer, setDocumentNumberCustomer] = useState('');
  const [stateButtonSave, setStateButtonSave] = useState(false);

  const [showDropdown, setShowDropdown] = useState(false);
  const [foundPersonDriver, setFoundPersonDriver] = useState(false);
  const [showDropdownDriver, setShowDropdownDriver] = useState(false);


  const [vehicleId, setVehicleId] = useState(0);
  const [objVehicleSelect, setObjVehicleSelect] = useState({});

  const [foundPerson, setFoundPerson] = useState(false);

  const [openModalRegisterVehicleDriver, setOpenModalRegisterVehicleDriver] = useState(false);

  const [formEmail, setFormEmail] = useState({
    emailDriverRuc: '',
    emailDriverDni: '',
    emailCustomerDni: '',
    emailCustomerRuc: '',
    licenseDriver: ''
  });
  const [formValuesInsertVehicle, setFormValuesInsertVehicle] = useState({
    license_plate: '',
    license_plate_carreta: '',
    year_of_production: 0,
    color: '',
    quantity_tires: 0,
    selectValueBrand: null,
  });

  const [formValues, setFormValues] = useState({
    documentDate: getNowDate(),
    producttype: '',
    plant: '',
    onBehalfOfMr: '',
    reason: '',
    observations: ''
  });

  //#region Placas de vehículo
  const [vehicleLicenseList, setVehicleLicenseList] = useState([])
  const [vehicleLicenseCartList, setVehicleLicenseCartList] = useState([])
  const [showDropDownOfVehicleLicense, setShowDropDownOfVehicleLicense] = useState(false)
  const [showDropDownOfVehicleLicenseCart, setShowDropDownOfVehicleLicenseCart] = useState(false)
  const [vehicleLicense, setVehicleLicense] = useState({})
  const [vehicleLicenseCart, setVehicleLicenseCart] = useState({})
  const [vehicleLicenseFound, setVehicleLicenseFound] = useState(false);
  const [vehicleLicenseCartFound, setVehicleLicenseCartFound] = useState(false);
  const [isNewVehicleCart, setIsNewVehicleCart] = useState(false)
  const [isNewVehicle, setIsNewVehicle] = useState(false)
  const [licenseCart, setLicenseCart] = useState('');
  const [license, setLicense] = useState('');
  const [isNewLicense, setIsNewLicense] = useState(false)
  const [isTheAnEmail, setIsTheAnEmail] = useState(false)
  const [licenseCartId, setLicenseCartId] = useState(0)
  const [inputValue, setInputValue] = useState('')
  const [brandInsert, setBrandInsert] = useState('')
  const [licenseDriver, setLicenseDriver] = useState({})

  const [formValuesLicense, setFormValuesLicense] = useState({
    selectValueBrand: null,
  });
  // Nuevo Driver
  const [formValuesDriver, setFormValuesDriver] = useState({
    lastNames: '',
    names: '',
    documentNumber: '',
    licenseCode: ''
  })
  const [isNewDriverModal, setIsNewDrivalModal] = useState(false);
  //#endregion Fin de estados locales - hooks
  //#region Logica

  const handleOnCancelExitNote = (_) => {
    Swal.fire({
      title: `Seguro de cancelar la nota de salida?`,
      text: "Si, estoy seguro!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Si`,
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        navigate('/tesoreria/nota-salida/');
      }
    });
  }
  const checkTablaData = () => {
    let result = ''
    lstLine.map(l => {
      if(parseFloat(l.cantidad) === 0 || !l.cantidad) {
        result = `Debe de ingresar una cantidad.`
      }
      else if(parseFloat(l.kgBrutos) < 0 || !l.kgBrutos) {
        result = `Debe de ingresar una cantidad bruta.`
      }
      else if(parseFloat(l.kgNetos) < 0 || !l.kgNetos) {
        result = `Debe de ingresar una cantidad neta.`
      }
      else if(parseFloat(l.unidadId) === 0 || !l.unidadId) {
        result = `Debe de ingresar una unidad de peso.`
      }
    })
    return result
  };
  
  const handleOnSaveExitNote = async (_) => {
    //Verificamos si existe un correo a enviar tanto para un cliente o un chofer
    if (!customerId && !isNewCustomer) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `Debe de ingresar el Cliente.`,
      });
      return;
    }
    if (!driverId && !isNewDriver) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `Debe de ingresar el Chofer.`,
      });
      return;
    }
    if (lstLine.length === 0) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `Debe de ingresar almenos un item del producto de la Nota de Salida.`,
      });
      return;
    }
    if(checkTablaData()) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `${checkTablaData()}`,
      });
      return;
    }
    // if(!objReniecDriver.license_code && !formEmail.licenseDriver) {
    //   withReactContent(Swal).fire({
    //     title: <small>Ingresa licencia de conducir</small>,
    //     input: 'text',
    //     inputValue,
    //     preConfirm: () => {
    //       setFormEmail({
    //         licenseDriver: Swal.getInput()?.value || ''
    //       })
    //     },
    //   })
    //   return;
    // }
    if(!vehicleLicenseFound) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `Debe de ingresar o seleccionar un vehículo`,
      });
      return;
    }
    // if(!vehicleLicenseCartFound) {
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'Error',
    //     text: `Debe de ingresar o seleccionar una carreta del vehículo`,
    //   });
    //   return;
    // }
    let data = {
      customerId,
      driverId,
      formValues,
      isNewCustomer: isNewCustomer ? 1: 0,
      isNewDriver: isNewDriver ? 1: 0,
      objReniecCustomer,
      objReniecDriver,
      isDniRucCustomer,
      isDniRucDriver,
      lstLine: lstLine,
      formEmail,
      vehicleId,
      isNewLicense: !isNewLicense? 0: 1,
      licenseCartId,
      licenseDriver
    };
    Swal.fire({
      title: `Seguro de guardar la nota de salida?`,
      text: "Si, estoy seguro!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Si guardar`,
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        data = JSON.stringify(data);
        setStateButtonSave(!stateButtonSave)
        const insert = await ServicePostInsertExitNote(data);
        if (insert.status === 200) {
          Swal.fire({
            title: `Registro exitoso`,
            text: `Se ha registrado la nota de salida.`,
            icon: "success"
          });
          handleOnClearControls();
          setStateButtonSave(false);
          const url = insert.data.data.pdf;
          const correlativo = `${insert.data.data.serie} - ${insert.data.data.number}`;
          handleDownloadPdf(url, correlativo)
          navigate('/tesoreria/nota-salida/');
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `${insert.error}`,
          });
          setStateButtonSave(false);
        }
      }
    });
  }
  function handleDownloadPdf(url, correlativo) {
    fetch(Uri_GetDownloadPdf(url, correlativo))
    .then(response => {
      if (!response.ok) {
        throw new Error('Ocurrió un error al descargar el PDF');
      }
      return response.blob();
    })
    .then(blob => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${correlativo}.pdf`);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
    })
    .catch(error => {
      console.error('Error:', error);
    });
  }
  const handleOnClickNewLine = () => {
    const newId = lastId + 1; // Generar un nuevo ID
    setLastId(newId); // Actualizar la variable del último ID generado

    setLstLine(prevLines => {
      const newLinea = {
        id: newId,
        cantidad: formatDecimal(0.00),
        unidadId: parseInt(0), // presentación
        natureProduct: '',
        weightUnidId: 1,
        kgBrutos: formatDecimal(0.00),
        kgNetos: formatDecimal(0.00)
      };
      return [...prevLines, newLinea];
    });
  };
  const handleDeleteItem = (_, itemToDelete) => {
    const updatedList = lstLine.filter(item => item.id !== itemToDelete.id);
    setLstLine([...updatedList]); // Utiliza una copia superficial de updatedList
  }

  const handleOnChangeAddQuantity = (e, itemId) => {
    const quantity = parseFloat(e.target.value);
    const updatedLines = lstLine.map(line => {
      if (parseInt(line.id) === parseInt(itemId)) {
        return { ...line, cantidad: quantity };
      } else {
        return line;
      }
    });
    setLstLine(updatedLines);
  }
  const handleOnChangeNaturalezaProduc = (e, itemId) => {
    const natureProductValue = e.target.value;
    const updateLines = lstLine.map(line => {
      if (parseInt(line.id) === itemId) {
        return { ...line, natureProduct: natureProductValue }
      } else {
        return line;
      }
    });
    setLstLine(updateLines);
  }
  const handleOnClearControls = () => {
    setIsNewCustomer(false);
    setIsNewDriver(false);
    setExpandViewDataCustomer(false);
    setExpandViewDataDriver(false);
  }
  const onHandleChangeInsert = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  }
  const handleEnterPressCustomer = async (e) => {
    const documentNumber = e.target.value.replace(/\s/g, '');
    setFullNameCustomer('');
    setDocumentNumberCustomer(documentNumber);
    await handleSearchCustomer(documentNumber);
  };
  const handleSearchCustomer = async (documentNumber) => {
    setLoadButtonCustomer(true);
    let isDniRuc = ''
    documentNumber = documentNumber.trim();
    if(documentNumber.length === 8) {
      isDniRuc = 'dni'
    } else if(documentNumber.length === 11) {
      isDniRuc = 'ruc'
    } else {
      isDniRuc = ''
      setLoadButtonCustomer(false);
      setShowDropdown(false)
      return
    }
    setIsDniRucCustomer(isDniRuc);
    if (isDniRuc === '') {
      return;
    }
    try {
      const objDataUser = await ServiceGetBasedDocumentNumber(documentNumber, AREA_CUSTOMER_ID);
      if (objDataUser.status === 200) {
        const { data_user: fullName, user_id: id } = objDataUser.data;
        setCustomerId(parseInt(id));
        setFullNameCustomer(fullName);
        setObjReniecCustomer(objDataUser.data);
        setExpandViewDataCustomer(true);
        setIsNewCustomer(false)
        setShowDropdown(true);
      } else if (objDataUser.status === 400 && parseInt(config.activar_uso_api_reniec) === 1) {
        setShowDropdown(false)
        let resultOld = null;
        let result = null;
        if(isDniRuc === 'ruc') {
          resultOld = await getReniec(documentNumber, config.token_api_reniec, isDniRuc, config.url_web_api_reniec);
          result = actualizarPropiedad(resultOld, "estado_del_contribuyente", "estado")
        } else {
          result = await getReniec(documentNumber, config.token_api_reniec, isDniRuc, config.url_web_api_reniec);
          let objNamesLastNames = getNameAndLastName(result.nombre);
          result = {
            success: result.success,
            nombre_completo: `${objNamesLastNames.nombres}, ${objNamesLastNames.apellidos}`,
            nombres: objNamesLastNames.nombres,
            apellido_paterno: objNamesLastNames.apellidoPaterno,
            apellido_materno: objNamesLastNames.apellidoMaterno,
            codigo_verificacion: 0,
            numero: result.dni
          }
        }
        if (result.success) {
          setShowDropdown(true);
          setExpandViewDataCustomer(true);
          setIsNewCustomer(true);
          setObjReniecCustomer(result);
        } else {
          setShowDropdown(false)
        }
      } 
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoadButtonCustomer(false);
    }
  };
  const handleEnterPressDriver = async (e) => {
    const documentNumber = e.target.value;
    const trimmedDocumentNumber = documentNumber.replace(/\s/g, '');
    setFullNameDriver('');
    setOpenModalRegisterVehicleDriver(false);
    setLoadButtonDriver(true);
    let isDniRuc = ''
    if(trimmedDocumentNumber.length === 8) {
      isDniRuc = 'dni'
    } else {
      setShowDropdownDriver(false)
      setShowDetailVehicleAssignment(false)
      setObjVehicleSelect({});
      setObjReniecDriver({});
      return
    }
    setIsDniRucDriver(isDniRuc);
    if (isDniRuc === '') {
      return;
    }
    
    try {
      const objDataUser = await ServiceGetBasedDocumentNumber(documentNumber, AREA_DRIVER_ID);
      if (objDataUser.status === 200) {
        const fullName = objDataUser.data.data_user;
        const id = parseInt(objDataUser.data.user_id);
        setDriverId(id);
        setFullNameDriver(fullName);
        setObjReniecDriver(objDataUser.data);
        setExpandViewDataDriver(true);
        setShowDropdownDriver(true);
        //Verificamos si tiene un vehiculo asignado
        if(objDataUser.data.is_vehicle_assignment === 1) {
          const dataVehicleLicense = {
            vehicle_license_plate: objDataUser.data.vehicle_license_plate
          }
          const dataVehicleLicenseCart = {
            license_cart: objDataUser.data.license_cart
          }
          setVehicleLicense(dataVehicleLicense)
          setVehicleLicenseFound(true);
          if(objDataUser.data.license_cart !== null) {
            setVehicleLicenseCart(dataVehicleLicenseCart)
            setVehicleLicenseCartFound(true);
          }
        }
      }
      else if (objDataUser.status === 400 && parseInt(config.activar_uso_api_reniec) === 1) {
        let result = null;
        result = await getReniec(documentNumber, config.token_api_reniec, "dni", config.url_web_api_reniec);
        if (result.success) {
          let objNamesLastNames = getNameAndLastName(result.nombre);
          result = {
            success: result.success,
            nombre_completo: `${objNamesLastNames.nombres}, ${objNamesLastNames.apellidos}`,
            numero: result.dni,
            nombres: objNamesLastNames.nombres,
            apellido_paterno: objNamesLastNames.apellidoPaterno,
            apellido_materno: objNamesLastNames.apellidoMaterno,
          }
          setIsNewDriver(true);
          setObjReniecDriver(result)
          setExpandViewDataDriver(true);
          setShowDropdownDriver(true);
          
        } else {
          setShowDropdownDriver(false);
        }
      } 
      setLoadButtonDriver(false);
    } catch (error) {
      throw error;
    }
  }
  const handleOnChangeWeightUnit = (e, id) => {
    const weightUnitValue = parseInt(e?.value)
    const updateLines = lstLine.map(line => {
      if (parseInt(line.id) === parseInt(id)) {
        return {
          ...line,
          weightUnidId: weightUnitValue
        }
      } else {
        return line;
      }
    });
    setLstLine(updateLines);
  }
  const handleOnChangeKgBrutos = (e, id) => {
    const kgBrutosValue = parseFloat(e.target.value);
    const updateLines = lstLine.map(line => {
      return parseInt(line.id) === parseInt(id) ? { ...line, kgBrutos: kgBrutosValue } : line
    });
    setLstLine(updateLines);
  }
  const handleOnChangeKgNetos = (e, id) => {
    const kgNetosValue = parseFloat(e.target.value);
    const updateLines = lstLine.map(line => {
      return parseInt(line.id) === parseInt(id) ? { ...line, kgNetos: kgNetosValue } : line
    });
    setLstLine(updateLines);
  }
  const handleOnChangePresentation = (e, id) => {
    const presentationIdValue = parseInt(e?.value);
    const updateLines = lstLine.map(line => {
      return parseInt(line.id) === parseInt(id) ? { ...line, unidadId: presentationIdValue } : line
    });
    setLstLine(updateLines);
  }
  const handleOnViewData = (e, type) => {
    if (type === 'CUSTOMER') {
      setExpandViewDataCustomer(!expandViewDataCustomer)
    } else {
      setExpandViewDataDriver(!expandViewDataDriver)
    }
  }
  const handleOnClickPersonAddCustomer = async (e) => {
  }
  const handleOnClickPersonAddDriver = async (_) => {

  }
  const handleOnChangeEmail = (e) => {
    const { name, value } = e.target;
    setFormEmail({
      ...formEmail,
      [name]: value,
    });
  }
  // const handleCloseAssignVehicleDriver = (e) => {
  //   setOpenModalRegisterVehicleDriver(false);
  // }
  const handleOnClickAddVehicle = (e) => {
    setOpenModalRegisterVehicleDriver(true);
  }
  // const onHandleChangeInsertVehicle = (e) => {
  //   const { name, value } = e.target;
  //   setFormValuesInsertVehicle({
  //     ...formValuesInsertVehicle,
  //     [name]: value,
  //   });
  // }
  // const onHandleChangeSelectInsert = (selectedOption, name) => {
  //   setFormValuesInsertVehicle({
  //     ...formValuesInsertVehicle,
  //     [name]: selectedOption,
  //   });
  // }
  // const handleOnSaveVehicleNew = (e) => {
  //   if(!formValuesInsertVehicle.license_plate) {
  //     Swal.fire({
  //       icon: "error",
  //       title: "Advertencia",
  //       text: `Debe de ingresar la placa del vehículo.`,
  //     });
  //     return;
  //   }
  //   if(formValuesInsertVehicle.selectValueBrand === undefined || formValuesInsertVehicle.selectValueBrand === null) {
  //     Swal.fire({
  //       icon: "error",
  //       title: "Advertencia",
  //       text: `Debe de seleccionar una marca del vehículo.`,
  //     });
  //     return;
  //   }
  //   let dataInsert = {
  //     license_plate: formValuesInsertVehicle.license_plate,
  //     license_plate_carreta: formValuesInsertVehicle.license_plate_carreta,
  //     year_of_production: parseInt(formValuesInsertVehicle.year_of_production),
  //     color: formValuesInsertVehicle.color,
  //     quantity_tires: parseInt(formValuesInsertVehicle.quantity_tires),
  //     brand: {
  //       brand_id: parseInt(formValuesInsertVehicle.selectValueBrand.id)
  //     },
  //     vehicleId
  //   };
  //   Swal.fire({
  //     title: "Seguro de guardar el vehículo?",
  //     text: "Si, estoy seguro!",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Si, guardar",
  //     cancelButtonText: 'Cancelar'
  //   }).then(async(result) => {
  //     if (result.isConfirmed) {
  //       let insert = await ServicePostCreateVehicle(dataInsert);
  //       if(insert.status === 200) {
  //         Swal.fire({
  //           title: `${insert.data.message}`,
  //           text: `Se ha registrado el vehículo ${dataInsert.license_plate}.`,
  //           icon: "success"
  //         });
  //         await handleGetAllVehicles();
  //         handleClearControlsInsertVehicle();
  //       } else {
  //         Swal.fire({
  //           icon: "error",
  //           title: "Error",
  //           text: `${insert.error}`,
  //         });
  //         setStateButtonSave(false);
  //       }
  //     }
  //   });
  // }
  const handleGetAllVehicles = async() => {
    let data = await ServiceGetAllVehiclesAvailable();
    setVehicleAvailable(data.data);
  }
  const handleClearControlsInsertVehicle = (e) => {
    setFormValuesInsertVehicle({});
  }
  // const handleOnAssignmentVehicle = async(_, item, type = 'new') => {
  //   Swal.fire({
  //     title: "Seguro de asignar el vehículo?",
  //     text: "Si, estoy seguro!",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Si, guardar",
  //     cancelButtonText: 'Cancelar'
  //   }).then(async(result) => {
  //     if (result.isConfirmed) {
  //       const {
  //         vehicle_id,
  //       } = item;
  //       setVehicleId(vehicle_id);
  //       let data = await ServiceGetAllVehiclesAvailable();
  //       if(data.data) {
  //         data.data.map(vehi => {
  //           if(parseInt(vehi.vehicle_id) === parseInt(vehicle_id)) {
  //             setShowDetailVehicleAssignment(true);
  //             setObjVehicleSelect(vehi)
  //             vehi.state = type === 'new' ? 'Seleccionado': 'Vehículo seleccionado'
  //             vehi.state_value = 2
  //             vehi.vehicle_id = vehicle_id
  //           }
  //         })
  //       }
  //       setVehicleAvailable(data.data);
  //       setOpenModalRegisterVehicleDriver(false);
  //     }
  //   });
  // }
  const handleOnDeleteAssignment = async(e) => {
    setShowDetailVehicleAssignment(false);
    setObjVehicleSelect({});
    await handleGetAllVehicles();
  }
  const handleSelectItem = (e, item) => {
    setShowDropdown(false);
    setFoundPerson(true);
  }
  const handleSelectItemDriver = (e, item) => {
    setShowDropdownDriver(false);
    setFoundPersonDriver(true);
  }
  const handleOnDeleteCustomer = (e) => {
    setFoundPerson(false);
    setObjReniecCustomer({});
  setIsNewCustomer(false)
  }
  const handleOnDeleteDriver = (e) => {
    setFoundPersonDriver(false);
    setObjReniecDriver({});
    setIsNewDriver(false)
  }
  //#region Metodos para buscar o crear un nuevo vehículo
  async function handleOnChangeSearchVehicleByLicense(e) {
    e.preventDefault()
    const licensePlate = (e.target.value).trim()
    setShowDropDownOfVehicleLicense(true)
    // if(licensePlate.length < 6) {
    //   return
    // }
    const data = {
      license: licensePlate
    }
    const result = await ServiceGetAllVehicleLicense(data)
    if(result.status === 200) {
      setVehicleLicenseList(result.data)
      setShowDropDownOfVehicleLicense(true) 
    }
  }
  async function handleOnChangeSearchVehicleCartByLicense(e) {
    e.preventDefault()
    const licensePlate = (e.target.value).trim()
    // setShowDropDownOfVehicleLicenseCart(true)
    // if(licensePlate.length < 6) {
    //   return
    // }
    const data = {
      license: licensePlate
    }
    const result = await ServiceGetAllVehicleLicenseCart(data)
    if(result.status === 200) {
      setVehicleLicenseCartList(result.data)
      setShowDropDownOfVehicleLicenseCart(true)
    }
  }
  function handleOnSelectItemVehicleLicense(_, item) {
    setVehicleLicense(item)
    setVehicleId(item.id)
    setVehicleLicenseFound(!vehicleLicenseFound)
    if(item.license_cart !== null) {
      setVehicleLicenseCart(item)
      setVehicleLicenseCartFound(!vehicleLicenseCartFound)
    }
    setShowDropDownOfVehicleLicense(!!showDropDownOfVehicleLicense)
  }
  function handleOnDeleteVehicleLicense(_, item) {
    setVehicleLicenseFound(!vehicleLicenseFound)
    if(item.is_have_license_cart === 1) {
      handleOnDeleteVehicleLicenseCart()
    }
    setShowDropDownOfVehicleLicense(!showDropDownOfVehicleLicense)
    setVehicleLicense({})
  }
  function handleOnDeleteVehicleLicenseCart(e) {
    setVehicleLicenseCartFound(false)
    setShowDropDownOfVehicleLicenseCart(false)
  }
  function handleOnSelectItemVehicleLicenseCart(e, item) {
    item.license_cart = item.vehicle_license_plate
    setLicenseCartId(parseInt(item.id))
    setVehicleLicenseCart(item)
    setVehicleLicenseCartFound(!vehicleLicenseCartFound)
    setShowDropDownOfVehicleLicenseCart(!!showDropDownOfVehicleLicenseCart)
  }
  function handleOnCloseAutoCompleteVehicleLicense() {
    setShowDropDownOfVehicleLicense(!showDropDownOfVehicleLicense)
  }
  function handleOnCloseAutoCompleteVehicleLicenseCart() {
    setShowDropDownOfVehicleLicenseCart(!showDropDownOfVehicleLicenseCart)
  }
  //#endregion Metodos para buscar o crear un nuevo vehículo
  //#region Customer
  function handleOnCloseAutoCompleteCustomer() {
    setShowDropdown(!showDropdown)
  }
  function handleOnCloseAutoCompleteDriver() {
    setShowDropdownDriver(!showDropdownDriver)
  }
  //#endregion
  //#region Nueva carreta
  function handleOnClickCreateCartNew() {
    setIsNewVehicleCart(!isNewVehicleCart)
  }
  function handleOnChangeLicenseCart(e) {
    setLicenseCart(e.target.value)
  }
  async function handleOnClickSaveCartNew() {
    if(!licenseCart) {
      return
    }
    const objLicenseCart = {
      license_cart: licenseCart,
      vehicleId: vehicleId
    }
    let register = await ServicePostVehicleCartNew(objLicenseCart)
    if(register.status === 200) {
      const objLicenseCartFilter = {
        license: licenseCart,
      }
      setIsNewVehicleCart(!isNewVehicleCart)
      const result = await ServiceGetAllVehicleLicenseCart(objLicenseCartFilter)
      if(result.status === 200) {
        result.data[0].license_cart = result.data[0].vehicle_license_plate
        setLicenseCartId(result.data[0].id)
        setVehicleLicenseCart(result.data[0])
        setVehicleLicenseCartFound(!vehicleLicenseCartFound)
        setShowDropDownOfVehicleLicenseCart(!!showDropDownOfVehicleLicenseCart)
      }
    }
  }
  function handleOnClickCreateLicenseNew() {
    setIsNewVehicle(!isNewVehicle)
  }
  async function handleOnClickSaveLicenseNew() {
    if(!license) {
      return
    }
    const objLicense = {
      license_plate: license,
      license_plate_carreta: '',
      year_of_production: 0,
      color: '',
      quantity_tires: 0,
      brand_insert: brandInsert,
      brand: {
        brand_id: 0 //  parseInt(formValuesLicense.selectValueBrand.id)
      },
    }
    let register = await ServicePostCreateVehicle(objLicense)
    if(register.status === 200) {
      const objLicenseFilter = {
        license: license,
      }
      setIsNewVehicle(!isNewVehicle)
      const result = await ServiceGetAllVehicleLicense(objLicenseFilter)
      if(result.status === 200) {
        setVehicleId(result.data[0].id)
        setIsNewLicense(1)
        setVehicleLicense(result.data[0])
        setVehicleLicenseFound(!vehicleLicenseFound)
        setShowDropDownOfVehicleLicense(!!showDropDownOfVehicleLicense)
      }
    }
  }
  function handleOnChangeLicense(e) {
    setLicense(e.target.value)
  }
  function onHandleChangeSelectBrandInsert(selectedOption, name) {
    setFormValuesLicense({
      ...formValuesLicense,
      [name]: selectedOption,
    });
  }
  function handleOnCloseModalEmailSend() {
    setIsTheAnEmail(false);
  }
  // function handleOnClickCustomer() {
  //   if(showDropdown) {
  //     setShowDropdown(false);
  //     return
  //   }
  //   setShowDropdown(true);
  // }
  // function handleOnClickDriver() {
  //   if(showDropdownDriver) {
  //     setShowDropdownDriver(false)
  //     return
  //   }
  //   setShowDropdownDriver(true)
  // }
  function handleOnClickVehicle() {
    if(showDropDownOfVehicleLicense) {
      setShowDropDownOfVehicleLicense(false)
      return
    }
    setShowDropDownOfVehicleLicense(true)
  }
  function handleOnClickLicenseCart() {
    if(showDropDownOfVehicleLicenseCart) {
      setShowDropDownOfVehicleLicenseCart(false)
      return
    }
    setShowDropDownOfVehicleLicenseCart(true)
  }
  // Nuevo Driver
  function handleOnChangeDriver(e) {
    const { name, value } = e.target;
    setFormValuesDriver({
      ...formValuesDriver,
      [name]: value,
    });
  }
  function handleOnClickCreateDriverNew() {
    setIsNewDrivalModal(true)
  }
  async function handleOnClickSaveDriverNew() {
    if(!formValuesDriver.names) {
      return
    }
    if(!formValuesDriver.lastNames) {
      return
    }
    if(!formValuesDriver.documentNumber) {
      return
    }
    const objDriver = {
      names: formValuesDriver.names,
      last_names: formValuesDriver.lastNames,
      nro_document: formValuesDriver.documentNumber,
      license_code: formValuesDriver.licenseCode,
      document_type: {
        document_type_id: 1
      },
      email: '',
      birth_date: '1950-01-01',
      date_of_admission: '1950-01-01',
      date_expiration: '1950-01-01',
      vehicle_id: -1
    }
    let insert = await ServicePostCreateDriver(objDriver)
    if(insert.status === 200) {
      setIsNewDrivalModal(false)
      let document_number = formValuesDriver.documentNumber
      const objDataUser = await ServiceGetBasedDocumentNumber(document_number, AREA_DRIVER_ID);
      if (objDataUser.status === 200) {
        const fullName = objDataUser.data.data_user;
        const id = parseInt(objDataUser.data.user_id);
        setDriverId(id);
        setFullNameDriver(fullName);
        setObjReniecDriver(objDataUser.data);
        setFoundPersonDriver(true);
      }
    }

    // let register = await ServicePostCreateVehicle(objLicense)
    // if(register.status === 200) {
    //   const objLicenseFilter = {
    //     license: license,
    //   }
    //   setIsNewVehicle(!isNewVehicle)
    //   const result = await ServiceGetAllVehicleLicense(objLicenseFilter)
    //   if(result.status === 200) {
    //     setVehicleId(result.data[0].id)
    //     setIsNewLicense(1)
    //     setVehicleLicense(result.data[0])
    //     setVehicleLicenseFound(!vehicleLicenseFound)
    //     setShowDropDownOfVehicleLicense(!!showDropDownOfVehicleLicense)
    //   }
    // }
  }
  function onHandleChangeDriverLicense(e) {
    setLicenseDriver(e.target.value)
  }
  function onHandleChangeBrand(e) {
    setBrandInsert(e.target.value)
  }
  //#endregion Fin de creacion de una nueva carreta
  return (
    <div className="page-content">
      <Container fluid>
        <div className="row">
          <div className="col-md-1">
            <Button
              id="basic-button"
              aria-haspopup="true"
              onClick={handleOnCancelExitNote}
              style={{ fontFamily: 'Arial', fontSize: '16px'}}
            >
              <Tooltip title='Volver al listado general de Notas de Salida'>
                <KeyboardBackspaceIcon />
              </Tooltip>
            </Button>
          </div>
          <div className="col-md-11 mt-2">
            <Breadcrumbs title="Tesorería" breadcrumbItem="Registro de Nota de Salida" />
          </div>
        </div>
        <FormCreate
          handleOnCancelExitNote={handleOnCancelExitNote}
          drivers={drivers}
          customers={customers}
          handleOnClickNewLine={handleOnClickNewLine}
          lstLine={lstLine}
          presentations={presentations}
          handleDeleteItem={handleDeleteItem}
          handleOnChangeAddQuantity={handleOnChangeAddQuantity}
          handleOnSaveExitNote={handleOnSaveExitNote}
          onHandleChangeInsert={onHandleChangeInsert}
          handleOnChangeNaturalezaProduc={handleOnChangeNaturalezaProduc}
          handleEnterPressCustomer={handleEnterPressCustomer}
          handleEnterPressDriver={handleEnterPressDriver}
          fullNameCustomer={fullNameCustomer}
          fullNameDriver={fullNameDriver}
          handleOnChangeWeightUnit={handleOnChangeWeightUnit}
          handleOnChangeKgBrutos={handleOnChangeKgBrutos}
          handleOnChangeKgNetos={handleOnChangeKgNetos}
          handleOnChangePresentation={handleOnChangePresentation}
          loadButtonCustomer={loadButtonCustomer}
          loadButtonDriver={loadButtonDriver}
          handleOnViewData={handleOnViewData}
          flagsViewData={{
            expandViewDataCustomer: expandViewDataCustomer,
            expandViewDataDriver: expandViewDataDriver
          }}
          flagsSelectTypeDocument={{
            isDniRucCustomer: isDniRucCustomer,
            isDniRucDriver: isDniRucDriver
          }}
          objReniecCustomer={objReniecCustomer}
          objReniecDriver={objReniecDriver}
          handleOnClickPersonAddCustomer={handleOnClickPersonAddCustomer}
          handleOnClickPersonAddDriver={handleOnClickPersonAddDriver}
          handleOnChangeEmail={handleOnChangeEmail}
          stateButtonSave={stateButtonSave}
          handleOnClickAddVehicle={handleOnClickAddVehicle}
          objVehicleSelect={objVehicleSelect}
          formEmail={formEmail}
          showDetailVehicleAssignment={showDetailVehicleAssignment}
          handleOnDeleteAssignment={handleOnDeleteAssignment}
          showDropdown={showDropdown}
          showDropdownDriver={showDropdownDriver}
          handleSelectItemDriver={handleSelectItemDriver}
          foundPersonDriver={foundPersonDriver}
          foundPerson={foundPerson}
          handleSelectItem={handleSelectItem}
          handleOnDeleteCustomer={handleOnDeleteCustomer}
          handleOnDeleteDriver={handleOnDeleteDriver}
          // Vehiculo
          handleOnChangeSearchVehicleByLicense={handleOnChangeSearchVehicleByLicense}
          vehicleLicenseList={vehicleLicenseList}
          vehicleLicenseCartList={vehicleLicenseCartList}
          showDropDownOfVehicleLicenseCart={showDropDownOfVehicleLicenseCart}
          showDropDownOfVehicleLicense={showDropDownOfVehicleLicense}
          handleOnSelectItemVehicleLicense={handleOnSelectItemVehicleLicense}
          vehicleLicense={vehicleLicense}
          vehicleLicenseCart={vehicleLicenseCart}
          vehicleLicenseFound={vehicleLicenseFound}
          vehicleLicenseCartFound={vehicleLicenseCartFound}
          handleOnDeleteVehicleLicense={handleOnDeleteVehicleLicense}
          handleOnDeleteVehicleLicenseCart={handleOnDeleteVehicleLicenseCart}
          handleOnChangeSearchVehicleCartByLicense={handleOnChangeSearchVehicleCartByLicense}
          handleOnSelectItemVehicleLicenseCart={handleOnSelectItemVehicleLicenseCart}
          handleOnCloseAutoCompleteVehicleLicense={handleOnCloseAutoCompleteVehicleLicense}
          handleOnCloseAutoCompleteVehicleLicenseCart={handleOnCloseAutoCompleteVehicleLicenseCart}
          // Customer
          handleOnCloseAutoCompleteCustomer={handleOnCloseAutoCompleteCustomer}
          isNewCustomer={isNewCustomer}
          // Chofer
          handleOnCloseAutoCompleteDriver={handleOnCloseAutoCompleteDriver}
          // Carretas
          handleOnClickCreateCartNew={handleOnClickCreateCartNew}
          isNewVehicleCart={isNewVehicleCart}
          handleOnChangeLicenseCart={handleOnChangeLicenseCart}
          handleOnClickSaveCartNew={handleOnClickSaveCartNew}
          // Vehiculo
          isNewVehicle={isNewVehicle}
          handleOnClickCreateLicenseNew={handleOnClickCreateLicenseNew}
          handleOnClickSaveLicenseNew={handleOnClickSaveLicenseNew}
          handleOnChangeLicense={handleOnChangeLicense}
          onHandleChangeSelectBrandInsert={onHandleChangeSelectBrandInsert}
          brands={brands}
          // handleOnClickCustomer={handleOnClickCustomer}
          // handleOnClickDriver={handleOnClickDriver}
          handleOnClickVehicle={handleOnClickVehicle}
          handleOnClickLicenseCart={handleOnClickLicenseCart}
          // Driver
          isNewDriverModal={isNewDriverModal}
          handleOnClickCreateDriverNew={handleOnClickCreateDriverNew}
          handleOnChangeDriver={handleOnChangeDriver}
          handleOnClickSaveDriverNew={handleOnClickSaveDriverNew}
          onHandleChangeDriverLicense={onHandleChangeDriverLicense}
          onHandleChangeBrand={onHandleChangeBrand}
        />
        {
          isTheAnEmail && (
            <Modal
              title={`Enviar comprobante por correo electrónico`}
              size='modal-xs'
              onClose={handleOnCloseModalEmailSend}
            >

              <SendEmailVouchers
                type="NOTA_SALIDA"
                handleOnChangeSend={handleOnChangeEmail}
                item={formEmail}
                showButtonSave={false}
                onClose={handleOnCloseModalEmailSend}
              />
            </Modal>
          )}
      </Container>
    </div>
  )
}
