import React from "react";
import { Link } from "react-router-dom";
import AddCircleIcon from '@mui/icons-material/AddCircle';
export default function AddNewItem({
  title = ''
  , handleOnClick
  , navigateUrl
}) {
  return (
    <div>
      <Link to={navigateUrl} className="btn btn-success" onClick={handleOnClick}>
        <AddCircleIcon /> {title}
      </Link>
    </div>
  )
}