import React from "react";
import { ButtonFormControl, InputFormControl, SelectedFormControl } from "../../molecules";

export default function FormCreate({
  handleOnCloseVehicleEdit
  , onHandleChangeInsert
  , onHandleChangeSelectInsert
  , handleOnSaveVehicleEdit
  , brands = []
  , objEditVehicle = {}
}) {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-4">
          <InputFormControl
            type={'text'}
            label={'Placa Frontal'}
            onHandleChange={onHandleChangeInsert}
            nameInput={'license_plate'}
            defaultValue={objEditVehicle.vehicle_license_plate}
          />
        </div>
        <div className="col-md-4">
          <InputFormControl
            type={'text'}
            label={'Placa Carreta'}
            onHandleChange={onHandleChangeInsert}
            nameInput={'license_plate_carreta'}
            defaultValue={objEditVehicle.vehicle_license_plate_carreta}
          />
        </div>
        <div className="col-md-4">
          <SelectedFormControl
            label={'Marca'}
            placeholder={'Seleccione una marca...'}
            options={brands}
            defaultValue={brands.filter(bra => parseInt(bra.value) === parseInt(objEditVehicle.brand_id))}
            onHandleChange={(selectedOption) => onHandleChangeSelectInsert(selectedOption, 'selectValueBrand')}
          />
        </div>
        {/* <div className="col-md-4">
          <InputFormControl
            type={'number'}
            label={'Año de fabricación'}
            onHandleChange={onHandleChangeInsert}
            nameInput={'year_of_production'}
            defaultValue={objEditVehicle.year_of_production}
          />
        </div>
        <div className="col-md-4">
          <InputFormControl
            type={'text'}
            label={'Color'}
            onHandleChange={onHandleChangeInsert}
            nameInput={'color'}
            defaultValue={objEditVehicle.color}
          />
        </div> */}
      </div>
      <div className="row">
        {/* <div className="col-md-4 mt-2">
          <InputFormControl
            type={'number'}
            label={'Cantidad de llantas'}
            onHandleChange={onHandleChangeInsert}
            nameInput={'quantity_tires'}
            defaultValue={objEditVehicle.quantity_tires}
          />
        </div> */}
        {/* <div className="col-md-4">
          <SelectedFormControl
            label={'Marca'}
            placeholder={'Seleccione una marca...'}
            options={brands}
            defaultValue={brands.filter(bra => parseInt(bra.value) === parseInt(objEditVehicle.brand_id))}
            onHandleChange={(selectedOption) => onHandleChangeSelectInsert(selectedOption, 'selectValueBrand')}
          />
        </div> */}
      </div>
      <div className="row mt-3">
        <div className="col-md-6"></div>
        <div className="col-md-6 d-flex justify-content-end">
          <div className="btn-group">
            <ButtonFormControl
              text={'Cancelar'}
              className={'btn btn-secondary'}
              OnHandleClick={handleOnCloseVehicleEdit}
            />&nbsp;&nbsp;
            <ButtonFormControl
              text={'Guardar'}
              className={'btn btn-success'}
              OnHandleClick={handleOnSaveVehicleEdit}
            />
          </div>
        </div>
      </div >
    </div>
  )
}