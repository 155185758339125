import { useEffect, useState } from "react";
import { ServiceGetAllAreas, ServiceGetAllBrands, ServiceGetAllConfigs } from "../service/common-service";

export function useGetAllBrands() {
  const [brands, setBrands] = useState([]);
  useEffect(() => {
    async function getAllBrands() {
      let data = await ServiceGetAllBrands();
      setBrands(data.data);
    }
    getAllBrands();
  }, []);
  return {brands}
}
export function useGetAllAreas() {
  const [areas, setAreas] = useState([]);
  useEffect(() => {
    async function getAllAreas() {
      let data = await ServiceGetAllAreas();
      setAreas(data.data);
    }
    getAllAreas();
  }, []);
  return {areas}
}
 
export function useGetAllConfigs() {
  const [config, setConfig] = useState({});
  useEffect(() => {
    async function getAllConfigs() {
      let data = await ServiceGetAllConfigs();
      let configsObj = {};
      data.data.forEach(config => {
        configsObj[config.name] = config.value;
      });
      setConfig(configsObj);
    }
    getAllConfigs();
  }, []);
  return {config}
}
 