export const COLOR_BUTTON_MAB = '#cc0033';
export const COLOR_BLUE_MAB = '#0266d0';
export const COLOR_GRIS = '#4f5d73';
export const COLOR_YELLOW = '#ffd600';
export const COLOR_GREEN = '#009900';

export const COLOR_1 = '#3b2900';
export const COLOR_2 = '#9266d0';
export const COLOR_3 = '#4f5d73';
export const COLOR_4 = '#f1a100';
export const COLOR_5 = '#009900';

export const COLOR_6 = '#cD0030';
export const COLOR_7 = '#0156d0';
export const COLOR_8 = '#4A5d13';
export const COLOR_9 = '#fAd100';
export const COLOR_10 = '#A19900';