
import React from "react";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";

export default function ExportImportExcel({
  handleOnClickExport
  , handleOnClickImport
  , showImport = true
}) {
  return (
    <UncontrolledDropdown>
      <DropdownToggle className="btn btn-link text-muted py-1 font-size-16 shadow-none" tag="a" >
        <i className="bx bx-dots-horizontal-rounded"></i>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-end">
        <li onClick={handleOnClickExport}><DropdownItem to="#">Exportar a Excel</DropdownItem></li>
        {
          showImport ?
          <li onClick={handleOnClickImport}><DropdownItem to="#">Importar Excel</DropdownItem></li>: ''
        }
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}