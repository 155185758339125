import { useEffect, useState } from "react";
import { ServiceGetAllDocumentType } from "../service/documentype-service";

export function useGetAllDocumentType() {
  const [documentsType, setDocumentsType] = useState([]);
  useEffect(() => {
    async function getAllDocumentType() {
      let data = await ServiceGetAllDocumentType();
      setDocumentsType(data.data);
    }
    getAllDocumentType();
  }, []);
  return {documentsType}
}
export function useGetAllDocumentType2() {
  const [documentsType, setDocumentsType] = useState([]);
  useEffect(() => {
    async function getAllDocumentType() {
      let data = await ServiceGetAllDocumentType();
      setDocumentsType(data.data);
    }
    getAllDocumentType();
  }, []);
  return {documentsType}
}

export default { useGetAllDocumentType, useGetAllDocumentType2 }