import { useEffect, useState } from "react";
import { GetAllEmployeed, ServiceGetAllCustomer, ServiceGetAllDrivers } from "../service/user-service";

export function useGetAllEmployeed() {
  const [employeed, setEmployeed] = useState([]);
  useEffect(() => {
    async function getAllEmployeed() {
      let data = await GetAllEmployeed();
      setEmployeed(data.data);
    }
    getAllEmployeed();
  }, []);
  return {employeed, setEmployeed}
}
export function useGetAllDrivers() {
  const [drivers, setDrivers] = useState([]);
  useEffect(() => {
    async function getAllDrivers() {
      let data = await ServiceGetAllDrivers();
      setDrivers(data.data);
    }
    getAllDrivers();
  }, []);
  return {drivers, setDrivers}
}
export function useGetAllCustomer() {
  const [customers, setCustomers] = useState([]);
  useEffect(() => {
    async function getAllCustomers() {
      let data = await ServiceGetAllCustomer();
      setCustomers(data.data);
    }
    getAllCustomers();
  }, []);
  return {customers, setCustomers}
}