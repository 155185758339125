import React, { Fragment } from "react";
import { ButtonFormControl, InputFormControl } from "../molecules";
import ConditionalRender from "./Conditional-Render";

export default function SendEmailVouchers({
  type = '',
  item = {},
  onClose,
  onSendEmail,
  handleOnChangeSend,
  showButtonSave = true
}) {
  switch (type) {
    case 'NOTA_SALIDA':
      return(
        <ComponenteSendEmailExitNote 
          item={item}
          onClose={onClose}
          handleOnClickSendEmail={onSendEmail}
          handleOnChangeSend={handleOnChangeSend}
          showButtonSave={showButtonSave}
        />
      )
    case 'EMPLOYEED':
      return(
        <ComponenteSendEmailEmployeed 
          item={item}
          onClose={onClose}
          handleOnClickSendEmail={onSendEmail}
          handleOnChangeSend={handleOnChangeSend}
        />
      )
    default:
      break;
  }
}

function ComponenteSendEmailEmployeed({
  item = {},
  onClose,
  handleOnClickSendEmail,
  handleOnChangeSend
}) {
  return(
    <Fragment>
      <div className="row mb-2">
        <div className="col-md-12">
          <InputFormControl
            type={'text'}
            label={'Correo del Empleado'}
            defaultValue={item.emails[0].email}
            onHandleChange={handleOnChangeSend}
            nameInput={'emailEmployeed'}
            disabled
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-6"></div>
        <div className="col-md-6 d-flex justify-content-end">
          <div className="btn-group">
            <ButtonFormControl
              text={'Cancelar'}
              OnHandleClick={onClose}
              className={'btn btn-secondary btn-lg'}
            />&nbsp;&nbsp;
            <ButtonFormControl
              text={'Enviar'}
              OnHandleClick={handleOnClickSendEmail}
              className={'btn btn-success btn-lg'}
            />
          </div>
        </div>
      </div >
    </Fragment>
  )
}

function ComponenteSendEmailExitNote({
  item = {},
  onClose,
  handleOnClickSendEmail,
  handleOnChangeSend,
  showButtonSave = true
}) {
  return(
    <Fragment>
      <div className="row mb-2">
        <div className="col-md-6">
          <InputFormControl
            type={'text'}
            label={'Correo del Cliente'}
            defaultValue={item.emailCustomer}
            onHandleChange={handleOnChangeSend}
            nameInput={'emailCustomerDni'}
          />
        </div>
        <div className="col-md-6">
          <InputFormControl
            type={'text'}
            label={'Correo del Chofer (*Opcional)'}
            nameInput={'emailDriverDni'}
            onHandleChange={handleOnChangeSend}
            defaultValue={item.emailDriverDni}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-6"></div>
        <ConditionalRender
          condition={!showButtonSave}
          elseContent={
            <div className="col-md-6 d-flex justify-content-end">
              <div className="btn-group">
                <ButtonFormControl
                  text={'Cancelar'}
                  OnHandleClick={onClose}
                  className={'btn btn-secondary btn-lg'}
                />&nbsp;&nbsp;
                <ButtonFormControl
                  text={'Enviar'}
                  OnHandleClick={handleOnClickSendEmail}
                  className={'btn btn-success btn-lg'}
                />
              </div>
            </div>
          }
        >
          <div className="col-md-6 d-flex justify-content-end">
            <div className="btn-group">
              <ButtonFormControl
                text={'Continuar'}
                OnHandleClick={onClose}
                className={'btn btn-secondary btn-lg'}
              />
            </div>
          </div>
        </ConditionalRender>
      </div >
    </Fragment>
  )
}