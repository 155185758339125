import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Row, Col, Container, Input, Label, Alert } from "reactstrap";
import { useSelector, useDispatch } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "../../components/Common/withRouter"
import { socialLogin } from "../../store/actions"
import CarouselPage from "../AuthenticationInner/CarouselPage"
import { createSelector } from "reselect";
import { GetAllCompaniesService } from "../../service/company-service";
import { ServicePutAuthentication } from "../../service/user-service";
import { InputFormControl } from "../../components/molecules";
import { useGetAllConfigs } from "../../hooks/common-hook";
import LoginIcon from '@mui/icons-material/Login';

const Login = props => {
  let navigate = useNavigate();
  const [objCompany, setObjCompany] = useState({});
  const [passwordShow, setPasswordShow] = useState(false);
  const [errorAuth, setErrorAuth] = useState('');
  const { config } = useGetAllConfigs();

  const [formValues, setFormValues] = useState({
    user: '',
    password: '',
  });

  localStorage.clear();
  useEffect(() => {
    async function getObjCompany() {
      let objCompanyFinal = {};
      let companiesList = await GetAllCompaniesService();
      const filteredCompanies = companiesList.data.filter(com => com.selected === true);
      objCompanyFinal = filteredCompanies[0];
      setObjCompany(objCompanyFinal);
    }
    getObjCompany();
  }, [])
  const dispatch = useDispatch()
  const errorData = createSelector(
    (state) => state.Login,
    (state) => ({
      error: state.error,
    })
  );
  const { error } = useSelector(errorData);
  const signIn = type => {
    dispatch(socialLogin(type, props.router.navigate));
  };
  const socialResponse = type => {
    signIn(type);
  };
  const handleAccessLogin = async (_) => {
    setErrorAuth('');
    try {
      const data = {
        user: formValues.user,
        password: formValues.password
      };
      const auth = await ServicePutAuthentication(data);
      if (auth.status === 200) {
        const authUser = [{
          uid: auth.data.id,
          username: auth.data.nombres,
          role: auth.data.rol,
          email: auth.data.usuario,
        }];
        const companiesList = await GetAllCompaniesService();
        const objCompanyFinal = companiesList.data.find(com => com.selected === true);
        localStorage.setItem('objCompa', JSON.stringify(objCompanyFinal));
        localStorage.setItem('authUser', JSON.stringify(authUser));
        navigate('/dashboard');
      } else {
        setErrorAuth(auth.error);
      }
    } catch (error) {
      console.error('Error en la autenticación:', error);
      setErrorAuth('Error en la autenticación. Por favor, inténtalo de nuevo.');
    }
  }
  
  const handleOnChangeAuth = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  }
  document.title = "Login";
  return (
    <React.Fragment>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4} md={5} className="col-xxl-3">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-1 text-center">
                      <Link to="/dashboard" className="d-block auth-logo">
                        {
                          objCompany && (
                            <img src={objCompany.image} alt={''} height="96" />
                          )
                        }
                        {
                          !objCompany.image && (
                            <span className="logo-txt">LOGIN</span>
                          )
                        }
                      </Link>
                    </div>
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <h5 className="mb-0">Bienvenido!</h5>
                        <p className="text-muted mt-2">Ingrese sus datos para acceder.</p>
                      </div>

                      {errorAuth ? <Alert color="danger">{errorAuth}</Alert> : null}
                      {/* <div className="mb-3">
                        <Label className="form-label">DNI</Label>
                        <Input
                          name="text"
                          className="form-control"
                          type="text"
                          onChange={handleOnChangeAuthDni}
                        />
                      </div> */}

                      <div className="col-md-12">
                        <InputFormControl
                          type={'text'}
                          label={'DNI'}
                          onHandleChange={handleOnChangeAuth}
                          nameInput={'user'}
                        />
                      </div>
                      <div className="mb-3">
                        <div className="d-flex align-items-start">
                          <div className="flex-grow-1">
                            <Label className="form-label">Password</Label>
                          </div>
                          <div className="flex-shrink-0">
                            <div className="">
                              {/* <Link to="/page-recoverpw" className="text-muted">Olvidaste tu password?</Link> */}
                            </div>
                          </div>
                        </div>
                        <div className="input-group auth-pass-inputgroup">
                          <Input
                            name="password"
                            type={passwordShow ? "text" : "password"}
                            onChange={handleOnChangeAuth}
                          />
                          <button onClick={() => setPasswordShow(!passwordShow)} className="btn btn-light shadow-none ms-0" type="button" id="password-addon"><i className="mdi mdi-eye-outline"></i></button>
                        </div>
                      </div>

                      <div className="row mb-4">
                        <div className="col">
                          <div className="mt-3 d-grid">
                            <button
                              className="btn btn-primary btn-block"
                              onClick={(e) => handleAccessLogin(e)}
                            >
                              <LoginIcon />&nbsp;
                              Ingresar
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">{new Date().getFullYear()} {config.copyright}</p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <CarouselPage />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}