import React from "react"
import { Container, Row, Col } from "reactstrap"
import { COLOR_BUTTON_MAB, COLOR_GREEN } from "../../helpers/color"

const Footer = ({
  config = {}
}) => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={3}>{new Date().getFullYear()} {config.copyright}</Col>
            <Col md={4}>
              <div className="text-sm-end d-none d-sm-block" style={{color: config.desarrollo_produccion === 'DEV' ? COLOR_BUTTON_MAB: COLOR_GREEN}}>
                {config.desarrollo_produccion === 'DEV' ? '***CONECTADO A DESARROLLO***': '***CONECTADO A PRODUCCIÓN***'}
              </div>
            </Col>
            <Col md={5}>
              <div className="text-sm-end d-none d-sm-block">
                Versión {config.version} 
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
