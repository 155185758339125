import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table";
import { Table, Row, Col, Button } from "reactstrap";
import { Filter, DefaultColumnFilter } from "./filters";
import { Link } from "react-router-dom";
import { InputFormControl, SelectedFormControl } from "../../molecules";
import { CustomOptions } from "../../organism";
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import Cleave from "cleave.js/react";

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <Col sm={4}>
      <div className="search-box mb-0 d-inline-block">
        <div className="position-relative">
          <label htmlFor="search-bar-0" className="search-label">
            <span id="search-bar-0-label" className="sr-only">
              Search this table
            </span>
            <input
              onChange={e => {
                setValue(e.target.value);
                onChange(e.target.value);
              }}
              id="search-bar-0"
              type="text"
              className="form-control"
              // placeholder={`${count} records...`}
              value={value || ""}
            />
          </label>
          {/* <i className="bx bx-search-alt search-icon"></i> */}
        </div>
      </div>
    </Col>
  );
}

const TableContainer = ({
  columns,
  data,
  customPageSize,
  className,
  isBordered,
  theadClass,
  presentations = [],
  handleDeleteItem,
  handleOnChangeAddQuantity,
  handleOnChangeNaturalezaProduc,
  handleOnChangeWeightUnit,
  handleOnChangeKgBrutos,
  handleOnChangeKgNetos,
  handleOnChangePresentation,
  weightUnits = []
}) => {
  const {
    headerGroups,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: customPageSize },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
  );

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : "";
  };

  return (
    <Fragment>
      <div className="table-responsive" style={{ height: '70vh' }}>
        <Table hover className={className} bordered={isBordered} >
          <thead className={theadClass}>
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th key={column.id}>
                    <div className="mb-2" {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </div>
                    <Filter column={column} />
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            
            {
              data.length > 0 ? data.map((d, index) => {
                return (
                  <tr key={index}>
                    <td width={'7.5%'}>
                      <InputFormControl
                        type={'text'}
                        showIsLabel={false}
                        defaultValue={d.cantidad}
                        onHandleChange={(e) => handleOnChangeAddQuantity(e, d.id)}
                      />
                    </td>
                    <td width={'15%'}>
                      <div style={{ position: 'relative' }}>
                        <SelectedFormControl
                          placeholder={'Selecciona...'}
                          showLabel={false}
                          options={presentations.filter(pre => pre.state_value === true)}
                          onHandleChange={(e) => handleOnChangePresentation(e, d.id)}
                          style={{ zIndex: 2 }}
                        />
                      </div>
                    </td>
                    <td width={'30%'}>
                      <InputFormControl
                        type={'text'}
                        showIsLabel={false}
                        defaultValue={d.natureProduct}
                        onHandleChange={(e) => handleOnChangeNaturalezaProduc(e, d.id)}
                      />
                    </td>
                    <td width={'15%'}>
                      <div style={{ position: 'relative' }}>
                        <SelectedFormControl
                          placeholder={'Selecciona...'}
                          showLabel={false}
                          options={weightUnits}
                          onHandleChange={(e) => handleOnChangeWeightUnit(e, d.id)}
                          defaultValue={weightUnits.filter(w => parseInt(w.value) === 1)}
                          style={{ zIndex: 2 }}
                        />
                      </div>
                    </td>
                    <td width={'15%'}>
                      <InputFormControl
                        type={'text'}
                        showIsLabel={false}
                        defaultValue={d.kgBrutos}
                        onHandleChange={(e) => handleOnChangeKgBrutos(e, d.id)}
                      />
                    </td>
                    <td width={'15%'}>
                      <InputFormControl
                        type={'text'}
                        showIsLabel={false}
                        defaultValue={d.kgNetos}
                        onHandleChange={(e) => handleOnChangeKgNetos(e, d.id)}
                      />
                    </td>
                    <td>
                      <div className="text-center">
                        <CustomOptions
                          componenteType='ListAddRemoveItem'
                          item={d}
                          handleDeleteItem={handleDeleteItem}
                        />
                      </div>
                    </td>
                  </tr>
                )
              }): ''
            }
          </tbody>
        </Table>
        {
          data.length === 0 ?
          <CenteredContent />: ''
        }
      </div>
    </Fragment>

  );
};
const CenteredContent = () => {
  return (
    <div className="container h-100">
      <div className="row h-100 justify-content-center align-items-center">
        <div className="col-md-12 text-center">
          <Inventory2OutlinedIcon style={{height: '100%', width: '40px'}} /> 
          <div>Lista de productos vacía.</div>
          Agrega al menos un producto, busca y selecciona un producto para comenzar
        </div>
      </div>
    </div>
  );
};

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default TableContainer;
