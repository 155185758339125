import { useEffect, useState } from "react";
import { ServiceGetAllCategory } from "../service/category-service";

export function useGetAllCategory() {
  const [categorys, setCategory] = useState([]);
  useEffect(() => {
    async function getAllCategory() {
      let data = await ServiceGetAllCategory();
      setCategory(data.data);
    }
    getAllCategory();
  }, []);
  return {categorys, setCategory}
}