import React from 'react'
import Select, { components } from 'react-select';
import { COLOR_BUTTON_MAB } from '../../helpers/color';

const Option = props => {
  return (
    <span title={props.data.cNombreCompleto}>
      <components.Option {...props} />
    </span>
  );
}

export default function SelectedFormControl({
  options,
  placeholder,
  label,
  className,
  onHandleChange,
  top = false,
  isBold = false,
  isSetting = false,
  routeSetting = '',
  history,
  defaultValue = '',
  isRequired = false,
  value,
  disabled = false,
  selectedRef,
  titleName = '',
  isDisabled = false,
  isTooltip = false,
  normal = false,
  nameSelect,
  showLabel = true
}) {
  const handleRedirect = () => {
    history.push(routeSetting);
  }
  return(
    <>
      <div className={`${className} ${top ? 'mt-2': 'mt-2'}`}>
        {
          showLabel ?
            label !== undefined && isRequired ?
            <label className={isBold ? "font-weight-bold": ''}>{label} <label style={{color: isRequired ? COLOR_BUTTON_MAB: ''}}> * </label></label>
            : 
            <label className={isBold ? "font-weight-bold": ''}>{label}</label>
            : ''
        }
        <Select
          name={nameSelect}
          ref={selectedRef}
          options={options}
          onChange={onHandleChange}
          placeholder={placeholder}
          defaultValue={defaultValue}
          value={value}
          blurInputOnSelect={true}
          isClearable
          isDisabled={isDisabled}
          isSearchable
        />
      </div>
    </>
  )
}