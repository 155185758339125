import React, { Fragment } from "react"
import { ButtonFormControl, InputFormControl, TextAreaFormControl } from "../../molecules"
import { COLOR_BLUE_MAB, COLOR_BUTTON_MAB } from "../../../helpers/color"
import AddCircleIcon from '@mui/icons-material/AddCircle'
import SaveIcon from '@mui/icons-material/Save'
import AutoCompleteInput from "../../organism/Auto-Complete-Input"
import { ComponentDetailPerson } from "../../organism"
import ListPurchaseIncome from './list-purchase-income'
import ListPurchaseResult from './list-purchase-result'

export default function FormCreate({
  foundPerson,
  objReniecCustomer = {},
  showDropdown,
  handleEnterPressCustomer,
  handleSelectItem,
  flagsSelectTypeDocument = {},
  handleOnCloseAutoCompleteCustomer,
  handleOnClickCustomer,
  handleOnClickNewLine,
  lstLine = [],
  lstLineResult = [],
  handleOnChangeNetKilo,
  handleOnChangeNetKiloResult,
  totals = 0.00,
  totalsResult = 0.00,
  handleOnChangeProduct,
  productName = '',
  handleOnChangeNumberBagsIncome,
  handleOnChangeGuiaRemisionIncome,
  handleOnChangeGuiaIngresoIncome,
  handleOnChangeDateIncome,
  handleOnChangePercentageHumedityIncome,
  handleOnChangePercentageIncome,
  handleOnChangeDescriptionResult,
  handleOnChangeNumberBagsResult,
  handleOnChangePercentageHumedityResult,
  handleOptionChange,
  selectedOption = '',
  handleOnChangeInsert,
  handleOnSavePuchaseSettlement,
  handleDeleteItem
}) {

  return(
    <Fragment>
      <div className="row">
        <div className="col-md-9">
          <div className="row mt-1">
            <div className="col-md-8 mb-2">
              <div className="btn-group">
                <ButtonFormControl
                  text={
                    <>
                      <AddCircleIcon />
                      &nbsp;Agregar item
                    </>
                  }
                  OnHandleClick={handleOnClickNewLine}
                  className={'btn btn-dark btn-sm'}
                />&nbsp;&nbsp;
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <ListPurchaseIncome 
                rows={lstLine}
                handleOnChangeNetKilo={handleOnChangeNetKilo}
                totals={totals}
                productName={productName}
                handleOnChangeNumberBagsIncome={handleOnChangeNumberBagsIncome}
                handleOnChangeGuiaRemisionIncome={handleOnChangeGuiaRemisionIncome}
                handleOnChangeGuiaIngresoIncome={handleOnChangeGuiaIngresoIncome}
                handleOnChangeDateIncome={handleOnChangeDateIncome}
                handleOnChangePercentageIncome={handleOnChangePercentageIncome}
                handleOnChangePercentageHumedityIncome={handleOnChangePercentageHumedityIncome}
                handleDeleteItem={handleDeleteItem}
                />
            </div>      
            <div className="col-md-12 mt-3">
              <ListPurchaseResult 
                rows={lstLineResult}
                handleOnChangeNetKilo={handleOnChangeNetKiloResult}
                totalsResult={totalsResult}
                productName={productName}
                handleOnChangeDescriptionResult={handleOnChangeDescriptionResult}
                handleOnChangeNumberBagsResult={handleOnChangeNumberBagsResult}
                handleOnChangePercentageHumedityResult={handleOnChangePercentageHumedityResult}
              />
            </div>      
          </div>
        </div>
        <div className="col-md-3">
          <div className="row">
            <div className="col-md-12">
              <h5 className="font-size-14 mb-3">
                <i className="mdi mdi-arrow-right text-primary me-1"></i>
                Tipos
              </h5>
              <div className="row text-center">
                <div className="form-check mb-1 col-md-4">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="formRadios"
                    id="formRadios1"
                    value="C"
                    onChange={(e) => handleOptionChange(e)}
                  />
                  <label className="form-check-label" htmlFor="formRadios1">
                    Convencional
                  </label>
                </div>
                <div className="form-check mb-1 col-md-4">
                  <input
                    className="form-check-input mb-1"
                    type="radio"
                    name="formRadios"
                    id="formRadios2"
                    value="O"
                    onChange={(e) => handleOptionChange(e)}
                  />
                  <label className="form-check-label" htmlFor="formRadios2">
                    Orgánico
                  </label>
                </div>
                <div className="form-check mb-1 col-md-4">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="formRadios"
                    id="formRadios3"
                    value="E"
                    onChange={(e) => handleOptionChange(e)}
                  />
                  <label className="form-check-label" htmlFor="formRadios3">
                    Extra
                  </label>
                </div>
              </div>
            </div>
            <div className="col-md-12 mb-2 mt-2">
              <InputFormControl
                type={'text'}
                label={'Cliente'}
                nameInput={'customer'}
                placeholder={'Ingresa el nombre del cliente'}
                onHandleChange={handleOnChangeInsert}
              />
            </div>
            <div className="col-md-12 mb-2">
              <InputFormControl
                type={'text'}
                label={'Producto'}
                nameInput={''}
                placeholder={'Ingresa el nombre del producto'}
                onHandleChange={handleOnChangeProduct}
              />
            </div>
            <div className="col-md-12">
              <label style={{ color: COLOR_BLUE_MAB }}>Origen: </label>
              <TextAreaFormControl
                className={'form-control'}
                rows={2}
                name={'origen'}
                onChange={handleOnChangeInsert}
                />
            </div>
            <div className="col-md-6">
              <InputFormControl
                type={'date'}
                label={'Fecha Secado'}
                nameInput={'fechaSecado'}
                onHandleChange={handleOnChangeInsert}
                />
            </div>
            <div className="col-md-6">
              <InputFormControl
                type={'date'}
                label={'Fecha Actual'}
                nameInput={'dateNow'}
                onHandleChange={handleOnChangeInsert}
                />
            </div>
            <div className="col-md-12 mt-2">
              <label style={{ color: COLOR_BUTTON_MAB }}>Observaciones: </label>
              <TextAreaFormControl
                className={'form-control'}
                rows={2}
                name={'observations'}
                onChange={handleOnChangeInsert}
                />
            </div>
            <div className="col-md-6 mt-3">
              <ButtonFormControl
                text={
                  <><SaveIcon />&nbsp;Guardar</>
                }
                OnHandleClick={handleOnSavePuchaseSettlement}
                className="btn btn-success w-100"
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}