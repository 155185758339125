import React from "react";
import { ButtonFormControl, InputFormControl, SelectedFormControl } from "../../molecules";

export default function FormCreate({
  handleOnCloseVehicleNew
  , onHandleChangeInsert
  , onHandleChangeSelectInsert
  , handleOnSaveVehicleNew
  , brands = []
  , sizeButton = 'lg'
  , showButtonCancel = true
  , showOptimizableInputs = false
}) {
  if(!showOptimizableInputs) {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <InputFormControl
              type={'text'}
              label={'Placa Frontal'}
              onHandleChange={onHandleChangeInsert}
              nameInput={'license_plate'}
              autoFocus
            />
          </div>
          <div className="col-md-4">
            <InputFormControl
              type={'text'}
              label={'Placa Carreta'}
              onHandleChange={onHandleChangeInsert}
              nameInput={'license_plate_carreta'}
            />
          </div>
          <div className="col-md-4">
            <SelectedFormControl
              label={'Marca'}
              placeholder={'Seleccione una marca...'}
              options={brands}
              onHandleChange={(selectedOption) => onHandleChangeSelectInsert(selectedOption, 'selectValueBrand')}
            />
          </div>
          {/* <div className="col-md-4">
            <InputFormControl
              type={'number'}
              label={'Año de fabricación'}
              onHandleChange={onHandleChangeInsert}
              nameInput={'year_of_production'}
            />
          </div> */}
          {/* <div className="col-md-4">
            <InputFormControl
              type={'text'}
              label={'Color'}
              onHandleChange={onHandleChangeInsert}
              nameInput={'color'}
            />
          </div> */}
        </div>
        <div className="row">
          {/* <div className="col-md-4 mt-2">
            <InputFormControl
              type={'number'}
              label={'Cantidad de llantas'}
              onHandleChange={onHandleChangeInsert}
              nameInput={'quantity_tires'}
            />
          </div> */}
          {/* <div className="col-md-4 mt-2">
            <SelectedFormControl
              label={'Marca'}
              placeholder={'Seleccione una marca...'}
              options={brands}
              onHandleChange={(selectedOption) => onHandleChangeSelectInsert(selectedOption, 'selectValueBrand')}
            />
          </div> */}
        </div>
        <div className="row mt-4">
          <div className="col-md-6"></div>
          <div className="col-md-6 d-flex justify-content-end">
            <div className="btn-group">
              {
                showButtonCancel ?
                <>
                  <ButtonFormControl
                    text={'Cerrar ventana'}
                    className={`btn btn-danger`}
                    OnHandleClick={handleOnCloseVehicleNew}
                  />&nbsp;&nbsp;
                </>: ''
              }
              <ButtonFormControl
                text={'Guardar Vehículo'}
                className={`btn btn-success`}
                OnHandleClick={handleOnSaveVehicleNew}
              />
            </div>
          </div>
        </div >
      </div>
    )
  } else {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <InputFormControl
              type={'text'}
              label={'Placa Frontal'}
              onHandleChange={onHandleChangeInsert}
              nameInput={'license_plate'}
              autoFocus
            />
          </div>
          <div className="col-md-4">
            <InputFormControl
              type={'text'}
              label={'Placa Carreta'}
              onHandleChange={onHandleChangeInsert}
              nameInput={'license_plate_carreta'}
            />
          </div>
          <div className="col-md-4">
            <SelectedFormControl
              label={'Marca'}
              placeholder={'Seleccione una marca...'}
              options={brands}
              onHandleChange={(selectedOption) => onHandleChangeSelectInsert(selectedOption, 'selectValueBrand')}
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-6"></div>
          <div className="col-md-6 d-flex justify-content-end">
            <div className="btn-group">
              {
                showButtonCancel ?
                <>
                  <ButtonFormControl
                    text={'Cerrar ventana'}
                    className={`btn btn-danger btn-${sizeButton}`}
                    OnHandleClick={handleOnCloseVehicleNew}
                  />&nbsp;&nbsp;
                </>: ''
              }
              <ButtonFormControl
                text={'Guardar Vehículo'}
                className={`btn btn-success btn-${sizeButton}`}
                OnHandleClick={handleOnSaveVehicleNew}
              />
            </div>
          </div>
        </div >
      </div>
    )
  }
}