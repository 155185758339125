import React, { useEffect, useState, useRef, useMemo, Fragment } from "react";
import { Link } from "react-router-dom";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Col,
  Row,

} from "reactstrap";
import { Badge } from "reactstrap";
import { AddNewItem, CustomOptions, ExportImportExcel } from "../../organism";
import { formatDecimal } from "../../../helpers/general_functions";

const List = ({
  rows = []
  , handleOnClickDriverNew
  , handleOnEdit
  , handleEnabledDisabled
}) => {
  const columns = useMemo(
    () => [
      {
        Header: "Nombres completos",
        filterable: false,
        disableFilters: true,
        accessor: "names",
        Cell: (driver) => (
          <>
            <span className="font-size-14 mb-1">
              <Link to="#" className="text-dark">
                {driver?.cell.row.original.names}, {driver.cell.row.original.last_names}
              </Link>
            </span>
          </>
        ),
      },
      {
        Header: "Licencia de conducir",
        filterable: false,
        disableFilters: true,
        accessor: "license_code",
        Cell: (product) => (
          <>
            <span className="font-size-14 mb-1">
              <Link to="#" className="text-dark">
                {product?.cell.row.original.license_code}
              </Link>
            </span>
          </>
        ),
      },
      {
        Header: "Vehículo",
        filterable: false,
        disableFilters: true,
        accessor: "role",
        Cell: (product) => (
          <>
            <span className="font-size-14 mb-1">
              <Link to="#" className="text-dark">
                {product?.cell.row.original.vehicle_license_plate}
              </Link>
            </span>
          </>
        ),
      },
      {
        Header: () => ( // Utilizamos una función para personalizar el encabezado
          <div className="text-center"> {/* Aplicamos la clase text-center aquí */}
            Nro Documento
          </div>
        ),
        filterable: false,
        disableFilters: true,
        accessor: "categoria",
        Cell: (user) => (
          <div className="text-center">
            <span className="font-size-14 mb-1">
              <Link to="#" className="text-dark">
                {user?.cell.row.original.type_document} - {user.cell.row.original.number_document}
              </Link>
            </span>
          </div>
        ),
      },
      {
        Header: () => ( // Utilizamos una función para personalizar el encabezado
          <div className="text-center"> {/* Aplicamos la clase text-center aquí */}
            Email
          </div>
        ),
        filterable: false,
        disableFilters: true,
        accessor: "email",
        Cell: (user) => (
          <div className="text-center">
            <span className="font-size-14 mb-1">
              <Link to="#" className="text-dark">
                {user?.cell.row.original?.emails[0]?.email}
              </Link>
            </span>
          </div>
        ),
      },
      {
        Header: () => ( // Utilizamos una función para personalizar el encabezado
          <div className="text-center"> {/* Aplicamos la clase text-center aquí */}
            Estado
          </div>
        ),
        filterable: false,
        disableFilters: true,
        accessor: "state",
        Cell: (product) => (
          <div className="text-center">
            <span className="font-size-14 mb-1">
              <Badge color={showColorState(product?.cell?.row.original.state_value)}>
                {product?.cell.row.original.state}
              </Badge>
            </span>
          </div>
        )
      },
      {
        Header: () => ( // Utilizamos una función para personalizar el encabezado
          <div className="text-center"> {/* Aplicamos la clase text-center aquí */}
            Acción
          </div>
        ),
        filterable: false,
        disableFilters: true,
        accessor: "accion",
        Cell: (user) => (
          <div className="text-center">
            <CustomOptions 
              componenteType = 'ListDriver'
              item={user.cell.row.original}
              handleOnEdit={handleOnEdit}
              handleEnabledDisabled={handleEnabledDisabled}
            />
          </div>
        )
      },
    ],
    []
  );
  const showColorState = (value) => {
    return value ? `success` : 'danger';
  }
  return (
    <Fragment>
      <Row>
        <Col lg="12">
          <Row className="align-items-center">
            <Col md={6}>
            </Col>
            <Col md={6}>
              <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                <AddNewItem 
                  title="Nuevo Chofer"
                  handleOnClick={handleOnClickDriverNew}
                />
                <ExportImportExcel />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl="12" className="mb-4">
              <TableContainer
                columns={columns}
                data={rows}
                isGlobalFilter={true}
                isAddUserList={true}
                customPageSize={10}
                className="table align-middle datatable dt-responsive table-check nowrap"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Fragment>
  );
};

export default List;
