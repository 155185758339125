
import React from "react";
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Tooltip } from "@mui/material";
import { CART, VEHICLE } from "../../config/config";
import RvHookupIcon from '@mui/icons-material/RvHookup';

export default function DetailVehicleAssignment({
	className = '',
	handleOnDelete,
	item = {},
	vehicleLicense = '',
	icon = ''
}) {
	function RenderIcon() {
		switch (icon) {
			case CART:
				return(
					<RvHookupIcon />
				)
			case VEHICLE:
				return(
					<LocalShippingOutlinedIcon />
				)
			default:
				break;
		}
	}
	return(
		<div className={className}>
			<div className="row m-auto" style={{border: '1px solid #ccc',  borderRadius: '8px'}}>
				<div className="col-md-2 text-center mt-1">
					{RenderIcon()}
				</div>
				<div className="col-md-8 ">
					<div className="row">
						<div className="col-md-12 mt-1">
							<span>Placa: {vehicleLicense}</span>
						</div>
						{/* <div className="col-md-12">
							<span>Placa Carreta: {item?.license_cart}</span>
						</div> */}
					</div>
				</div>
				<div className="col-md-2 mt-1">
					<Tooltip title='Quitar asignación'>
						<div onClick={(e) => handleOnDelete(e, item)}>
							<DeleteOutlineOutlinedIcon style={{cursor: 'pointer'}} />
						</div>
					</Tooltip>
				</div>
			</div>
		</div>
	)
}