import { Uri_GetAllAreas, Uri_GetAllBrands, Uri_GetAllConfigs, Uri_SendEmail, Uri_SendEmailCredentials } from "../api/common/api-common";
import { get, post } from "../helpers/api_helper";

export async function ServiceGetAllBrands() {
  return await get(Uri_GetAllBrands);
}
export async function ServiceGetAllConfigs() {
  return await get(Uri_GetAllConfigs);
}
export async function ServiceGetAllAreas() {
  return await get(Uri_GetAllAreas);
}
export async function ServiceSendEmail(data) {
  return await post(Uri_SendEmail, data);
}
export async function ServiceSendEmailCredentials(data) {
  return await post(Uri_SendEmailCredentials, data);
}
