import React, { useState } from "react";
import FormCreate from "./form-create";
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { Container } from "reactstrap";
import { Button, Tooltip } from "@mui/material";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate } from "react-router-dom";
import { formatDecimal, getTitle, validaSoloDigitosDecimales } from "../../../helpers/general_functions";
import { ServicePostPurchaseSettlement } from "../../../service/purchase-settlement";
import Swal from 'sweetalert2';
import { Uri_GetDownloadPdf } from "../../../api/purchase-settlement/api-purchase-settlement";

export default function Manager() {
  getTitle('Registro de una Liquidación de Compra')
  let navigate = useNavigate()

  // Hooks
  const [lstLine, setLstLine] = useState([])
  const [lstLineResult, setLstLineResult] = useState([])
  const [lastId, setLastId] = useState(0)
  const [productName, setProductName] = useState('')
  const [selectedOption, setSelectedOption] = useState('');
  const [formValues, setFormValues] = useState({
    origen: '',
    fechaSecado: '',
    dateNow: '',
    observations: '',
    customer: ''
  })

  function handleOnCancelExitNote() {
    navigate('/tesoreria/liquidacion-compra')
  }
  function calculateTotals(lines) {
    return lines.reduce((acc, row) => {
      acc.numberBags += parseInt(row.numberBags) || 0;
      acc.kilosNetos += parseFloat(row.netKilo);
      acc.qqs552 += parseFloat(row.qqs55_2);
      acc.qqs46 += parseFloat(row.qqs46);
      return acc;
    }, {
      numberBags: 0,
      kilosNetos: 0.00,
      qqs552: 0.00,
      qqs46: 0.00
    });
  };
  function calculateTotalsResult(lines) {
    return lines.reduce((acc, row) => {
      acc.numberBags += parseInt(row.numberBags) || 0;
      acc.kilosNetos += parseFloat(row.netKilo);
      acc.qqs552 += parseFloat(row.qqs55_2);
      acc.qqs46 += parseFloat(row.qqs46);
      acc.percentage += parseFloat(row.percentage);
      return acc;
    }, {
      sacos: 0,
      kilosNetos: 0.00,
      qqs552: 0.00,
      qqs46: 0.00,
      percentage: 0.00
    });
  };
  function handleOnClickNewLine() {
    const newId = lastId + 1; // Generar un nuevo ID
    setLastId(newId); // Actualizar la variable del último ID generado
  
    const createNewLine = (id) => ({
      id: id,
      fecha: '',
      guiaRemision: '',
      guiaIngreso: '',
      numberBags: 0,
      netKilo: 0.00,
      qqs55_2: 0.00,
      qqs46: 0.00,
      percentage: 0.00,
      percentageHumedity: 0.00,
    });
  
    const createNewLineResult = (id, productName) => ({
      id: id,
      description: productName,
      numberBags: 0,
      netKilo: formatDecimal(0.00),
      qqs55_2: 0.00,
      qqs46: 0.00,
      percentage: 0.00,
      percentageHumedity: 0.00,
    });
  
    setLstLine(prevLines => [...prevLines, createNewLine(newId)]);
  
    if (lstLineResult.length < 2) {
      setLstLineResult(prevLines => [
        ...prevLines,
        createNewLineResult(1, productName),
        createNewLineResult(2, ''),
      ]);
    }
  }
  function handleOnChangeNetKilo(e, item) {
    let netKilo = (e.target.value).trim();
    if(!validaSoloDigitosDecimales(netKilo)) {
      document.getElementById('txtNetKilo').value = ''
      return;
    }
    netKilo = formatDecimal(parseFloat(netKilo))
    const updatedList = lstLine.map(line => {
      if (parseInt(line.id) === parseInt(item.id)) {
        return {
          ...line,
          netKilo: formatDecimal(netKilo),
          qqs55_2: formatDecimal((netKilo / 55.2).toFixed(2)),
          qqs46: formatDecimal((netKilo / 46).toFixed(2)),
        };
      }
      return line;
    });
    setLstLine(updatedList);
    const totals = calculateTotals(updatedList);
    const updateListResult = lstLineResult.map(line => {
      if(parseInt(line.id) === 1) {
        return {
          ...line,
          netKilo: formatDecimal(totals.kilosNetos),
          qqs55_2: formatDecimal((totals.kilosNetos / 55.2).toFixed(2)),
          qqs46: formatDecimal((totals.kilosNetos / 46).toFixed(2)),
          percentage: formatDecimal((totals.kilosNetos * 100) / totals.kilosNetos)
        }
      }
      return line
    })
    setLstLineResult(updateListResult)
  }
 
    const handleOnChangeNetKiloResult = (e, d) => {
    let netKiloMerma = (e.target.value).trim()
  
    if(!validaSoloDigitosDecimales(netKiloMerma)) {
      document.getElementById('txtNetKiloResult').value = ''
      return;
    }
    netKiloMerma = parseFloat(netKiloMerma)
    const totals = calculateTotals(lstLine);
    let originalNetKilo = totals.kilosNetos
    const updateListResult = lstLineResult.map(line => {
      if (parseInt(line.id) === 1) {
        let resultNetKiloResta = formatDecimal(originalNetKilo - netKiloMerma);
        return {
          ...line,
          netKilo: formatDecimal(resultNetKiloResta),
          qqs55_2: formatDecimal((resultNetKiloResta / 55.2).toFixed(2)),
          qqs46: formatDecimal((resultNetKiloResta / 46).toFixed(2)),
          percentage: formatDecimal((resultNetKiloResta * 100) / totals.kilosNetos)
        };
      } else if (parseInt(line.id) === 2) {
        return {
          ...line,
          netKilo: formatDecimal(netKiloMerma),
          qqs55_2: formatDecimal((netKiloMerma / 55.2).toFixed(2)),
          qqs46: formatDecimal((netKiloMerma / 46).toFixed(2)),
          percentage: formatDecimal((netKiloMerma * 100) / totals.kilosNetos)
        };
      }
      return line;
    });

    setLstLineResult(updateListResult);
  };
  function handleOnChangeProduct(e) {
    setProductName(e.target.value)
  }
  function handleOnChangeNumberBagsIncome(e, d) {
    e.preventDefault()
    let numberBagsIncome = (e.target.value).trim()
    if(!validaSoloDigitosDecimales(numberBagsIncome)) {
      document.getElementById('txtNumberBags').value = ''
      return;
    }
    const updateList = lstLine.map(line => {
      if(parseInt(line.id) === parseInt(d.id)) {
        return {
          ...line,
          numberBags: parseInt(numberBagsIncome)
        }
      }
      return line
    })
    setLstLine(updateList)
  }
  function handleOnChangeGuiaIngresoIncome(e, d) {
    let guiaIngreso = (e.target.value)
    const updateList = lstLine.map(line => {
      if(parseInt(line.id) === parseInt(d.id)) {
        return {
          ...line,
          guiaIngreso: guiaIngreso
        }
      }
      return line
    })
    setLstLine(updateList)
  }
  function handleOnChangeGuiaRemisionIncome(e, d) {
    let guiaRemision = (e.target.value)
    const updateList = lstLine.map(line => {
      if(parseInt(line.id) === parseInt(d.id)) {
        return {
          ...line,
          guiaRemision: guiaRemision
        }
      }
      return line
    })
    setLstLine(updateList)
  }
  function handleOnChangeDateIncome(e, d) {
    let dateIncomeGuia = (e.target.value)
    const updateList = lstLine.map(line => {
      if(parseInt(line.id) === parseInt(d.id)) {
        return {
          ...line,
          fecha: (dateIncomeGuia)
        }
      }
      return line
    })
    setLstLine(updateList)
  }
  function handleOnChangePercentageHumedityIncome(e, d) {
    let percentageHumedityIncome = (e.target.value).trim()

    if(!validaSoloDigitosDecimales(percentageHumedityIncome)) {
      document.getElementById('txtPercentageHumeIncome').value = ''
      return;
    }
    percentageHumedityIncome = parseFloat(percentageHumedityIncome)
    const updateList = lstLine.map(line => {
      if(parseInt(line.id) === parseInt(d.id)) {
        return {
          ...line,
          percentageHumedity: formatDecimal(percentageHumedityIncome)
        }
      }
      return line
    })
    setLstLine(updateList)
  }
  function handleOnChangePercentageIncome(e, d) {
    let percentageIncome = (e.target.value).trim()

    if(!validaSoloDigitosDecimales(percentageIncome)) {
      document.getElementById('txtPercentageIncome').value = ''
      return;
    }

    const updateList = lstLine.map(line => {
      if(parseInt(line.id) === parseInt(d.id)) {
        return {
          ...line,
          percentage: formatDecimal(percentageIncome)
        }
      }
      return line
    })
    setLstLine(updateList)
  }
  // Resultado
  function handleOnChangeDescriptionResult(e, d) {
    let descriptionResult = (e.target.value)
    const updateListResult = lstLineResult.map(line => {
      if(parseInt(line.id) === parseInt(d.id)) {
        return {
          ...line,
          description: descriptionResult
        }
      }
      return line
    })
    setLstLineResult(updateListResult)
  }
    function handleOnChangeNumberBagsResult(e, d) {
      let numberBagsResult = (e.target.value).trim()
      if(!validaSoloDigitosDecimales(numberBagsResult)) {
        document.getElementById('txtNumberBagsResult').value = ''
        return;
      }
      numberBagsResult = parseInt(numberBagsResult)
      const updateListResult = lstLineResult.map(line => {
        if(parseInt(line.id) === parseInt(d.id)) {
          return {
            ...line,
            numberBags: parseInt(numberBagsResult)
          }
        }
        return line
      })
      if (!validaTodaLaLista(updateListResult)) {
        e.target.value = ''
        return;
      } 
      setLstLineResult(updateListResult)
    }
  function validaTodaLaLista(lista) {
    return lista.every(item => validaSoloDigitosDecimales(item.numberBags.toString()));
  }
  function handleOnChangePercentageHumedityResult(e, d) {
    let percentageHumedity = (e.target.value).trim()

    if(!validaSoloDigitosDecimales(percentageHumedity)) {
      document.getElementById('txtPercentageHumedityResult').value = ''
      return;
    }
    percentageHumedity = parseFloat(percentageHumedity)
    const updateListResult = lstLineResult.map(line => {
      if(parseInt(line.id) === parseInt(d.id)) {
        return {
          ...line,
          percentageHumedity: formatDecimal(percentageHumedity)
        }
      }
      return line
    })
    setLstLineResult(updateListResult)
  }
  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value)
  }
  function handleOnChangeInsert(e) {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  }
  function handleOnSavePuchaseSettlement() {
    if(!productName) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `Debe de ingresar el nombre del producto.`,
      });
      return
    }
    if(!formValues.customer) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `Debe de ingresar el cliente.`,
      });
      return
    }
    if(!formValues.origen) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `Debe de ingresar el origen.`,
      });
      return
    }
    if(!formValues.fechaSecado) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `Debe de ingresar la fecha de secado`,
      });
      return
    }
    if(lstLine.length === 0) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `Debe de ingresar un item para registrar una liquidación de compra.`,
      });
      return
    }
    if(selectedOption === '' || !selectedOption) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `Debe de seleccionar una opción (Convencional, Orgánico o Extra)`,
      });
      return
    }
    formValues.product = productName
    let data = {
      formValues,
      lstLine,
      lstLineResult,
      selectedOption,
    }
    console.log(data);
    Swal.fire({
      title: `Seguro de guardar la liquidación de compra?`,
      text: "Si, estoy seguro!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Si guardar`,
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        data = JSON.stringify(data);
        const insert = await ServicePostPurchaseSettlement(data);
        if (insert.status === 200) {
          Swal.fire({
            title: `Registro exitoso`,
            text: `Se ha registrado la liquidación de compra`,
            icon: "success"
          });
          console.log(insert);
          const url = insert.data.url_pdf;
          const name = `${insert.data.pdf_name}`;
          handleDownloadPdf(url, name)
          navigate('/tesoreria/liquidacion-compra/');
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `${insert.error}`,
          });
        }
      }
    });
  }
  function handleDownloadPdf(url, name) {
    fetch(Uri_GetDownloadPdf(url, name))
    .then(response => {
      if (!response.ok) {
        throw new Error('Ocurrió un error al descargar el PDF');
      }
      return response.blob();
    })
    .then(blob => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${name}`);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
    })
    .catch(error => {
      console.error('Error:', error);
    });
  }
  function handleDeleteItem(e, d) {
    const updatedList = lstLine.filter(item => item.id !== d.id);
    setLstLine([...updatedList]); 
  }
  const totals = calculateTotals(lstLine);
  const totalsResult = calculateTotalsResult(lstLineResult);
  return(
    <div className="page-content">
      <Container fluid>
        <div className="row">
          <div className="col-md-1">
            <Button
              id="basic-button"
              aria-haspopup="true"
              onClick={handleOnCancelExitNote}
              style={{ fontFamily: 'Arial', fontSize: '16px'}}
            >
              <Tooltip title='Volver al listado general de Liquidación de compras'>
                <KeyboardBackspaceIcon />
              </Tooltip>
            </Button>
          </div>
          <div className="col-md-11 mt-2">
            <Breadcrumbs title="Tesorería" breadcrumbItem="Registro de Liquidación de Compra" />
          </div>
        </div>
        <FormCreate 
          handleOnClickNewLine={handleOnClickNewLine}
          lstLine={lstLine}
          lstLineResult={lstLineResult}
          handleOnChangeNetKilo={handleOnChangeNetKilo}
          handleOnChangeNetKiloResult={handleOnChangeNetKiloResult}
          totals={totals}
          totalsResult={totalsResult}
          handleOnChangeProduct={handleOnChangeProduct}
          productName={productName}
          handleOnChangeNumberBagsIncome={handleOnChangeNumberBagsIncome}
          handleOnChangeGuiaRemisionIncome={handleOnChangeGuiaRemisionIncome}
          handleOnChangeGuiaIngresoIncome={handleOnChangeGuiaIngresoIncome}
          handleOnChangeDateIncome={handleOnChangeDateIncome}
          handleOnChangePercentageHumedityIncome={handleOnChangePercentageHumedityIncome}
          handleOnChangePercentageIncome={handleOnChangePercentageIncome}
          //Resultado
          handleOnChangeDescriptionResult={handleOnChangeDescriptionResult}
          handleOnChangeNumberBagsResult={handleOnChangeNumberBagsResult}
          handleOnChangePercentageHumedityResult={handleOnChangePercentageHumedityResult}
          handleOptionChange={handleOptionChange}
          selectedOption={selectedOption}
          handleOnChangeInsert={handleOnChangeInsert}
          handleOnSavePuchaseSettlement={handleOnSavePuchaseSettlement}
          handleDeleteItem={handleDeleteItem}
        />
      </Container>
    </div>
  )
}