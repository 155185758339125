
export function getTitle(value){
  const storedData = localStorage.getItem('objCompa');
  const parsedData = JSON.parse(storedData);
  return document.title = `${parsedData.business_name.toUpperCase()} - Módulo ${value}`;
}
export function formatDecimal(value = 0.00) {
  const parsedNum = parseFloat(value);
  return isNaN(parsedNum) ? '0.00' : parsedNum.toFixed(2);
}
export const formatearFecha = (date) => {
  const fechaActual = new Date(date);
  const year = fechaActual.getFullYear();
  const month = String(fechaActual.getMonth() + 1).padStart(2, '0');
  const day = String(fechaActual.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};
export function isNumberKey(evt)
{
   var charCode = (evt.which) ? evt.which : evt.keyCode;
   if (charCode != 46 && charCode > 31 
     && (charCode < 48 || charCode > 57))
      return false;

   return true;
}
export function validaSoloDigitosDecimales (numero) {
  let patron = /^[\d.,]+$/
  return patron.test(numero) || numero === '';
}
export const getNowDate = () => {
  return formatearFecha(new Date());
}
export const captureValueInsideSquareBracket = (value = '') => {
  let indexLeft = value.indexOf("[");
  let indexRight = value.indexOf("]");
  if(indexLeft !== -1 && indexRight !== -1) {
    return value.substring(indexLeft + 1, indexRight)
  }
}
export const getValueNamePerMonth = (value) => {
  let meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
  let mesNombre = '';

  if(! isNaN(value) && value >= 1  && value <= 12 ) {
    mesNombre = meses[value - 1];
  }
  return mesNombre.toUpperCase();

}
export function getNameAndLastName(str) {
  const tokens = str.trim().split(' '); // Quita espacios en blanco al principio y al final del string
  const names = []
  const special_tokens = ['da','de','del','la', 'las','los','mac','mc','van','von','y','i','san','santa']
  let prev = "";
  tokens.forEach(token =>{
    var _token = token.toLowerCase();
    if(special_tokens.includes(_token)){
      prev = `${prev}${firstLetterUpperCase(_token)} `;
    }else{
      names.push(`${prev}${firstLetterUpperCase(_token)}`);
      prev = "";
    }
  });
  const num_nombres = names.length;
  let nombres = ""
  let apellidos = "";
  switch (num_nombres){
    case 0:
      nombres = '';
      break;
    case 1:
      nombres = names[0];
      break;
    case 2:
      nombres = names[1];
      apellidos = names[0];
      break;
    case 3:
      nombres = names[2];
      apellidos = `${names[0]} ${names[1]}`;
      break;
    case 4:
      nombres = `${names[2]} ${names[3]}`;
      apellidos = `${names[0]} ${names[1]}`;
      break;
    default:
      nombres = `${names[2]} ${names[3]}`;
      names.splice(0, 4);
      apellidos = names.join(" ");
      break;
  }
  return {
    nombres,
    apellidos,
    apellidoPaterno: apellidos.split(" ")[0],
    apellidoMaterno: apellidos.split(" ")[1],
  }
}
function firstLetterUpperCase(str){
  return str.charAt(0).toUpperCase() + str.slice(1);
}


export function actualizarPropiedad(objeto, propiedad, valor) {
  return {
    ...objeto,
    [propiedad]: valor
  };
}