import React, { useEffect, useState, useRef, useMemo, Fragment } from "react";
import { Link } from "react-router-dom";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Col,
  Row,

} from "reactstrap";
import { Badge } from "reactstrap";
import { AddNewItem, CustomOptions, ExportImportExcel } from "../../organism";
import { formatDecimal } from "../../../helpers/general_functions";

const List = ({
  rows = []
  , handleOnClickProductNew
  , handleOnEdit
  , handleEnabledDisabled
}) => {
  const columns = useMemo(
    () => [
      // {
      //   Header: "#",
      //   Cell: () => {
      //     return <input type="checkbox" className="form-check-input" />;
      //   },
      // },
      {
        Header: "Placa Frontal",
        filterable: false,
        disableFilters: true,
        accessor: "vehicle_license_plate",
        Cell: (product) => (
          <>
            <span className="font-size-14 mb-1">
              <Link to="#" className="text-dark">
                {product?.cell.row.original.vehicle_license_plate}
              </Link>
            </span>
          </>
        ),
      },
      {
        Header: "Placa Carreta",
        filterable: false,
        disableFilters: true,
        accessor: "vehicle_license_plate_carreta",
        Cell: (product) => (
          <>
            <span className="font-size-14 mb-1">
              <Link to="#" className="text-dark">
                {product?.cell.row.original.vehicle_license_plate_carreta}
              </Link>
            </span>
          </>
        ),
      },
      // {
      //   Header: () => ( // Utilizamos una función para personalizar el encabezado
      //     <div className="text-center"> {/* Aplicamos la clase text-center aquí */}
      //       Año de fabricación
      //     </div>
      //   ),
      //   filterable: false,
      //   disableFilters: true,
      //   accessor: "year_of_production",
      //   Cell: (product) => (
      //     <div className="text-center">
      //       <span className="font-size-14 mb-1">
      //         <Link to="#" className="text-dark">
      //           {product?.cell.row.original.year_of_production}
      //         </Link>
      //       </span>
      //     </div>
      //   ),
      // },
      // {
      //   Header: () => ( // Utilizamos una función para personalizar el encabezado
      //     <div className="text-center"> {/* Aplicamos la clase text-center aquí */}
      //       Color
      //     </div>
      //   ),
      //   filterable: false,
      //   disableFilters: true,
      //   accessor: "color",
      //   Cell: (product) => (
      //     <div className="text-center">
      //       <span className="font-size-14 mb-1">
      //         <Link to="#" className="text-dark">
      //           {product?.cell.value}
      //         </Link>
      //       </span>
      //     </div>
      //   ),
      // },
      // {
      //   Header: () => ( // Utilizamos una función para personalizar el encabezado
      //     <div className="text-center"> {/* Aplicamos la clase text-center aquí */}
      //       Cantidad de Llantas
      //     </div>
      //   ),
      //   filterable: false,
      //   disableFilters: true,
      //   accessor: "quantity_tires",
      //   Cell: (product) => (
      //     <div className="text-center">
      //       <span className="font-size-14 mb-1">
      //         <Link to="#" className="text-dark">
      //           {parseInt(product?.cell.value)} llantas
      //         </Link>
      //       </span>
      //     </div>
      //   ),
      // },
      {
        Header: () => ( // Utilizamos una función para personalizar el encabezado
          <div className="text-center"> {/* Aplicamos la clase text-center aquí */}
            Marca
          </div>
        ),
        filterable: false,
        disableFilters: true,
        accessor: "brand",
        Cell: (product) => (
          <div className="text-center">
            <span className="font-size-14 mb-1">
              <Link to="#" className="text-dark">
                {product?.cell.row.original.brand}
              </Link>
            </span>
          </div>
        ),
      },
      {
        Header: () => ( // Utilizamos una función para personalizar el encabezado
          <div className="text-center"> {/* Aplicamos la clase text-center aquí */}
            Tipo
          </div>
        ),
        filterable: false,
        disableFilters: true,
        accessor: "vehicle_type",
        Cell: (product) => (
          <div className="text-center">
            <span className="font-size-14 mb-1">
              <Link to="#" className="text-dark">
                {product?.cell.row.original.vehicle_type}
              </Link>
            </span>
          </div>
        ),
      },
      {
        Header: () => ( // Utilizamos una función para personalizar el encabezado
          <div className="text-center"> {/* Aplicamos la clase text-center aquí */}
            Estado
          </div>
        ),
        filterable: false,
        disableFilters: true,
        accessor: "state",
        Cell: (product) => (
          <div className="text-center">
            <span className="font-size-14 mb-1">
              <Badge color={showColorState(product?.cell?.row.original.state_value)}>
                {product?.cell.row.original.state}
              </Badge>
            </span>
          </div>
        )
      },
      {
        Header: () => ( // Utilizamos una función para personalizar el encabezado
          <div className="text-center"> {/* Aplicamos la clase text-center aquí */}
            Acción
          </div>
        ),
        filterable: false,
        disableFilters: true,
        accessor: "accion",
        Cell: (product) => (
          <div className="text-center">
            <CustomOptions 
              componenteType = 'ListProduct'
              item={product.cell.row.original}
              handleOnEdit={handleOnEdit}
              handleEnabledDisabled={handleEnabledDisabled}
            />
          </div>
        )
      },
    ],
    []
  );
  const showColorState = (value) => {
    return value ? `success` : 'danger';
  }
  return (
    <Fragment>
      <Row>
        <Col lg="12">
          <Row className="align-items-center">
            <Col md={6}>
            </Col>
            <Col md={6}>
              <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                <AddNewItem 
                  title="Nuevo vehículo"
                  handleOnClick={handleOnClickProductNew}
                />
                <ExportImportExcel />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl="12" className="mb-4">
              <TableContainer
                columns={columns}
                data={rows}
                isGlobalFilter={true}
                isAddUserList={true}
                customPageSize={10}
                className="table align-middle datatable dt-responsive table-check nowrap"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Fragment>
  );
};

export default List;
