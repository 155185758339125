import React from 'react';
import { Offcanvas, OffcanvasHeader, OffcanvasBody } from 'reactstrap'; 
import { ButtonFormControl, InputFormControl, SelectedFormControl } from '../molecules';
import SaveIcon from '@mui/icons-material/Save';
import { CART, VEHICLE } from '../../config/config';

function FormCreateModalNew ({ 
  isOpen, 
  toggle,
  onClickSave,
  onChange,
  titleHeader = '',
  type = '',
  onHandleChangeSelectBrandInsert,
  brands = [],
  onChangeBrand
}) {
  function RenderFormCreate() {
    switch (type) {
      case VEHICLE:
        return(
          <div className='row'>
            <div className='col-md-12'>
              <InputFormControl
                type={'text'}
                label={'Placa:'}
                nameInput={'license'}
                onHandleChange={onChange}
              />
            </div>
            <div className='col-md-12'>
              {/* <SelectedFormControl
                label={'Marca'}
                placeholder={'Seleccione una marca...'}
                options={brands}
                onHandleChange={(selectedOption) => onHandleChangeSelectBrandInsert(selectedOption, 'selectValueBrand')}
              /> */}
              <InputFormControl
                type={'text'}
                label={'Marca:'}
                nameInput={'brand'}
                onHandleChange={onChangeBrand}
              />
            </div>
            <div className='col-md-12 mt-3'>
              <ButtonFormControl
                  text={
                    <><SaveIcon />&nbsp;Guardar</>
                  }
                  OnHandleClick={onClickSave}
                  className="btn btn-success "
                />
            </div>
          </div>
        )
      case CART:
        return(
          <div className='row'>
            <div className='col-md-12'>
              <InputFormControl
                type={'text'}
                label={'Placa de la carreta:'}
                nameInput={'license_cart'}
                onHandleChange={onChange}
              />
            </div>
            <div className='col-md-12 mt-3'>
              <ButtonFormControl
                  text={
                    <><SaveIcon />&nbsp;Guardar</>
                  }
                  OnHandleClick={onClickSave}
                  className="btn btn-success "
                />
            </div>
          </div>
        )
      case 'DRIVER':
        return(
          <div className='row'>
            <div className='col-md-12'>
              <InputFormControl
                type={'text'}
                label={'Nombres completos:'}
                nameInput={'names'}
                onHandleChange={onChange}
              />
            </div>
            <div className='col-md-12'>
              <InputFormControl
                type={'text'}
                label={'Apellidos completos:'}
                nameInput={'lastNames'}
                onHandleChange={onChange}
              />
            </div>
            <div className='col-md-6'>
              <InputFormControl
                type={'text'}
                label={'Nro documento:'}
                nameInput={'documentNumber'}
                onHandleChange={onChange}
              />
            </div>
            <div className='col-md-6'>
              <InputFormControl
                type={'text'}
                label={'Nro Licencia:'}
                nameInput={'licenseCode'}
                onHandleChange={onChange}
              />
            </div>
            <div className='col-md-12 mt-3'>
              <ButtonFormControl
                  text={
                    <><SaveIcon />&nbsp;Guardar</>
                  }
                  OnHandleClick={onClickSave}
                  className="btn btn-success "
                />
            </div>
          </div>
        )
      default:
        break;
    }
  }
  return (
    <Offcanvas isOpen={isOpen} direction="end" toggle={toggle}>
      <OffcanvasHeader toggle={toggle}>
        {titleHeader}
      </OffcanvasHeader>
      <OffcanvasBody>
        {
          RenderFormCreate()
        }
      </OffcanvasBody>
    </Offcanvas>
  );
};

export default FormCreateModalNew;
