import { useEffect, useState } from "react";
import { ServiceGetAllEntryNote, ServiceGetAllExitNote } from "../service/ServiceMovements";

export function useGetAllExitNote() {
  const [lstExitNote, setExitNote] = useState([]);
  useEffect(() => {
    async function getAllExitNote() {
      let data = await ServiceGetAllExitNote();
      setExitNote(data.data);
    }
    getAllExitNote();
  }, []);
  return {lstExitNote, setExitNote}
}
export function useGetAllEntryNote() {
  const [lstEntryNote, setEntryNote] = useState([]);
  useEffect(() => {
    async function getAllEntryNote() {
      let data = await ServiceGetAllEntryNote();
      setEntryNote(data.data);
    }
    getAllEntryNote();
  }, []);
  return {lstEntryNote, setEntryNote}
}

 