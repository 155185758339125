import React, { useEffect, useState, useRef, useMemo, Fragment } from "react";
import { Link } from "react-router-dom";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Col,
  Row,

} from "reactstrap";
import { Badge } from "reactstrap";
import { AddNewItem, CustomOptions, ExportImportExcel } from "../../organism";
import { formatDecimal } from "../../../helpers/general_functions";

const List = ({
  rows = []
  , handleOnClickProductNew
  , handleOnEdit
  , handleEnabledDisabled
}) => {
  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
      {
        Header: "Código",
        filterable: false,
        disableFilters: true,
        accessor: "codigo",
        Cell: (product) => (
          <>
            <span className="font-size-14 mb-1">
              <Link to="#" className="text-dark">
                {product?.cell.row.original.codigo}
              </Link>
            </span>
          </>
        ),
      },
      {
        Header: "Nombre del producto",
        filterable: false,
        disableFilters: true,
        accessor: "producto_descripcion",
        Cell: (product) => (
          <>
            <span className="font-size-14 mb-1">
              <Link to="#" className="text-dark">
                {product?.cell.row.original.producto_descripcion}
              </Link>
            </span>
          </>
        ),
      },
      {
        Header: () => ( // Utilizamos una función para personalizar el encabezado
          <div className="text-center"> {/* Aplicamos la clase text-center aquí */}
            Categoría
          </div>
        ),
        filterable: false,
        disableFilters: true,
        accessor: "categoria",
        Cell: (product) => (
          <div className="text-center">
            <span className="font-size-14 mb-1">
              <Link to="#" className="text-dark">
                {product?.cell.value}
              </Link>
            </span>
          </div>
        ),
      },
      {
        Header: () => ( // Utilizamos una función para personalizar el encabezado
          <div className="text-center"> {/* Aplicamos la clase text-center aquí */}
            S/. Precio de Venta
          </div>
        ),
        filterable: false,
        disableFilters: true,
        accessor: "precio_venta",
        Cell: (product) => (
          <div className="text-center">
            <span className="font-size-14 mb-1">
              <Link to="#" className="text-dark">
                {formatDecimal(product?.cell.value)}
              </Link>
            </span>
          </div>
        ),
      },
      {
        Header: () => ( // Utilizamos una función para personalizar el encabezado
          <div className="text-center"> {/* Aplicamos la clase text-center aquí */}
            Cantidad
          </div>
        ),
        filterable: false,
        disableFilters: true,
        accessor: "stock_venta",
        Cell: (product) => (
          <div className="text-center">
            <span className="font-size-14 mb-1">
              <Link to="#" className="text-dark">
                {product?.cell.value} {product?.cell.row.original.presentacion.toLowerCase()}
              </Link>
            </span>
          </div>
        ),
      },
      {
        Header: () => ( // Utilizamos una función para personalizar el encabezado
          <div className="text-center"> {/* Aplicamos la clase text-center aquí */}
            Estado
          </div>
        ),
        filterable: false,
        disableFilters: true,
        accessor: "estado",
        Cell: (product) => (
          <div className="text-center">
            <span className="font-size-14 mb-1">
              <Badge color={showColorState(product?.cell?.row.original.valor_estado)}>
                {product?.cell.row.original.estado}
              </Badge>
            </span>
          </div>
        )
      },
      {
        Header: () => ( // Utilizamos una función para personalizar el encabezado
          <div className="text-center"> {/* Aplicamos la clase text-center aquí */}
            Acción
          </div>
        ),
        filterable: false,
        disableFilters: true,
        accessor: "accion",
        Cell: (product) => (
          <div className="text-center">
            <CustomOptions 
              componenteType = 'ListProduct'
              item={product.cell.row.original}
              handleOnEdit={handleOnEdit}
              handleEnabledDisabled={handleEnabledDisabled}
            />
          </div>
        )
      },
    ],
    []
  );
  const showColorState = (value) => {
    return value ? `success` : 'danger';
  }
  return (
    <Fragment>
      <Row>
        <Col lg="12">
          <Row className="align-items-center">
            <Col md={6}>
            </Col>
            <Col md={6}>
              <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                <AddNewItem 
                  title="Nuevo Producto"
                  handleOnClick={handleOnClickProductNew}
                />
                <ExportImportExcel />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl="12">
              <TableContainer
                columns={columns}
                data={rows}
                isGlobalFilter={true}
                isAddUserList={true}
                customPageSize={10}
                className="table align-middle datatable dt-responsive table-check nowrap"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Fragment>
  );
};

export default List;
