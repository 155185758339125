import { useEffect, useState } from "react";
import { ServiceGetAllPresentation } from "../service/presentation-service";

export function useGetAllPresentation() {
  const [presentations, setPresentation] = useState([]);
  useEffect(() => {
    async function getAllPresentation() {
      let data = await ServiceGetAllPresentation();
      setPresentation(data.data);
    }
    getAllPresentation();
  }, []);
  return {presentations, setPresentation}
}