import React, { useState } from "react";
import { Container } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import List from './List';
import { getTitle } from "../../../helpers/general_functions";
import { useGetAllExitNote } from "../../../hooks/";
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";
import { Modal, SendEmailVouchers } from "../../organism";
import { ServiceSendEmail } from "../../../service/common-service";
import { ServiceAnulaMovementExitNote, ServiceGetAllExitNote } from "../../../service/ServiceMovements";

export default function Manager() {
  
  getTitle('Lista general de Notas de Salida');

  const { lstExitNote, setExitNote } = useGetAllExitNote();
  const [objComprobante, setObjComprobante] = useState({});
  const [openModalSendEmail, setOpenModalSendEmail] = useState(false);
  const [formSend, setFormSend] = useState({
    emailCustomer: '',
    emailDriver: ''
  })
  const handleDownloadPDF = (e, row) => {
    
  }
  const handleSendEmail = (e, item) => {
    setOpenModalSendEmail(true);
    setObjComprobante(item);
  }
  async function getAllExitNoteRefresh() {
    let data = await ServiceGetAllExitNote();
    setExitNote(data.data);
  }
  const handleOnCloseSendEmail = (e) => {
    setOpenModalSendEmail(false);
  }
  const handleOnClickSendEmail = async(_) => {
    const {
      pdf,
      serie,
      number,
      customer
    } = objComprobante;
    const {
      emailCustomer,
      emailDriver,
    } = formSend;
    let sendData = {
      customer_email: emailCustomer === '' ? objComprobante.email_customer: emailCustomer,
      driver_email: emailDriver === '' ? objComprobante.email_driver: emailDriver,
      pdf,
      serie,
      number,
      customer
    };
    Swal.fire({
      title: `Seguro de enviar el correo a los destinatarios?`,
      text: "Si, estoy seguro!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Si enviar`,
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        sendData = JSON.stringify(sendData);
        const send = await ServiceSendEmail(sendData);
        if (send.status === 200) {
          Swal.fire({
            title: `Envío exitoso`,
            text: `Se ha enviado el comprobante de nota de salida a los destinatarios.`,
            icon: "success"
          });
          handleOnCloseSendEmail();
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `${send.error}`,
          });
        }
      }
    });
  }
  const handleOnChangeSend = (e) => {
    const { name, value } = e.target;
    setFormSend({
      ...formSend,
      [name]: value,
    });
  }
  const handleAnular = (e, row) => {
    const {
      movement_id
    } = row
    const data = {
      movement_id: parseInt(movement_id),
      type: 'NS'
    }
    Swal.fire({
      title: `Seguro de anular el comprobante?`,
      text: "Si, estoy seguro!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Aceptar`,
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const update = await ServiceAnulaMovementExitNote(data);
        if (update.status === 200) {
          Swal.fire({
            title: `Anulación exitosa`,
            text: `Se ha anulado el comprobante correctamente.`,
            icon: "success"
          });
          await getAllExitNoteRefresh()
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `${update.error}`,
          });
        }
      }
    });
  }
  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Tesorería" breadcrumbItem="Listado de Notas de Salida" />
        <List
          rows={lstExitNote}
          handleDownloadPDF={handleDownloadPDF}
          handleSendEmail={handleSendEmail}
          handleAnular={handleAnular}
        />
         {
        openModalSendEmail && (
          <Modal
            title={`Enviar por correo electrónico`}
            size='modal-lg'
            onClose={handleOnCloseSendEmail}
          >
            <SendEmailVouchers
              item={objComprobante}
              type="NOTA_SALIDA"
              onClose={handleOnCloseSendEmail}
              onSendEmail={handleOnClickSendEmail}
              handleOnChangeSend={handleOnChangeSend}
            />
          </Modal>
        )}
      </Container>
    </div>
  )
}