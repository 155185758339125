import { useEffect, useState } from "react";
import { GetAllCompaniesService } from "../service/company-service";

export default function useGetAllCompanies() {
  const [companies, setCompanies] = useState([]);
  useEffect(() => {
    async function getAllCompanies() {
      let data = await GetAllCompaniesService();
      setCompanies(data.data);
    }
    getAllCompanies();
  }, []);
  return {companies}
}