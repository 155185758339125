import axios from "axios"
import accessToken from "./jwt-token-access/accessToken"

//pass new generated access token here
const token = accessToken

//apply base url for axios
const API_URL = ""

const axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.defaults.headers.common["Authorization"] = token

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get(url, config = {}) {
  try {
    const response = await axiosApi.get(url, config);
    return response.data;
  } catch (error) {
    if (error.response) {
      return { status: error.response.status, error: error.response.data.errors.message };
    } else {
      throw error;
    }
  }
}
export async function getReniec(documentNumber, token, isDniRuc, urlApi) {
  try {
    let endpoint = `${urlApi}/${isDniRuc}`;
    let res = null;
    if(isDniRuc === 'ruc') {
      res = await fetch(endpoint, {
        method: 'POST',
        contentType: 'application/json',
        body: JSON.stringify({token: token, ruc: documentNumber}),
        headers: {
          'Accept': 'application/json',
          'content-type': 'application/json',
        }
      })
    } else {
      res = await fetch(endpoint, {
        method: 'POST',
        contentType: 'application/json',
        body: JSON.stringify({token: token, dni: documentNumber}),
        headers: {
          'Accept': 'application/json',
          'content-type': 'application/json',
        }
      })
    }
    return await res.json()
  } catch (error) {
    if (error.response) {
      return { status: error.response.status, error: error.response.data.errors.message };
    } else {
      throw error;
    }
  }
}

export async function post(url, data, config = {}) {
  try {
    const response = await axiosApi.post(url, data, config);
    return response.data;
  } catch (error) {
    if (error.response) {
      return { status: error.response.status, error: error.response.data.errors.message };
    } else {
      throw error;
    }
  }
}

export async function put(url, data, config = {}) {
  try {
    const response = await axiosApi.put(url, { ...data }, config);
    return response.data;
  } catch (error) {
    if (error.response) {
      return { status: error.response.status, error: error.response.data.errors.message };
    } else {
      throw error;
    }
  }
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
