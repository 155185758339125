import { Uri_GetAllProducts, Uri_PostNewProduct, Uri_PutDisabledProduct, Uri_PutEditProduct, Uri_PutEnabledProduct } from "../api/product/api-product";
import { get, post, put } from "../helpers/api_helper";

export async function ServiceGetAllProducts() {
  return await get(Uri_GetAllProducts);
}
export async function ServicePostNewProduct(data) {
  return await post(Uri_PostNewProduct, data);
}
export async function ServicePutEditProduct(data) {
  return await put(Uri_PutEditProduct, data);
}
export async function ServicePutEnabledProduct(product_id) {
  return await put(Uri_PutEnabledProduct(product_id));
}
export async function ServicePutDisabledProduct(product_id) {
  return await put(Uri_PutDisabledProduct(product_id));
}