import { Uri_GetAllCustomer, Uri_GetAllDrivers, Uri_GetAllEmployeed, Uri_GetBasedDocumentNumber, Uri_PostCreateCustomer, Uri_PostCreateDriver, Uri_PostCreateEmployed, Uri_PutAuthentication, Uri_PutCreateDriver, Uri_PutDisabledCustomer, Uri_PutDisabledDriver, Uri_PutDisabledEmployeed, Uri_PutEnabledCustomer, Uri_PutEnabledDriver, Uri_PutEnabledEmployeed } from "../api/user/api-user";
import { get, post, put } from "../helpers/api_helper";

export async function GetAllEmployeed() {
  return await get(Uri_GetAllEmployeed);
}
export async function ServiceGetAllDrivers() {
  return await get(Uri_GetAllDrivers);
}
export async function ServiceGetBasedDocumentNumber(documentNumber, roleId) {
  return await get(Uri_GetBasedDocumentNumber(documentNumber, roleId));
}
export async function ServiceGetAllCustomer() {
  return await get(Uri_GetAllCustomer);
}
export async function ServicePostCreateDriver(data) {
  return await post(Uri_PostCreateDriver, data);
}
export async function ServicePutAuthentication(data) {
  return await post(Uri_PutAuthentication, data);
}
export async function ServicePostCreateEmployed(data) {
  return await post(Uri_PostCreateEmployed, data);
}
export async function ServicePostCreateCustomer(data) {
  return await post(Uri_PostCreateCustomer, data);
}
export async function ServicePutCreateDriver(data) {
  return await put(Uri_PutCreateDriver, data);
}
export async function ServiceDisabledDriver(driverId) {
  return await put(Uri_PutDisabledDriver(driverId));
}
export async function ServiceEnabledDriver(driverId) {
  return await put(Uri_PutEnabledDriver(driverId));
}
export async function ServiceDisabledEmployeed(user_id) {
  return await put(Uri_PutDisabledEmployeed(user_id));
}
export async function ServicePutEnabledEmployeed(user_id) {
  return await put(Uri_PutEnabledEmployeed(user_id));
}
export async function ServiceDisabledCustomer(user_id) {
  return await put(Uri_PutDisabledCustomer(user_id));
}
export async function ServicePutEnabledCustomer(user_id) {
  return await put(Uri_PutEnabledCustomer(user_id));
}