
import PropTypes from 'prop-types';

function ConditionalRender ({
  condition,
  children,
  elseContent 
}) {
  return condition? children: elseContent;
}
ConditionalRender.propTypes = {
  condition: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired
};
ConditionalRender.defaultProps = {
  elseContent: null
};
export default ConditionalRender ;
