import { URL_BACKEND } from "../../config/config";

export const Uri_GetAllEmployeed = `${URL_BACKEND}controller=user&operation=getAllEmployeed`;
export const Uri_GetAllDrivers = `${URL_BACKEND}controller=user&operation=getAllDrivers`;
export const Uri_GetAllCustomer = `${URL_BACKEND}controller=user&operation=getAllCustomer`;
export const Uri_PutEnabledCustomer = (user_id) => `${URL_BACKEND}controller=user&operation=enabledCustomer&user_id=${user_id}`;
export const Uri_PutDisabledCustomer= (user_id) => `${URL_BACKEND}controller=user&operation=disabledCustomer&user_id=${user_id}`;
export const Uri_PutEnabledEmployeed = (user_id) => `${URL_BACKEND}controller=user&operation=enabledEmployeed&user_id=${user_id}`;
export const Uri_PutDisabledEmployeed = (user_id) => `${URL_BACKEND}controller=user&operation=disabledEmployeed&user_id=${user_id}`;
export const Uri_PutEnabledDriver = (driver_id) => `${URL_BACKEND}controller=user&operation=enabledDriver&driver_id=${driver_id}`;
export const Uri_PutDisabledDriver = (driver_id) => `${URL_BACKEND}controller=user&operation=disabledDriver&driver_id=${driver_id}`;
export const Uri_GetBasedDocumentNumber = (document_number, role_id) => `${URL_BACKEND}controller=user&operation=getBasedDocumentNumber&document_number=${document_number}&role_id=${role_id}`;
export const Uri_PostCreateDriver = `${URL_BACKEND}controller=user&operation=createDriver`;
export const Uri_PostCreateEmployed = `${URL_BACKEND}controller=user&operation=createEmployeed`;
export const Uri_PostCreateCustomer = `${URL_BACKEND}controller=user&operation=createCustomer`;
export const Uri_PutCreateDriver = `${URL_BACKEND}controller=user&operation=updateDriver`;
export const Uri_PutAuthentication= `${URL_BACKEND}controller=user&operation=authentication`;
