import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import LogoutIcon from '@mui/icons-material/Logout';
// users
import user1 from "../../../assets/images/users/user-dummy-img.jpg";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { getTitle } from "../../../helpers/general_functions";

const ProfileMenu = props => {
  const [menu, setMenu] = useState(false);
  const [username, setusername] = useState("Admin");
  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))[0];
      setusername(obj.username);
    }
  }, []);
  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item bg-soft-light border-start border-end"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={user1}
            height={'84px'}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-1 fw-medium">{username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">

          <Link to={`/${username.toLocaleLowerCase().split(" ").join('-')}`} className="dropdown-item">
            <AccountCircleIcon />{" "}
            Perfil{" "}
          </Link>{" "}
          <div className="dropdown-divider" />
          <Link to="/login" className="dropdown-item">
          <LogoutIcon />{" "}
            <span>Cerrar sesión</span>
          </Link>
          
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
};

const mapStatetoProps = state => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu)
  );