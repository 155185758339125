import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import TableContainer from "../../../components/Common/TableContainer";

import {
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Label,
  Input,
  FormFeedback,
  UncontrolledTooltip,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavLink,
} from "reactstrap";

import * as Yup from "yup";
import { useFormik } from "formik";

import { Email, Tags, Projects, Img } from "./Column";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import DeleteModal from "../../../components/Common/DeleteModal";

import {
  getUsers as onGetUsers,
  addNewUser as onAddNewUser,
  updateUser as onUpdateUser,
  deleteUser as onDeleteUser,
} from "../../../store/actions";

import { isEmpty } from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { Badge } from "reactstrap";
import { AddNewItem, CustomOptions, ExportImportExcel } from "../../organism";

const List = ({
  rows = [],
  handlePresentationNew,
  handleOnEdit,
  handleEnabledDisabled
}) => {
  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleToggle = () => {
    setMenuOpen(!menuOpen);
  };
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (contact && contact.name) || "",
      designation: (contact && contact.designation) || "",
      tags: (contact && contact.tags) || "",
      email: (contact && contact.email) || "",
      projects: (contact && contact.projects) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Your Name"),
      designation: Yup.string().required("Please Enter Your Designation"),
      tags: Yup.array().required("Please Enter Tag"),
      email: Yup.string().required("Please Enter Your Email"),
      projects: Yup.number().required("Please Enter Your Project"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateUser = {
          id: contact.id,
          name: values.name,
          designation: values.designation,
          tags: values.tags,
          email: values.email,
          projects: values.projects,
        };

        // update user
        dispatch(onUpdateUser(updateUser));
        validation.resetForm();
        setIsEdit(false);
      } else {
        const newUser = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          name: values["name"],
          designation: values["designation"],
          email: values["email"],
          tags: values["tags"],
          projects: values["projects"],
        };
        // save new user
        dispatch(onAddNewUser(newUser));
        validation.resetForm();
      }
      toggle();
    },
  });

  const contactlistData = createSelector(

    (state) => state.contacts,
    (state) => ({
      users: state.users,
    })
  );
  const { users } = useSelector(contactlistData);
  
  const columns = useMemo(
    () => [
      {
        Header: "Nombre",
        filterable: false,
        disableFilters: true,
        accessor: "producto_descripcion",
        Cell: (product) => (
          <>
            <span className="font-size-14 mb-1">
              <Link to="#" className="text-dark">
                {product?.cell.row.original.names}
              </Link>
            </span>
          </>
        ),
      },
      {
        Header: () => ( // Utilizamos una función para personalizar el encabezado
          <div className="text-center"> {/* Aplicamos la clase text-center aquí */}
            Abreviatura
          </div>
        ),
        filterable: false,
        disableFilters: true,
        accessor: "abbreviature",
        Cell: (product) => (
          <div className="text-center">
            <span className="font-size-14 mb-1">
              <Link to="#" className="text-dark">
                {product?.cell.value}
              </Link>
            </span>
          </div>
        ),
      },
      {
        Header: () => ( // Utilizamos una función para personalizar el encabezado
          <div className="text-center"> {/* Aplicamos la clase text-center aquí */}
            Estado
          </div>
        ),
        filterable: false,
        disableFilters: true,
        accessor: "state",
        Cell: (product) => (
          <div className="text-center">
            <span className="font-size-14 mb-1">
              <Badge color={showColorState(product?.cell?.row.original.state_value)}>
                {product?.cell.row.original.state}
              </Badge>
            </span>
          </div>
        )
      },
      {
        Header: () => ( // Utilizamos una función para personalizar el encabezado
          <div className="text-center"> {/* Aplicamos la clase text-center aquí */}
            Acción
          </div>
        ),
        filterable: false,
        disableFilters: true,
        accessor: "accion",
        Cell: (presentation) => (
          <div className="text-center">
            <CustomOptions 
              componenteType = 'ListPresentation'
              item={presentation.cell.row.original}
              handleOnEdit={handleOnEdit}
              handleEnabledDisabled={handleEnabledDisabled}
            />
          </div>
        )
      },
    ],
    []
  );
  const showColorState = (value) => {
    return value ? `success` : 'danger';
  }

  useEffect(() => {
    if (users && !users.length) {
      dispatch(onGetUsers());
      setIsEdit(false);
    }
  }, [dispatch, users]);

  useEffect(() => {
    setContact(users);
    setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };

  const handleUserClick = (arg) => {
    const user = arg;

    setContact({
      id: user.id,
      name: user.name,
      designation: user.designation,
      email: user.email,
      tags: user.tags,
      projects: user.projects,
    });
    setIsEdit(true);

    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = (page) => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };
  return (
    <React.Fragment>
      <Row>
        <Col lg="12">
          <Row className="align-items-center">
            <Col md={6}>
            </Col>
            <Col md={6}>
              <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                <AddNewItem 
                  title="Nueva Presentación"
                  handleOnClick={handlePresentationNew}
                />
                <ExportImportExcel />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl="12" className="mb-4">
              <TableContainer
                columns={columns}
                data={rows}
                isGlobalFilter={true}
                isAddUserList={true}
                customPageSize={10}
                className="table align-middle datatable dt-responsive table-check nowrap"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default List;
