import React, { useState } from "react";
import { Container } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import List from './List';
import { getTitle } from "../../../helpers/general_functions";
import { useGetAllPresentation } from "../../../hooks";
import { Modal } from "../../organism";
import FormCreate from "./FormCreate";
import { ServiceGetAllPresentation, ServicePostNewPresentation, ServicePutDisabledPresentation, ServicePutEnabledPresentation, ServicePutPresentation } from "../../../service/presentation-service";
import Swal from 'sweetalert2';
import FormUpdate from './FormUpdate';

export default function Manager() {
  
  getTitle('Presentación');

  const {presentations, setPresentation} = useGetAllPresentation();
  const [openModalNew, setOpenModalNew] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [objPresentationEdit, setObjPresentationEdit] = useState({});
  const [formValues, setFormValues] = useState({
    names: '',
    abbreviature: ''
  })

  const handlePresentationNew = (_) => {
    setOpenModalNew(true);
  }
  const handleOnClosePresentationModal = (_) => {
    setOpenModalNew(false);
  }
  const handleOnChangeInsert = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  }
  const handleOnClickSavePresentation = (e) => {

    if(!formValues.names) {
      return;
    }

    const dataInsert = {
      name: formValues.names,
      abbreviature: formValues.abbreviature
    }

    Swal.fire({
      title: "Seguro de guardar la presentación?",
      text: "Si, estoy seguro!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, guardar",
      cancelButtonText: 'Cancelar'
    }).then(async(result) => {
      if (result.isConfirmed) {
        let insert = await ServicePostNewPresentation(dataInsert);
        if(insert.status === 200) {
          Swal.fire({
            title: `${insert.data.message}`,
            text: `Se ha registrado la presentación.`,
            icon: "success"
          });
          await handleGetAllPresentations();
          handleClearControls();
        } else {
          Swal.fire({
            icon: "error",
            title: "Advertencia",
            text: `${insert.error}`,
          });
        }
      }
    });
  }
  const handleOnClickSaveEditPresentation = (_) => {

    const dateUpdate = {
      name: formValues.names === '' || formValues.names === undefined || formValues.names === null ? objPresentationEdit.names: formValues.names,
      abbreviature: formValues.abbreviature === '' || formValues.abbreviature === undefined || formValues.abbreviature === null ? objPresentationEdit.abbreviature: formValues.abbreviature,
      presentation_id: parseInt(objPresentationEdit.id)
    }

    Swal.fire({
      title: "Seguro de editar la presentación?",
      text: "Si, estoy seguro!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, editar",
      cancelButtonText: 'Cancelar'
    }).then(async(result) => {
      if (result.isConfirmed) {
        let insert = await ServicePutPresentation(dateUpdate);
        if(insert.status === 200) {
          Swal.fire({
            title: `${insert.data.message}`,
            text: `Se ha actualizado la presentación.`,
            icon: "success"
          });
          await handleGetAllPresentations();
          handleClearControls();
        } else {
          Swal.fire({
            icon: "error",
            title: "Advertencia",
            text: `${insert.error}`,
          });
        }
      }
    });
  }
  const handleGetAllPresentations = async(e) => {
    let data = await ServiceGetAllPresentation();
    setPresentation(data.data);
  }
  const handleClearControls = (e) => {
    setOpenModalNew(false);
    setFormValues({});
    if(openModalEdit) {
      setOpenModalEdit(false);
    }
  }
  const handleOnEdit = (e, item) => {
    setOpenModalEdit(true);
    setObjPresentationEdit(item);
  }
  const handleOnClosePresentationModalEdit = (e) => {
    setOpenModalEdit(false);
  }
  const handleEnabledDisabled = (_, item) => {
    const {
      id,
      state_value,
      names,
    } = item;
    Swal.fire({
      title: `Seguro de ${state_value ? 'inactivar' : 'activar'} la presentación?`,
      text: "Si, estoy seguro!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Si, ${state_value ? 'inactivar' : 'activar'}`,
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = false;
        if (!state_value) {
          response = await ServicePutEnabledPresentation(id);
        } else {
          response = await ServicePutDisabledPresentation(id);
        }
        if (response.status === 200) {
          Swal.fire({
            title: `${response.data.message}`,
            text: `Se ha ${state_value ? 'inactivado' : 'activado'} la presentación - ${names}.`,
            icon: "success"
          });
          await handleGetAllPresentations();
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `${insert.error}`,
          });
        }
      }
    });
  }
  return ( 
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Mantenimiento" breadcrumbItem="Presentación" />
      </Container>
      <List 
        rows={presentations}
        handlePresentationNew={handlePresentationNew}
        handleOnEdit={handleOnEdit}
        handleEnabledDisabled={handleEnabledDisabled}
      />
      {
        openModalNew && (
          <Modal
            title={`Nueva presentación`}
            size='modal-lg'
            onClose={handleOnClosePresentationModal}
          >
          <FormCreate 
            handleOnChangeInsert={handleOnChangeInsert}
            handleOnClickSavePresentation={handleOnClickSavePresentation}
            handleOnClosePresentationModal={handleOnClosePresentationModal}
          />
          </Modal>
      )}
      {
        openModalEdit && (
          <Modal
            title={`Editar presentación`}
            size='modal-lg'
            onClose={handleOnClosePresentationModalEdit}
          >
          <FormUpdate 
            handleOnChangeInsert={handleOnChangeInsert}
            handleOnClosePresentationModalEdit={handleOnClosePresentationModalEdit}
            handleOnClickSaveEditPresentation={handleOnClickSaveEditPresentation}
            item={objPresentationEdit}
          />
          </Modal>
      )}
    </div>
  )
}