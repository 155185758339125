import React, { useEffect, useState, useRef, useMemo, Fragment } from "react";
import { Link } from "react-router-dom";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Col,
  Row,

} from "reactstrap";
import { Badge } from "reactstrap";
import { AddNewItem, CustomOptions, ExportImportExcel } from "../../organism";
import { formatDecimal } from "../../../helpers/general_functions";

const List = ({
  rows = []
  , handleOnClickCustomerNew
  , handleOnEdit
  , handleEnabledDisabled
}) => {
  const columns = useMemo(
    () => [
      {
        Header: "Nombres completos",
        filterable: false,
        disableFilters: true,
        accessor: "names",
        Cell: (user) => (
          <>
            <span className="font-size-14 mb-1">
              <Link to="#" className="text-dark">
                {
                  user?.cell.row.original.tipo_documento_id === 1 ?
                  <span>{user?.cell.row.original.names}, {user?.cell.row.original.last_names}</span>
                  :
                  <span>{user?.cell.row.original.names}</span>
                } 
              </Link>
            </span>
          </>
        ),
      },
      {
        Header: "Celular",
        filterable: false,
        disableFilters: true,
        accessor: "cellphone",
        Cell: (user) => (
          <>
            <span className="font-size-14 mb-1">
              <Link to="#" className="text-dark">
                {user?.cell.row.original.cellphone}
              </Link>
            </span>
          </>
        ),
      },
      {
        Header: () => ( // Utilizamos una función para personalizar el encabezado
          <div className=""> {/* Aplicamos la clase text-center aquí */}
            Numero de Documento
          </div>
        ),
        filterable: false,
        disableFilters: true,
        accessor: "type_document",
        Cell: (user) => (
          <div className="">
            <span className="font-size-14 mb-1">
              <Link to="#" className="text-dark">
                {user?.cell.row.original.type_document} - {user?.cell.row.original.number_document}
              </Link>
            </span>
          </div>
        ),
      },
      {
        Header: () => ( // Utilizamos una función para personalizar el encabezado
          <div className="text-center"> {/* Aplicamos la clase text-center aquí */}
            Email
          </div>
        ),
        filterable: false,
        disableFilters: true,
        accessor: "email",
        Cell: (user) => (
          <div className="text-center">
            <span className="font-size-14 mb-1">
              <Link to="#" className="text-dark">
                {user?.cell.row.original.emails[0]?.email}
              </Link>
            </span>
          </div>
        ),
      },
      {
        Header: () => ( // Utilizamos una función para personalizar el encabezado
          <div className="text-center"> {/* Aplicamos la clase text-center aquí */}
            Estado
          </div>
        ),
        filterable: false,
        disableFilters: true,
        accessor: "estado",
        Cell: (product) => (
          <div className="text-center">
            <span className="font-size-14 mb-1">
              <Badge color={showColorState(product?.cell?.row.original.state_value)}>
                {product?.cell.row.original.state}
              </Badge>
            </span>
          </div>
        )
      },
      {
        Header: () => ( // Utilizamos una función para personalizar el encabezado
          <div className="text-center"> {/* Aplicamos la clase text-center aquí */}
            Acción
          </div>
        ),
        filterable: false,
        disableFilters: true,
        accessor: "accion",
        Cell: (product) => (
          <div className="text-center">
            <CustomOptions 
              componenteType = 'ListProduct'
              item={product.cell.row.original}
              handleOnEdit={handleOnEdit}
              handleEnabledDisabled={handleEnabledDisabled}
            />
          </div>
        )
      },
    ],
    []
  );
  const showColorState = (value) => {
    return value ? `success` : 'danger';
  }
  return (
    <Fragment>
      <Row>
        <Col lg="12">
          <Row className="align-items-center">
            <Col md={6}>
            </Col>
            <Col md={6}>
              <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                <AddNewItem 
                  title="Nuevo Cliente"
                  handleOnClick={handleOnClickCustomerNew}
                />
                <ExportImportExcel />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl="12">
              <TableContainer
                columns={columns}
                data={rows}
                isGlobalFilter={true}
                isAddUserList={true}
                customPageSize={10}
                className="table align-middle datatable dt-responsive table-check nowrap"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Fragment>
  );
};

export default List;
