import React, { Fragment, useState } from "react";
import { ButtonFormControl, InputFormControl, SelectedFormControl } from "../../molecules";
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import classnames from "classnames"
import ListVehicleAvailable from "./ListVehicleAvailable";
import { formatearFecha } from "../../../helpers/general_functions";

export default function FormUpdate({
  handleOnCloseDriverEdit,
  documentsType = [],
  onHandleChangeInsert,
  onHandleChangeSelectInsert,
  handleOnSaveDriverEdit,
  vehiclesAvailable = [],
  objDriver = {},
  handleOnAssignmentVehicle,
  handleOnDeleteAssignmentVehicle
}) {
  const [activeTab, toggleTab] = useState("1");
  const handleTabChange = (tab) => {
    toggleTab(tab);
    if (tab === "1") {
      setTextBack('Cancelar');
      setTextNext('Siguiente');
    } else if (tab === "2") {
      setTextBack('Atrás');
      setTextNext('Siguiente');
    } else if (tab === "3") {
      setTextBack('Atrás');
      setTextNext('Guardar');
    }
  };
  const [textNext, setTextNext] = useState('Siguiente');
  const [textBack, setTextBack] = useState('Atrás');
  return (
    <div className="container">
      <React.Fragment>
        <Row>
          <Col xl={12} lg={6}>
            <Nav tabs>
              <NavItem>
                <NavLink
                  href="#"
                  className={classnames({
                    active: activeTab === "1",
                  }, "px-3")}
                  onClick={() => handleTabChange("1")}
                >
                  Datos personales
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#"
                  className={classnames({
                    active: activeTab === "2",
                  }, "px-3")}
                  onClick={() => handleTabChange("2")}
                >
                  Datos de la Licencia de Conducir
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#"
                  className={classnames({
                    active: activeTab === "3",
                  }, "px-3")}
                  onClick={() => handleTabChange("3")}
                >
                  Ver/Asignar vehículo
                </NavLink>
              </NavItem>
            </Nav>
              
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <ComponentPersonalInformation
                  documentsType={documentsType}
                  onHandleChangeInsert={onHandleChangeInsert}
                  onHandleChangeSelectInsert={onHandleChangeSelectInsert}
                  objDriver={objDriver}
                />
              </TabPane>
              <TabPane tabId="2">
                <ComponenteDriversLicenseDetails
                  onHandleChangeInsert={onHandleChangeInsert}
                  objDriver={objDriver}
                />
              </TabPane>
              <TabPane tabId="3">
                <ComponentAssignmentVehicleDriver
                  vehiclesAvailable={vehiclesAvailable}
                  objDriver={objDriver}
                  handleOnAssignmentVehicle={handleOnAssignmentVehicle}
                  handleOnDeleteAssignmentVehicle={handleOnDeleteAssignmentVehicle}
                />
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </React.Fragment>
      <div className="row mt-3">
        <div className="col-md-6"></div>
        <div className="col-md-6 d-flex justify-content-end">
          <div className="btn-group">
            <ButtonFormControl
              text={textBack}
              className={'btn btn-secondary btn-lg'}
              OnHandleClick={activeTab === "1" ? handleOnCloseDriverEdit : () => handleTabChange(`${parseInt(activeTab) - 1}`)}

            />&nbsp;&nbsp;
            <ButtonFormControl
              text={textNext}
              className={'btn btn-success btn-lg'}
              OnHandleClick={activeTab === "3" ? handleOnSaveDriverEdit : () => handleTabChange(`${parseInt(activeTab) + 1}`)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
const ComponentAssignmentVehicleDriver = ({
  vehiclesAvailable = [],
  objDriver = {},
  handleOnAssignmentVehicle,
  handleOnDeleteAssignmentVehicle
}) => {
  return(
    <Fragment>
      <div className="row">
        <div className="col-md-12">
          <strong>DETALLE DE LOS DATOS DEL CHOFER</strong>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-md-2">
          <strong>Nombres completos: </strong>
        </div>
        <div className="col-md-10">
          <span>{objDriver.names}, {objDriver.last_names}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-md-2">
          <strong>Licencia de conducir: </strong>
        </div>
        <div className="col-md-10">
          <span>{objDriver.license_code}</span>
        </div>
      </div>
      <ListVehicleAvailable   
        rows={vehiclesAvailable}
        objDriver={objDriver}
        handleOnAssignmentVehicle={handleOnAssignmentVehicle}
        isEdit={true}
        handleOnDeleteAssignmentVehicle={handleOnDeleteAssignmentVehicle}
      />
    </Fragment>
  )
}
const ComponenteDriversLicenseDetails = ({
  onHandleChangeInsert,
  objDriver = {}
}) => {
  return (
    <>
      <div className="row">
        <div className="col-md-4">
          <InputFormControl
            type={'text'}
            label={'Código de Licencia de conducir'}
            onHandleChange={onHandleChangeInsert}
            nameInput={'licenseCode'}
            defaultValue={objDriver.license_code}
          />
        </div>
        <div className="col-md-4">
          <InputFormControl
            type={'date'}
            label={'Fecha de emisión'}
            onHandleChange={onHandleChangeInsert}
            nameInput={'dateOfAdmission'}
            defaultValue={formatearFecha(new Date(objDriver?.date_of_admission))}
          />
        </div>
        <div className="col-md-4">
          <InputFormControl
            type={'date'}
            label={'Fecha de expiración'}
            onHandleChange={onHandleChangeInsert}
            nameInput={'dateExpiration'}
            defaultValue={formatearFecha(new Date(objDriver?.license_expiration_date))}
          />
        </div>
      </div>
    </>
  )
}

const ComponentPersonalInformation = ({
  onHandleChangeInsert,
  documentsType = [],
  onHandleChangeSelectInsert,
  objDriver = {}
}) => {
  return (
    <>
      <div className="row">
        <div className="col-md-4">
          <InputFormControl
            type={'text'}
            label={'Nombre'}
            onHandleChange={onHandleChangeInsert}
            nameInput={'names'}
            defaultValue={objDriver.names}
          />
        </div>
        <div className="col-md-4">
          <InputFormControl
            type={'text'}
            label={'Apellidos'}
            onHandleChange={onHandleChangeInsert}
            nameInput={'lastNames'}
            defaultValue={objDriver.last_names}
          />
        </div>
        <div className="col-md-4">
          <InputFormControl
            type={'email'}
            label={'Email'}
            onHandleChange={onHandleChangeInsert}
            nameInput={'email'}
            defaultValue={objDriver.emails[0]?.email}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <SelectedFormControl
            label={'Tipo de documento'}
            placeholder={'Seleccione...'}
            options={documentsType}
            onHandleChange={(selectedOption) => onHandleChangeSelectInsert(selectedOption, 'selectValueDocumenType')}
            defaultValue={documentsType.filter(doc => parseInt(doc.value) === parseInt(objDriver.document_type_id))}
          />
        </div>
        <div className="col-md-4 mt-2">
          <InputFormControl
            type={'text'}
            label={'Nro de documento'}
            onHandleChange={onHandleChangeInsert}
            nameInput={'nroDocument'}
            defaultValue={objDriver.number_document}
          />
        </div>
        <div className="col-md-4 mt-2">
          <InputFormControl
            type={'date'}
            label={'Fecha de nacimiento'}
            onHandleChange={onHandleChangeInsert}
            nameInput={'birthDate'}
            defaultValue={formatearFecha(new Date(objDriver.birth_date))}
          />
        </div>
      </div>
    </>
  )
}
