import { useEffect, useState } from "react";
import { ServiceGetReportEntryNoteEmit, ServiceGetReportExitNoteEmit } from "../service/ServiceReports";

export function useGetReportExitNoteEmit() {
  const [exitNotesEmits, setExitNotesEmits] = useState([]);
  useEffect(() => {
    async function getReportExitNoteEmit() {
      let data = await ServiceGetReportExitNoteEmit();
      setExitNotesEmits(data.data);
    }
    getReportExitNoteEmit();
  }, []);
  return {exitNotesEmits}
}

export function useGetReportEntryNoteEmit() {
  const [entryNotesEmits, setEntryNotesEmits] = useState([]);
  useEffect(() => {
    async function getReportEntryNoteEmit() {
      let data = await ServiceGetReportEntryNoteEmit();
      setEntryNotesEmits(data.data);
    }
    getReportEntryNoteEmit();
  }, []);
  return {entryNotesEmits}
}

