import React from 'react';
import { useGetAllConfigs } from '../../hooks/common-hook';
 
const Greeting = ({
  title = ''
}) => {
  const { config } = useGetAllConfigs();
  
  let date = new Date();
  let greeting, color;
  let hours = date.getHours();
  if(hours < 12) {
    greeting = `Buenos días, ${config.mensajeBienvenidaDia}`;
    color = `#${config.colorMensajeDia}`;
  } else if(hours >= 12 && hours <= 18) {
    greeting = `Buenas tardes, ${config.mensajeBienvenidaTarde}`;
    color = `#${config.colorMensajeTarde}`;
  } else if(hours >= 18 && hours <= 24) {
    greeting = `Buenas noches, ${config.mensajeBienvenidaNoche}`;
    color = `#${config.colorMensajeNoche}`;
  }
  
  return(
    <div className='animated fadeIn ml-3 mb-3' style={{ borderLeft: 'solid 5px', borderLeftColor: color}}>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-12'>
            <h1 style={{fontSize: '30px'}}>{title}</h1>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <span>{greeting}</span>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Greeting