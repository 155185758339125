// AutoCompleteInput.jsx
import React, { useState } from 'react';
import { FormControl, Dropdown, Button } from 'react-bootstrap';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import { VEHICLE } from '../../config/config';
import CloseIcon from '@mui/icons-material/Close';

const AutoCompleteInput = ({ 
  data = {}, 
  onCreate, 
  titleCreate, 
  placeholder, 
  onKeyPress,
  handleSelectItem,
  showDropdown = false,
  typeDocument,
  onChange,
  type,
  items = [],
  onCloseAutoComplete,
  onClickCreate,
  handleOnClick,
}) => {
  const {
    is_vehicle_assignment
  } = data;
  if(type === 'CUSTOMER') {
    return (
      <div>
        <FormControl
          type="text"
          placeholder={placeholder}
          onChange={onKeyPress}
          className="mr-sm-2"
          maxLength={20}
          onClick={handleOnClick}
        />
        {showDropdown && (
          <Dropdown.Menu show className="w-100 position-absolute">
            <Dropdown.Item className="text-dark" onClick={onCloseAutoComplete}>
              <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <CloseIcon style={{ width: '18px' }} />
                <span className="text-dark">Cerrar</span>
              </div>
            </Dropdown.Item>
            {Object.entries(data).length !== 0 ? (
              <Dropdown.Item onClick={(e) => handleSelectItem(e, data)}>
                {
                  typeDocument === 'dni' ?
                  <div>
                    <PersonIcon /> 
                    {
                      is_vehicle_assignment === 1 || is_vehicle_assignment === 0 ?
                      data.data_user
                      : is_vehicle_assignment === undefined ? data.nombre_completo: ''
                    }
                  </div>:
                  <div>
                    <PersonIcon /> 
                    {
                      is_vehicle_assignment === 1 || is_vehicle_assignment === 0 ?
                      data.data_user
                      : is_vehicle_assignment === undefined ? data.nombre_o_razon_social: ''
                    }
                  </div>
                }
              </Dropdown.Item>
            ) : (
              <Dropdown.Item disabled>Sin resultados</Dropdown.Item>
            )}
            {/* <Dropdown.Item className="text-dark" >
              <AddIcon /> {titleCreate}
            </Dropdown.Item> */}
          </Dropdown.Menu>
        )}
      </div>
    );
  } else if(type === VEHICLE) {
    return (
      <div>
        <FormControl
          type="text"
          placeholder={placeholder}
          onChange={onChange}
          onClick={handleOnClick}
          className="mr-sm-2"
        />
        {showDropdown && (
          <Dropdown.Menu show className="w-100 position-absolute">
            <Dropdown.Item className="text-dark" onClick={onCloseAutoComplete}>
              <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <CloseIcon style={{ width: '18px' }} />
                <span className="text-dark">Cerrar</span>
              </div>
            </Dropdown.Item>
            {items.length !== 0 ? (
              items.map((license) => {
                return(
                  <Dropdown.Item key={license.id} onClick={(e) => handleSelectItem(e, license)}>
                  <span>{license.vehicle_license_plate}</span>
                  </Dropdown.Item>
                  )
                })
            ) : (
              <Dropdown.Item disabled>Sin resultados</Dropdown.Item>
            )}
            <Dropdown.Item className="text-dark" onClick={onClickCreate} >
              <AddIcon /> {titleCreate}
            </Dropdown.Item>
          </Dropdown.Menu>
        )}
      </div>
    );
  }
  else {
    return (
      <div>
        <FormControl
          type="text"
          placeholder={placeholder}
          onChange={onKeyPress}
          className="mr-sm-2"
          // maxLength={8}
          onClick={handleOnClick}
        />
        {showDropdown && (
          <Dropdown.Menu show className="w-100 position-absolute">
            <Dropdown.Item className="text-dark" onClick={onCloseAutoComplete}>
              <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <CloseIcon style={{ width: '18px' }} />
                <span className="text-dark">Cerrar</span>
              </div>
            </Dropdown.Item>
            {Object.entries(data).length !== 0 ? (
              <Dropdown.Item onClick={(e) => handleSelectItem(e, data)}>
                {
                  typeDocument === 'dni' ?
                  <div>
                    <PersonIcon /> 
                    {
                      is_vehicle_assignment === 1 || is_vehicle_assignment === 0 ?
                      data.data_user
                      : is_vehicle_assignment === undefined ? data.nombre_completo: ''
                    }
                  </div>:
                  <div>
                    <PersonIcon /> 
                    {
                      data.nombre_o_razon_social
                    }
                  </div>
                }
              </Dropdown.Item>
            ) : (
              <Dropdown.Item disabled>Sin resultados</Dropdown.Item>
            )}
            {/* <Dropdown.Item className="text-dark" onClick={onClickCreate}>
                <AddIcon /> {titleCreate}
            </Dropdown.Item> */}
          </Dropdown.Menu>
        )}
      </div>
    );
  }
};

export default AutoCompleteInput;
