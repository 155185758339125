
import React from 'react'
import { COLOR_BUTTON_MAB } from '../../helpers/color'

function TextAreaFormControl({ 
  title,
  className,
  rows = 0,
  onChange,
  name = ''
}) {
  return(
    <textarea  
      rows={rows}
      className={className}
      onChange={onChange}
      name={name}
    >
      {title}
    </textarea>
  )
}
export default TextAreaFormControl