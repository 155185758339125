import { Uri_GetAllPresentation, Uri_PostCreatePresentation, Uri_PutDisabledPresentation, Uri_PutEnabledPresentation, Uri_PutUpdatePresentation } from "../api/presentation/api-presentation";
import { get, post, put } from "../helpers/api_helper";

export async function ServiceGetAllPresentation() {
  return await get(Uri_GetAllPresentation);
}
export async function ServicePostNewPresentation(data) {
  return await post(Uri_PostCreatePresentation, data);
}
export async function ServicePutPresentation(data) {
  return await put(Uri_PutUpdatePresentation, data);
}
export async function ServicePutEnabledPresentation(id) {
  return await put(Uri_PutEnabledPresentation(id));
}
export async function ServicePutDisabledPresentation(id) {
  return await put(Uri_PutDisabledPresentation(id));
}
