import { URL_BACKEND } from "../../config/config";

export const Uri_GetAllVehicles = `${URL_BACKEND}controller=vehicle&operation=getAll`;
export const Uri_GetAllVehiclesAvailable = `${URL_BACKEND}controller=vehicle&operation=getAllVehicleAvailable`;
export const Uri_GetValidateAssignment = (vehicle_id) => `${URL_BACKEND}controller=vehicle&operation=validateAssignment&vehicle_id=${vehicle_id}`;
export const Uri_PutDisabledVehicle = (vehicle_id) => `${URL_BACKEND}controller=vehicle&operation=disabledVehicle&vehicle_id=${vehicle_id}`;
export const Uri_PutEnabledVehicle = (vehicle_id) => `${URL_BACKEND}controller=vehicle&operation=enabledVehicle&vehicle_id=${vehicle_id}`;
export const Uri_PostCreateVehicle = `${URL_BACKEND}controller=vehicle&operation=createVehicle`;
export const Uri_PutUpdateVehicle = `${URL_BACKEND}controller=vehicle&operation=updateVehicle`;
export const Uri_GetAllVehicleLicenseByLicense = `${URL_BACKEND}controller=vehicle&operation=getAllVehicleLicenseByLicense`;
export const Uri_GetAllVehicleLicenseCartByLicense = `${URL_BACKEND}controller=vehicle&operation=getAllVehicleLicenseCartByLicense`;
export const Uri_PostVehicleCartNew = `${URL_BACKEND}controller=vehicle&operation=createVehicleCart`;