import React, { useState } from "react";
import { Container } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import List from './List';
import { getTitle } from "../../../helpers/general_functions";
import { Modal } from "../../organism";
import FormCreate from './FormCreate';
import { useGetAllCustomer, useGetAllDocumentType } from "../../../hooks/";
import Swal from 'sweetalert2';
import { ServiceGetAllProducts, ServicePostNewProduct, ServicePutDisabledProduct, ServicePutEditProduct, ServicePutEnabledProduct } from "../../../service/product-service";
import FormUpdate from "./FormUpdate";
import { ServiceDisabledCustomer, ServiceGetAllCustomer, ServicePostCreateCustomer, ServicePutEnabledCustomer } from "../../../service/user-service";

const steps = ['Ingreso de datos personales'];

export default function Manager() {
  getTitle('Clientes');
  const { customers, setCustomers } = useGetAllCustomer();
  const { documentsType } = useGetAllDocumentType();
  const [objEditCustomer, setObjEditCustomer] = useState({});
  const [documentTypeId, setDocumentTypeId] = useState(2);

  const [formValues, setFormValues] = useState({
    names: '',
    lastNames: '',
    documentNumber: '',
    birthDate: '',
    address: '',
    email: '',
    cellphone: ''
  });
  const [openModalCustomerNew, setOpenModalCustomerNew] = useState(false);
  const [openModalCustomerEdit, setOpenModalCustomerEdit] = useState(false);

  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };
  const onHandleChangeInsert = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  }
  const handleNext = (e, type = 'I') => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    if (activeStep + 1 === steps.length) {
      const stateTypeTransaction = type === 'U';
      let data = {};
      if(type === 'I') {
        if(!formValues.names) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `Debe de ingresar los nombres del cliente`,
          });
          return;
        }
        if(!formValues.lastNames) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `Debe de ingresar los apellidos del cliente`,
          });
          return;
        }
        if(!formValues.documentNumber) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `Debe de ingresar el número de documento del cliente`,
          });
          return;
        }
        data = {
          formValues,
          typeTransaction: type,
          documentTypeId
        }
      } else {
        const {
          user_id,
          tipo_documento_id,
          names,
          last_names,
          address,
          cellphone,
          emails,
          number_document,
          fecha_nacimiento
        } = objEditCustomer
        let email = emails[0].email;
        // Validamos que sea DNI
        if(tipo_documento_id === 1) {
          data = {
            formValues: {
              names: formValues.names === undefined || formValues.names === null || formValues.names === '' ? names: formValues.names,
              lastNames: formValues.lastNames === undefined || formValues.lastNames === null || formValues.lastNames === '' ? last_names: formValues.lastNames,
              birthDate: formValues.birthDate === undefined || formValues.birthDate === null || formValues.birthDate === '' ? fecha_nacimiento: formValues.birthDate,
              documentNumber: formValues.documentNumber === undefined || formValues.documentNumber === null || formValues.documentNumber === '' ? number_document: formValues.documentNumber,
              address: formValues.address === undefined || formValues.address === null || formValues.address === '' ? address: formValues.address,
              email: formValues.email === undefined || formValues.email === null || formValues.email === '' ? email: formValues.email,
              cellphone: formValues.cellphone === undefined || formValues.cellphone === null || formValues.cellphone === '' ? cellphone: formValues.cellphone,
              documenTypeId: documentTypeId === 0 || documentTypeId === '' ? tipo_documento_id: documentTypeId,
            },
            user_id,
            typeTransaction: type,
            personType: 'N'
          }
        } else {
          data = {
            formValues: {
              razonSocial: formValues.names === undefined || formValues.names === null || formValues.names === '' ? names: formValues.names,
              address: formValues.address === undefined || formValues.address === null || formValues.address === '' ? address: formValues.address,
              email: formValues.email === undefined || formValues.email === null || formValues.email === '' ? email: formValues.email,
              documentNumber: formValues.documentNumber === undefined || formValues.documentNumber === null || formValues.documentNumber === '' ? number_document: formValues.documentNumber,
              cellphone: formValues.cellphone === undefined || formValues.cellphone === null || formValues.cellphone === '' ? cellphone: formValues.cellphone,
              documenTypeId: documentTypeId === 0 || documentTypeId === '' ? tipo_documento_id: documentTypeId,
            },
            user_id,
            typeTransaction: type,
            personType: 'J'
          }
        }
      }
      Swal.fire({
        title: `Seguro de ${stateTypeTransaction ? 'actualizar': 'guardar'} el registro del nuevo cliente?`,
        text: "Si, estoy seguro!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: `Si ${stateTypeTransaction ? 'actualizar': 'guardar'}`,
        cancelButtonText: 'Cancelar'
      }).then(async (result) => {
        if (result.isConfirmed) {
          data = JSON.stringify(data);
          // setStateButtonSave(!stateButtonSave)
          let response = await ServicePostCreateCustomer(data);
          if (response.status === 200) {
            Swal.fire({
              title: `${stateTypeTransaction ? 'Actualización': 'Registro'} exitoso`,
              text: `Se ha ${stateTypeTransaction ? 'actualizado': 'registrado'} el empleado`,
              icon: "success"
            });
            await handleGetAllCustomerRefresh();
            handleOnClearControls();
            // setStateButtonSave(false);
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: `${response.error}`,
            });
          }
        }
      });
    }
  };
  const handleGetAllCustomerRefresh = async() => {
    let data = await ServiceGetAllCustomer();
    setCustomers(data.data);
  }
  const handleOnClearControls = (_) => { 
    setFormValues({});
    setOpenModalCustomerNew(false);
    if(openModalCustomerEdit) {
      setOpenModalCustomerEdit(false);
    }
  }
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleGetAllProducts = async() => {
    let data = await ServiceGetAllProducts();
    setProducts(data.data);
  }
  const onHandleChangeSelectInsert = (selectedOption, name) => {
    setFormValues({
      ...formValues,
      [name]: selectedOption,
    });
  }
  const handleClearControls = () => {
    setFormValues({});
  }
  // Editar producto
  const handleOnEdit = (e, item) => {
    setObjEditCustomer(item);
    setOpenModalCustomerEdit(true);
  }
  const handleEnabledDisabled = async(e, item) => {
    const {
      user_id,
      state_value,
      names
    } = item;
    Swal.fire({
      title: `Seguro de ${state_value ? 'inactivar': 'activar'} el cliente?`,
      text: "Si, estoy seguro!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Si, ${state_value ? 'inactivar': 'activar'}`,
      cancelButtonText: 'Cancelar'
    }).then(async(result) => {
      if (result.isConfirmed) {
        let response = false;
        if(!state_value) {
          response = await ServicePutEnabledCustomer(user_id);
        } else {
          response = await ServiceDisabledCustomer(user_id);
        }
        if(response.status === 200) {
          Swal.fire({
            title: `${response.data.message}`,
            text: `Se ha ${state_value ? 'inactivado': 'activado'} el cliente ${names}.`,
            icon: "success"
          });
          await handleGetAllCustomerRefresh();
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `${insert.error}`,
          });
        }
      }
    });
  }
  const handleOnClickCustomerNew = (e) => {
    setOpenModalCustomerNew(!openModalCustomerNew)
  }
  const handleOnCloseCustomerNew = (e) => {
    setOpenModalCustomerNew(false);
  }
  const handleOnChangeInsert = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  }
  const handleOnChangeSelectDocumentType = (e) => {
    setDocumentTypeId(e?.value);
  }
  const handleOnCloseCustomerEdit = (e) => {
    setOpenModalCustomerEdit(false);
  }
  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Mantenimiento" breadcrumbItem="Clientes" />
      </Container>
      <List
        rows={customers}
        handleOnClickCustomerNew={handleOnClickCustomerNew}
        handleOnEdit={handleOnEdit}
        handleEnabledDisabled={handleEnabledDisabled}
      />
      {
        openModalCustomerNew && (
          <Modal
            title={`Nuevo cliente`}
            size='modal-xl'
            onClose={handleOnCloseCustomerNew}
          >
          <FormCreate 
            activeStep={activeStep}
            handleNext={handleNext}
            isStepOptional={isStepOptional}
            isStepSkipped={isStepSkipped}
            handleBack={handleBack}
            steps={steps}
            documentsType={documentsType}
            handleOnChangeInsert={handleOnChangeInsert}
            handleOnCloseCustomerNew={handleOnCloseCustomerNew}
            handleOnChangeSelectDocumentType={handleOnChangeSelectDocumentType}
            documentTypeId={documentTypeId}
          />
          </Modal>
      )}
      {
        openModalCustomerEdit && (
          <Modal
            title={`Editar cliente`}
            size='modal-xl'
            onClose={handleOnCloseCustomerEdit}
          >
          <FormUpdate
            activeStep={activeStep}
            handleNext={handleNext}
            isStepOptional={isStepOptional}
            isStepSkipped={isStepSkipped}
            handleBack={handleBack}
            steps={steps}
            objEditCustomer={objEditCustomer}
            documentsType={documentsType}
            handleOnChangeInsert={handleOnChangeInsert}
            handleOnCloseCustomerEdit={handleOnCloseCustomerEdit}
            handleOnChangeSelectDocumentType={handleOnChangeSelectDocumentType}
          />
          </Modal>
      )}
    </div>
  )
}