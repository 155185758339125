import React from 'react';

export default function ButtonFormControl({
  className,
  text,
  OnHandleChange,
  OnHandleClick,
  disabled = false
}) {
  return(
    <button 
      disabled={disabled}
      className={className} 
      onChange={OnHandleChange}
      onClick={OnHandleClick}
      >
      {text}
    </button>
  )
}