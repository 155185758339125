import React from "react";
import { ButtonFormControl, InputFormControl } from "../../molecules";

export default function FormUpdate({
  handleOnChangeInsert,
  handleOnClickSaveEditCategory,
  item = {},
  handleOnCloseCategoryEdit
}) {
  return(
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <InputFormControl
            type={'text'}
            label={'Nombre'}
            nameInput={'names'}
            onHandleChange={handleOnChangeInsert}
            defaultValue={item.names}
          />
        </div>
        <div className="col-md-6">
          <InputFormControl
            type={'text'}
            label={'Abreviatura'}
            onHandleChange={handleOnChangeInsert}
            nameInput={'abbreviature'}
            defaultValue={item.abbreviature}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-6"></div>
        <div className="col-md-6 d-flex justify-content-end">
          <div className="btn-group">
            <ButtonFormControl
              text={'Cancelar'}
              className={'btn btn-secondary btn-lg'}
              OnHandleClick={handleOnCloseCategoryEdit}
            />&nbsp;&nbsp;
            <ButtonFormControl
              text={'Guardar'}
              className={'btn btn-success btn-lg'}
              OnHandleClick={handleOnClickSaveEditCategory}
            />
          </div>
        </div>
      </div >
    </div>
  )
}