import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { ButtonFormControl, InputFormControl, SelectedFormControl } from '../../molecules';
import SaveIcon from '@mui/icons-material/Save';
import LogoutIcon from '@mui/icons-material/Logout';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';

export default function FormCreate({
  handleOnChangeInsert,
  handleFinallyRegister,
  activeStep,
  handleNext,
  isStepOptional,
  isStepSkipped,
  handleBack,
  steps = [],
  areas = [],
  roles = [],
  areaIdSelect,
  handleOnChangeSelectArea,
  handleOnChangeSelectRole,
  handleOnKeyPressDni,
  formValues = {},
  handleOnCloseModalEmployeedNew
}) {

  return (
    <Box sx={{ width: '100%', marginTop: '0.5em' }} >
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption">Opcional</Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <RenderComponentRegister 
        step={activeStep + 1}
        handleOnChangeInsert={handleOnChangeInsert}
        areas={areas}
        roles={roles}
        areaIdSelect={areaIdSelect}
        handleOnChangeSelectArea={handleOnChangeSelectArea}
        handleOnChangeSelectRole={handleOnChangeSelectRole}
        handleOnKeyPressDni={handleOnKeyPressDni}
        formValues={formValues}
      />
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            Todos los pasos completados.
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <ButtonFormControl
              text={<><LogoutIcon />&nbsp;Finalizar</>}
              OnHandleClick={handleFinallyRegister}
              className="btn btn-primary btn-lg"
            />
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Volver
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Siguiente
              </Button>
            )}
            <ButtonFormControl
              text={
                activeStep === steps.length - 1 ? <>
                  <ArrowCircleLeftIcon />&nbsp;Cancelar
                </> : ''
              }
              className="btn btn-secondary btn-lg"
              OnHandleClick={handleOnCloseModalEmployeedNew}
            />&nbsp;&nbsp;
            <ButtonFormControl
              text={
                activeStep === steps.length - 1 ? <>
                  <SaveIcon />&nbsp;Guardar
                </> : 'Siguiente'
              }
              className="btn btn-success btn-lg"
              OnHandleClick={handleNext}
            />&nbsp;&nbsp;
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
}

const RenderComponentRegister = ({
  step,
  handleOnChangeInsert,
  areas = [],
  roles = [],
  areaIdSelect = 0,
  handleOnChangeSelectArea,
  handleOnChangeSelectRole,
  handleOnKeyPressDni,
  formValues = {}
}) => {
  switch (step) {
    case 1:
      return(
        <ComponentePersonalDataRegister 
          handleOnChangeInsert={handleOnChangeInsert}
          areas={areas}
          roles={roles}
          areaIdSelect={areaIdSelect}
          handleOnChangeSelectArea={handleOnChangeSelectArea}
          handleOnChangeSelectRole={handleOnChangeSelectRole}
          handleOnKeyPressDni={handleOnKeyPressDni}
          formValues={formValues}
        />
      )

    default:
      break;
  }
}

const ComponentePersonalDataRegister = ({
  handleOnChangeInsert,
  areas = [],
  roles = [],
  areaIdSelect,
  handleOnChangeSelectArea,
  handleOnChangeSelectRole,
  handleOnKeyPressDni,
  formValues = {}
}) => {
  return(
    <div className='container mt-2'>
      <div className='row'>
        <div className='col-md-6'>
          <InputFormControl
            type={'text'}
            label={'Nombres'}
            nameInput={'names'}
            defaultValue={formValues.names}
            onHandleChange={handleOnChangeInsert}
          />
        </div>
        <div className='col-md-6'>
          <InputFormControl
            type={'text'}
            label={'Apellidos'}
            nameInput={'lastNames'}
            onHandleChange={handleOnChangeInsert}
            defaultValue={formValues.lastNames}
          />
        </div>
        <div className='col-md-3'>
          <InputFormControl
            type={'text'}
            label={'DNI'}
            nameInput={'dni'}
            autoFocus
            onHandleChange={handleOnChangeInsert}
            onKeyPress={handleOnKeyPressDni}
            defaultValue={formValues.dni}
            maxlength={8}
          />
        </div>
        <div className='col-md-3'>
          <InputFormControl
            type={'date'}
            label={'Fecha Nacimiento'}
            nameInput={'birthDate'}
            onHandleChange={handleOnChangeInsert}
          />
        </div>
        <div className='col-md-6'>
          <InputFormControl
            type={'text'}
            label={'Dirección (Calle|Mz|Psje|Av)'}
            nameInput={'address'}
            onHandleChange={handleOnChangeInsert}
          />
        </div>
        <div className='col-md-4'>
          <InputFormControl
            type={'email'}
            label={'Email'}
            nameInput={'email'}
            onHandleChange={handleOnChangeInsert}
          />
        </div>
        <div className='col-md-2'>
          <InputFormControl
            type={'text'}
            label={'Celular'}
            nameInput={'cellphone'}
            onHandleChange={handleOnChangeInsert}
            maxlength={9}
          />
        </div>
        <div className='col-md-3'>
          <SelectedFormControl
            label={'Area'}
            placeholder={'Seleccione una area...'}
            nameSelect='areaId'
            options={areas}
            onHandleChange={handleOnChangeSelectArea}
          />
        </div>
        <div className='col-md-3'>
          <SelectedFormControl
            label={'Cargo'}
            placeholder={'Seleccione una cargo...'}
            nameSelect='cargoId'
            options={roles.filter(role => parseInt(role.areaId) === parseInt(areaIdSelect))}
            onHandleChange={handleOnChangeSelectRole}
          />
        </div>
      </div>
    </div>
  )
}