import React, { useState } from "react";
import { Container } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import List from './List';
import { getTitle } from "../../../helpers/general_functions";
import { Modal } from "../../organism";
import FormCreate from './FormCreate';
import { useGetAllBrands, useGetAllPresentation, useGetAllVehicle } from "../../../hooks/";
import Swal from 'sweetalert2';
import FormUpdate from "./FormUpdate";
import { ServiceGetAllVehicles, ServiceGetValidateAssignment, ServicePostCreateVehicle, ServicePutDisabledVehicle, ServicePutEnabledVehicle, ServicePutUpdateVehicle } from "../../../service/vehicle-service";

export default function Manager() {
  const { vehicles, setVehicle } = useGetAllVehicle();
  const { brands } = useGetAllBrands();
  const { presentations } = useGetAllPresentation();
  const [objEditVehicle, setObjEditProduct] = useState({});

  const [formValues, setFormValues] = useState({
    license_plate: '',
    license_plate_carreta: '',
    year_of_production: 0,
    color: '',
    quantity_tires: 0,
    selectValueBrand: null,
  });

  const [openModalVehicleNew, setOpenModalVehicleNew] = useState(false);
  const [openModalVehicleEdit, setOpenModalProductEdit] = useState(false);
  getTitle('Vehículos');

  const handleOnClickProductNew = (_) => {
    setOpenModalVehicleNew(!openModalVehicleNew);
  }
  const handleOnCloseVehicleNew = (_) => {
    setOpenModalVehicleNew(!!!openModalVehicleNew)
  }
  const onHandleChangeInsert = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  }
  const handleGetAllVehicles = async() => {
    let data = await ServiceGetAllVehicles();
    setVehicle(data.data);
  }
  const onHandleChangeSelectInsert = (selectedOption, name) => {
    setFormValues({
      ...formValues,
      [name]: selectedOption,
    });
  }
  const handleOnSaveVehicleNew = (_) => {
    //Validaciones
    if(!formValues.license_plate) {
      Swal.fire({
        icon: "error",
        title: "Advertencia",
        text: `Debe de ingresar la placa del vehículo.`,
      });
      return;
    }
    if(formValues.selectValueBrand === undefined || formValues.selectValueBrand === null) {
      Swal.fire({
        icon: "error",
        title: "Advertencia",
        text: `Debe de seleccionar una marca del vehículo.`,
      });
      return;
    }
    let dataInsert = {
      license_plate: formValues.license_plate,
      license_plate_carreta: formValues.license_plate_carreta,
      year_of_production: parseInt(formValues.year_of_production),
      color: formValues.color,
      quantity_tires: parseInt(formValues.quantity_tires),
      brand_insert: '',
      brand: {
        brand_id: parseInt(formValues.selectValueBrand.id)
      },
    };
    Swal.fire({
      title: "Seguro de guardar el vehículo?",
      text: "Si, estoy seguro!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, guardar",
      cancelButtonText: 'Cancelar'
    }).then(async(result) => {
      if (result.isConfirmed) {
        let insert = await ServicePostCreateVehicle(dataInsert);
        if(insert.status === 200) {
          Swal.fire({
            title: `${insert.data.message}`,
            text: `Se ha registrado el vehículo ${dataInsert.license_plate}.`,
            icon: "success"
          });
          setOpenModalVehicleNew(false);
          await handleGetAllVehicles();
          handleClearControls();
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `${insert.error}`,
          });
        }
      }
    });
  }
  const handleClearControls = () => {
    setFormValues({});
  }
  // Editar producto
  const handleOnEdit = (e, item) => {
    setObjEditProduct(item);
    setOpenModalProductEdit(true);
  }
  const handleOnCloseVehicleEdit = (e) => {
    setOpenModalProductEdit(false);
  }
  const handleOnSaveVehicleEdit = (_) => {
    const {
      id
    } = objEditVehicle
    let dataEdit = {
      vehicle_id: parseInt(id),
      license_plate_carreta: formValues.license_plate_carreta === undefined || formValues.license_plate_carreta === null || formValues.license_plate_carreta === '' ? objEditVehicle?.vehicle_license_plate_carreta: formValues.license_plate_carreta,
      license_plate: formValues.license_plate === undefined || formValues.license_plate === null || formValues.license_plate === '' ? objEditVehicle?.vehicle_license_plate: formValues.license_plate,
      year_of_production: formValues.year_of_production === undefined || formValues.year_of_production === null || formValues.year_of_production === 0 ? objEditVehicle?.year_of_production: formValues.year_of_production,
      color: formValues.color === undefined || formValues.color === null || formValues.color === '' ? objEditVehicle?.color: formValues.color,
      quantity_tires: formValues.quantity_tires === undefined || formValues.quantity_tires === null || formValues.quantity_tires === 0 ? objEditVehicle.quantity_tires: formValues.quantity_tires,
      brand_insert: '',
      brand: {
        brand_id: formValues.selectValueBrand === null || formValues.selectValueBrand === undefined ? objEditVehicle.brand_id: parseInt(formValues.selectValueBrand.id)
      }
    }
    Swal.fire({
      title: "Seguro de editar el vehículo?",
      text: "Si, estoy seguro!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, editar",
      cancelButtonText: 'Cancelar'
    }).then(async(result) => {
      if (result.isConfirmed) {
        let update = await ServicePutUpdateVehicle(dataEdit);
        if(update.status === 200) {
          Swal.fire({
            title: `${update.data.message}`,
            text: `Se ha actualizado el vehículo ${dataEdit.license_plate}.`,
            icon: "success"
          });
          setOpenModalProductEdit(false);
          await handleGetAllVehicles();
          handleClearControls();
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `${update.error}`,
          });
        }
      }
    });
  }
  const handleEnabledDisabled = async(_, item) => {
    const {
      id,
      state_value,
      vehicle_license_plate
    } = item;
    let validate = await ServiceGetValidateAssignment(id);
    if(parseInt(validate.data.validate) === 1) {
      Swal.fire({
        icon: "warning",
        title: "Advertencia",
        text: `${validate.data.message}`,
      });
      return;
    }
    Swal.fire({
      title: `Seguro de ${state_value ? 'inactivar': 'activar'} el vehículo?`,
      text: "Si, estoy seguro!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Si, ${state_value ? 'inactivar': 'activar'}`,
      cancelButtonText: 'Cancelar'
    }).then(async(result) => {
      if (result.isConfirmed) {
        let response = false;
        if(!state_value) {
          response = await ServicePutEnabledVehicle(id);
        } else {
          response = await ServicePutDisabledVehicle(id);
        }
        if(response.status === 200) {
          Swal.fire({
            title: `${response.data.message}`,
            text: `Se ha ${state_value ? 'inactivado': 'activado'} el vehículo ${vehicle_license_plate}.`,
            icon: "success"
          });
          await handleGetAllVehicles();
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `${insert.error}`,
          });
        }
      }
    });

  }
  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Mantenimiento" breadcrumbItem="Vehículo" />
      </Container>
      <List
        rows={vehicles}
        handleOnClickProductNew={handleOnClickProductNew}
        handleOnEdit={handleOnEdit}
        handleEnabledDisabled={handleEnabledDisabled}
      />
      {
        openModalVehicleNew && (
          <Modal
            title={`Nuevo vehiculo`}
            size='modal-lg'
            onClose={handleOnCloseVehicleNew}
          >
          <FormCreate 
            handleOnCloseVehicleNew={handleOnCloseVehicleNew}
            presentations={presentations}
            onHandleChangeInsert={onHandleChangeInsert}
            onHandleChangeSelectInsert={onHandleChangeSelectInsert}
            handleOnSaveVehicleNew={handleOnSaveVehicleNew}
            brands={brands}
          />
          </Modal>
      )}
      {
        openModalVehicleEdit && (
          <Modal
            title={`Editar vehículo ${objEditVehicle.vehicle_license_plate}`}
            size='modal-lg'
            onClose={handleOnCloseVehicleEdit}
          >
          <FormUpdate
            handleOnCloseVehicleEdit={handleOnCloseVehicleEdit}
            objEditVehicle={objEditVehicle}
            presentations={presentations}
            brands={brands}
            onHandleChangeInsert={onHandleChangeInsert}
            onHandleChangeSelectInsert={onHandleChangeSelectInsert}
            handleOnSaveVehicleEdit={handleOnSaveVehicleEdit}
          />
          </Modal>
      )}
    </div>
  )
}