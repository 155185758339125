import React, { useState } from "react";
import { Container } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import List from './List';
import { formatearFecha, getTitle } from "../../../helpers/general_functions";
import { Modal } from "../../organism";
import FormCreate from './FormCreate';
import {  useGetAllDocumentType, useGetAllVehicleAvailable } from "../../../hooks/";
import Swal from 'sweetalert2';
import FormUpdate from "./FormUpdate";
import { useGetAllDrivers } from "../../../hooks/user-hook";
import { ServiceDisabledDriver, ServiceEnabledDriver, ServiceGetAllDrivers, ServicePostCreateDriver, ServicePutCreateDriver } from "../../../service/user-service";
import { ServiceGetAllVehiclesAvailable } from "../../../service/vehicle-service";

export default function Manager() {
  const { drivers, setDrivers } = useGetAllDrivers();
  const { documentsType } = useGetAllDocumentType();
  const { vehiclesAvailable, setVehicleAvailable } = useGetAllVehicleAvailable();
  const [objEditDriver, setObjEditDriver] = useState({});

  const [formValues, setFormValues] = useState({
    names: '',
    lastNames: '',
    nroDocument: '',
    email: '-',
    selectValueDocumenType: null,
    birthDate: '',
    licenseCode: '',
    dateOfAdmission: '',
    dateExpiration: ''
  });
  const [vehicleId, setVehicleId] = useState(0);
  const [vehicleIdDelete, setVehicleIdDelete] = useState(0);

  const [openModalDriverNew, setOpenModalDriverNew] = useState(false);
  const [openModalDriverEdit, setOpenModalDriverEdit] = useState(false);
  getTitle('Choferes');

  const handleOnClickDriverNew = (_) => {
    setOpenModalDriverNew(!openModalDriverNew);
  }
  const handleOnCloseDriverNew = (_) => {
    setOpenModalDriverNew(!!!openModalDriverNew);
    handleGetAllDrivers();
    handleClearControls();
  }
  const onHandleChangeInsert = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  }
  const handleGetAllDrivers = async() => {
    let data = await ServiceGetAllDrivers();
    setDrivers(data.data);
    let vehiclesAvailable = await ServiceGetAllVehiclesAvailable();
    setVehicleAvailable(vehiclesAvailable.data);
  }
  const onHandleChangeSelectInsert = (selectedOption, name) => {
    setFormValues({
      ...formValues,
      [name]: selectedOption,
    });
  }
  const handleOnSaveDriverNew = (e) => {
    if(formValues.selectValueDocumenType === null || formValues.selectValueDocumenType === undefined) {
      Swal.fire({
        icon: "warning",
        title: "Advertencia",
        text: `Debes de seleccionar un tipo de documento`,
      });
      return;
    }
    if(!vehicleId) {
      Swal.fire({
        icon: "warning",
        title: "Advertencia",
        text: `Debes de asignar un vehículo`,
      });
      return;
    }
    if(!formValues.names) {
      Swal.fire({
        icon: "warning",
        title: "Advertencia",
        text: `Debes de ingresar el nombre del chofer`,
      });
      return;
    }
    if(!formValues.lastNames) {
      Swal.fire({
        icon: "warning",
        title: "Advertencia",
        text: `Debes de ingresar el apellido del chofer`,
      });
      return;
    }
    if(!formValues.nroDocument) {
      Swal.fire({
        icon: "warning",
        title: "Advertencia",
        text: `Debes de ingresar el número de documento del chofer`,
      });
      return;
    }
    if(!formValues.licenseCode) {
      Swal.fire({
        icon: "warning",
        title: "Advertencia",
        text: `Debes de ingresar la licencia de conducir del chofer`,
      });
      return;
    }
    let dataInsert = {
      names: formValues.names,
      last_names: formValues.lastNames,
      nro_document: formValues.nroDocument,
      email: formValues.email,
      document_type: {
        document_type_id: parseInt(formValues.selectValueDocumenType.id)
      },
      birth_date: formValues.birthDate === undefined || formValues.birthDate === null? '1950-01-01': formValues.birthDate,
      license_code: formValues.licenseCode,
      date_of_admission: formValues.dateOfAdmission,
      date_expiration: formValues.dateExpiration,
      vehicle_id: parseInt(vehicleId)
    };
    Swal.fire({
      title: "Seguro de guardar el chofer?",
      text: "Si, estoy seguro!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, guardar",
      cancelButtonText: 'Cancelar'
    }).then(async(result) => {
      if (result.isConfirmed) {
        let insert = await ServicePostCreateDriver(dataInsert);
        if(insert.status === 200) {
          Swal.fire({
            title: `${insert.data.message}`,
            text: `Se ha registrado el chofer ${dataInsert.names}, ${dataInsert.last_names}.`,
            icon: "success"
          });
          setOpenModalDriverNew(false);
          await handleGetAllDrivers();
          handleClearControls();
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `${insert.error}`,
          });
        }
      }
    });
  }
  const handleClearControls = () => {
    setFormValues({});
    setVehicleId(0);  
    setVehicleIdDelete(0);
  }
  // Editar chofer
  const handleOnEdit = (e, item) => {
    setObjEditDriver(item);
    setOpenModalDriverEdit(true);
  }
  const handleOnCloseDriverEdit = (e) => {
    setOpenModalDriverEdit(false);
    handleGetAllDrivers();
    handleClearControls();
  }
  const handleOnSaveDriverEdit = (_) => {
    const {
      user_id
    } = objEditDriver
    let dataEdit = {
      driver_id: user_id,
      names: formValues.names === undefined || formValues.names === null || formValues.names === '' ? objEditDriver.names: formValues.names,
      last_names: formValues.lastNames === undefined || formValues.lastNames === null || formValues.lastNames === '' ? objEditDriver.last_names: formValues.lastNames,
      nro_document: formValues.nroDocument === undefined || formValues.nroDocument === null || formValues.nroDocument === '' ? objEditDriver.number_document: formValues.nroDocument,
      email: formValues.email === undefined || formValues.email === null || formValues.email === '' ? objEditDriver.emails[0]?.email: formValues.email,
      document_type: {
        document_type_id: formValues.selectValueDocumenType === undefined || formValues.selectValueDocumenType === null ? objEditDriver.document_type_id: parseInt(formValues.selectValueDocumenType.id)
      },
      birth_date: formValues.birthDate === undefined || formValues.birthDate === null || formValues.birthDate === '' ? formatearFecha(objEditDriver.birth_date): formValues.birthDate,
      license_code: formValues.licenseCode === undefined || formValues.licenseCode === null || formValues.licenseCode === '' ? objEditDriver.license_code: formValues.licenseCode,
      date_of_admission: formValues.dateOfAdmission === undefined || formValues.dateOfAdmission === null || formValues.dateOfAdmission === '' ? formatearFecha(objEditDriver.date_of_admission): formValues.dateOfAdmission,
      date_expiration: formValues.dateExpiration === undefined || formValues.dateExpiration === null || formValues.dateExpiration === '' ? formatearFecha(objEditDriver.license_expiration_date): formValues.dateExpiration,
      vehicle_id: parseInt(vehicleId) === 0 ? objEditDriver.vehicle_id: parseInt(vehicleId),
      delete_vehicle_associate: parseInt(vehicleIdDelete) > 0 ? 1: 0,
      vehicle_id_delete: parseInt(vehicleIdDelete)
    }
    Swal.fire({
      title: "Seguro de editar el chofer?",
      text: "Si, estoy seguro!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, editar",
      cancelButtonText: 'Cancelar'
    }).then(async(result) => {
      if (result.isConfirmed) {
        let update = await ServicePutCreateDriver(dataEdit);
        if(update.status === 200) {
          Swal.fire({
            title: `${update.data.message}`,
            text: `Se ha actualizado el chofer ${dataEdit.names}, ${dataEdit.last_names}.`,
            icon: "success"
          });
          setOpenModalDriverEdit(false);
          await handleGetAllDrivers();
          handleClearControls();
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `${update.error}`,
          });
        }
      }
    });
  }
  const handleEnabledDisabled = async(_, item) => {
    const {
      user_id,
      state_value,
      names,
      last_names
    } = item;
    Swal.fire({
      title: `Seguro de ${state_value ? 'inactivar': 'activar'} el chofer?`,
      text: "Si, estoy seguro!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Si, ${state_value ? 'inactivar': 'activar'}`,
      cancelButtonText: 'Cancelar'
    }).then(async(result) => {
      if (result.isConfirmed) {
        let response = false;
        if(!state_value) {
          response = await ServiceEnabledDriver(user_id);
        } else {
          response = await ServiceDisabledDriver(user_id);
        }
        if(response.status === 200) {
          Swal.fire({
            title: `${response.data.message}`,
            text: `Se ha ${state_value ? 'inactivado': 'activado'} el chofer - ${names}, ${last_names}.`,
            icon: "success"
          });
          await handleGetAllDrivers();
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `${insert.error}`,
          });
        }
      }
    });
  }
  const handleOnAssignmentVehicle = async(_, item, type = 'new') => {
    const {
      vehicle_id
    } = item;
    setVehicleId(vehicle_id);
    let data = await ServiceGetAllVehiclesAvailable();
    if(data.data) {
      data.data.map(vehi => {
        if(parseInt(vehi.vehicle_id) === parseInt(vehicle_id)) {
          vehi.state = type === 'new' ? 'Seleccionado': 'Vehículo seleccionado'
          vehi.state_value = 2
          vehi.vehicle_id = vehicle_id
        }
      })
    }
    setVehicleAvailable(data.data);
  }
  const handleOnDeleteAssignmentVehicle = async(e, item) => {
    const {
      vehicle_id
    } = item;
    setVehicleIdDelete(vehicle_id);
    let data = await ServiceGetAllVehiclesAvailable();
    if(data.data) {
      data.data.map(vehi => {
        if(parseInt(vehi.vehicle_id) === parseInt(vehicle_id)) {
          vehi.state = 'Vehículo quitado'
          vehi.state_value = 3
          vehi.vehicle_id = vehicle_id
        }
      })
    }
    setVehicleAvailable(data.data);
  }
  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Mantenimiento" breadcrumbItem="Choferes" />
      </Container>
      <List
        rows={drivers}
        handleOnClickDriverNew={handleOnClickDriverNew}
        handleOnEdit={handleOnEdit}
        handleEnabledDisabled={handleEnabledDisabled}
      />
      {
        openModalDriverNew && (
          <Modal
            title={`Nuevo Chofer`}
            size='modal-xl'
            onClose={handleOnCloseDriverNew}
          >
          <FormCreate 
            handleOnCloseDriverNew={handleOnCloseDriverNew}
            documentsType={documentsType}
            onHandleChangeInsert={onHandleChangeInsert}
            onHandleChangeSelectInsert={onHandleChangeSelectInsert}
            handleOnSaveDriverNew={handleOnSaveDriverNew}
            vehiclesAvailable={vehiclesAvailable}
            objDriver={formValues}
            handleOnAssignmentVehicle={handleOnAssignmentVehicle}
          />
          </Modal>
      )}
      {
        openModalDriverEdit && (
          <Modal
            title={`Editar Chofer - ${objEditDriver.names}, ${objEditDriver.last_names}`}
            size='modal-xl'
            onClose={handleOnCloseDriverEdit}
          >
          <FormUpdate
            handleOnCloseDriverEdit={handleOnCloseDriverEdit}
            objDriver={objEditDriver}
            documentsType={documentsType}
            onHandleChangeInsert={onHandleChangeInsert}
            onHandleChangeSelectInsert={onHandleChangeSelectInsert}
            handleOnSaveDriverEdit={handleOnSaveDriverEdit}
            vehiclesAvailable={vehiclesAvailable}
            handleOnAssignmentVehicle={handleOnAssignmentVehicle}
            handleOnDeleteAssignmentVehicle={handleOnDeleteAssignmentVehicle}
          />
          </Modal>
      )}
    </div>
  )
}